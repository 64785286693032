import { Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import BookingTable from "../Components/BookingTable";
import { get_data, post_data } from "../../../api";
import SearchComponent from "../Components/SearchComponent";

export default function Booking({ socket }) {

    const [loading, setLoading] = useState("true");
    const [bookingData, setBookingData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [value, setValue] = useState('')

    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalBookings, setTotalBookings] = useState(null);
    const [searchState, setSearchState] = useState(false)

    const handleSearch = () => {
        setLoader(true)
        setTimeout(() => {
            if (value !== '') {
                searchBookings()
                setSearchState(true)
            }
            else {
                getAllBooking()
                setSearchState(false)
            }
        }, 500);
    }

    const searchBookings = async () => {
        try {
            const data = await post_data(`booking/search-bookings/${value}?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setBookingData(data?.data?.bookings);
                setTotalPages(data?.data?.totalPages);
                setTotalBookings(data?.data?.totalBookings);
            }
            else {
                setLoader(false);
                setBookingData([])
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error While Fetching All Customers", error);
        }
    }

    const getAllBooking = async () => {
        try {
            const data = await get_data(`booking/get-all-booking?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setBookingData(data?.data?.bookings);
                setTotalPages(data?.data?.totalPages);
                setTotalBookings(data?.data?.totalBookings);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error While Fetching All Bookings", error);
        }
    }


    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchBookings()
            }
            else {
                getAllBooking();
            }
        }
    }, [currentPage])

    const vendor_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Phone", grid: 2 },
        { title: "Customer", grid: 2 },
        { title: "Booking Id", grid: 2 },
        // { title: "Services", grid: 4 },
        { title: "Vendor", grid: 2 },
        { title: "Date", grid: 1 },
        { title: "Status", grid: 1.3 },
        { title: "Info", grid: 0.7 },
    ];

    const count = bookingData?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...vendor_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Bookings' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div>

                <div style={page_container}>
                    <BookingTable
                        socket={socket}
                        tableHeader={tableHeader}
                        data={bookingData}
                        totalBookings={totalBookings}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPages={totalPages}
                        loader={loader}
                        setLoader={setLoader}
                        getAllBooking={getAllBooking}
                    />
                </div>
            </Grid>
        </>
    )
}