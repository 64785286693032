import React, { useState } from "react";
import { Grid, Typography, Pagination, Modal, Box, TextField, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip, FormHelperText } from "@mui/material";
import { RxUpdate } from "react-icons/rx";
import { IoIosAddCircleOutline } from "react-icons/io";
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { post_data } from "../../../api";
import Swal from 'sweetalert2';
import Loader from "../../../website/Components/Loader";
import { formatPrice, globalConstant } from '../../../globalConstants';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RefundPayment from "./RefundPayment";
import { IoIosInformationCircleOutline } from "react-icons/io";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


export default function RefundTable({
    tableHeader,
    data,
    itemsPerPage = 6,
    currentPage,
    setCurrentPage,
    count,
    totalPages,
    setLoader,
    loader,
    getAllRefunds,
    totalRefunds
}) {

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [refundStatus, setRefundStatus] = useState('');
    const [errors, setErrors] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [updateID, setUpdateID] = useState('');
    const [disabled, setDisabled] = useState(false);
    const { primaryColor } = globalConstant();
    const [refundOpen, setRefundOpen] = useState(false);
    const [openType, setOpenType] = useState('');
    const [bookingID, setBookingID] = useState('');

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setRefundStatus('')
        setIsUpdate(false);
        setUpdateID('');
        setBookingID('');
        setDisabled(false);
        setOpen(false);
        setErrors({});
    };


    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        background: "white",
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalRefunds);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };


    const handleSetUpdateRefund = (item) => {
        setIsUpdate(true);
        setUpdateID(item?._id);
        setBookingID(item?.booking_id?._id);
        setRefundStatus(item?.refund_status)
        handleOpen();
    }

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }

    function validate() {
        let error = false;

        if (refundStatus.length === 0) {
            error = true;
            handleError('refundStatus', 'Refund Status is required')
        }
        return error;
    }

    const statusStyles = {
        processed: {
            backgroundColor: '#ffe9a8',
            color: '#bf8f00',
        },
        success: {
            backgroundColor: '#C8E6C9',
            color: '#388E3C',
        },
        failed: {
            backgroundColor: '#FFCDD2',
            color: '#D32F2F',
        }
    };

    const handleChangeStaus = async () => {
        const error = validate();
        if (!error) {
            setDisabled(true);

            const data = await post_data(`refund/update-refund/${updateID}`, { refund_status: refundStatus, bookingID });

            if (data?.status) {
                getAllRefunds();
                Toast.fire({
                    icon: "success",
                    text: "Refund Status Changed!",
                    color: "Green"
                })
            } else {
                Toast.fire({
                    icon: "success",
                    text: "Refund Status Changed!",
                    color: "Green"
                })
            }

            handleClose();
        }
    }

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader?.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 || index === 2 || index === 3 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            // p: 4,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        },
        button: {
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            borderRadius: '8px',
            cursor: 'pointer',
            border: '2px solid ' + primaryColor,
            marginTop: '3%',
            width: '30%',
            boxShadow: 'none'
        }
    };
    const heading = {
        fontWeight: 600,
        fontSize: 22,
        marginBottom: '10px',
        textAlign: 'left',
        p: 3,
        borderBottom: '1px solid gainsboro'
    }

    const [selectedItem, setSelectedItem] = useState({})

    const handleOpenBank = (item, type) => {
        setOpenType(type);
        setRefundOpen(true);
        setSelectedItem(item)
    }

    const displayAllHeader = () => {
        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'right',
                    cursor: 'pointer',
                }}
                >
                    <RefundPayment setRefundOpen={setRefundOpen} refundOpen={refundOpen} currentRefund={selectedItem} type={openType} />
                    <span style={{ display: 'flex', alignItems: 'center', gap: '3px', color: '#34eb52' }}>
                        <Modal
                            open={open}
                            onClose={handleClose}
                        >
                            <Box sx={styles.modalBox}>
                                <Typography id="logout-modal-title" variant="h6" sx={heading}>
                                    Refund Details
                                </Typography>
                                <Typography sx={{ padding: '2% 6% 6% 6%' }}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '2% 0',
                                        alignItems: 'center'
                                    }}>
                                        <Typography variant="body1">Refund Status:</Typography>
                                        <Box style={{ width: 200 }}>
                                            <FormControl error={errors?.refundStatus} fullWidth>
                                                <Select
                                                    size="small"
                                                    value={refundStatus}
                                                    onChange={(e) => {
                                                        setRefundStatus(e.target.value);
                                                    }}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, refundStatus: "" })
                                                    }}
                                                >
                                                    <MenuItem value="processed">Processed</MenuItem>
                                                    <MenuItem value="success">Success</MenuItem>
                                                    <MenuItem value="failed">Failed</MenuItem>
                                                </Select>
                                                <FormHelperText>{errors.refundStatus}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Typography>
                                <Typography sx={{ padding: '2% 6% 6% 6%' }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            boxShadow: 'none', padding: '2.5% 0', background: '#5DB761', '&:hover': { backgroundColor: '#5DB761', boxShadow: 'none' }
                                        }}
                                        onClick={handleChangeStaus}
                                    >
                                        Update
                                    </Button>
                                </Typography>
                            </Box>
                        </Modal>
                    </span>
                </div>
                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        :
                        data?.length > 0 ?
                            data?.map((item, i) => (
                                <div style={{ width: "100%" }} key={i}>
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{
                                            width: "100%",
                                            padding: 6,
                                            display: "flex",
                                            alignItems: "center",
                                            borderTop: "1px dashed gainsboro",
                                        }}
                                    >
                                        <Grid item xs={1}>
                                            <h3 style={{ ...table_row_font, color: "#3F4254" }}>{startEntry + i}</h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254",
                                                }}
                                            >
                                                {item?.customer_id?.full_name}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254", margin: 0, padding: 0
                                                }}
                                            >
                                                {item?.customer_id?.phone}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{ display: "flex", alignItems: "center", justifyContent: 'left' }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254", margin: 0, padding: 0
                                                }}
                                            >
                                                {item?.booking_id?.booking_id}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                                {formatPrice(item?.org_amount)}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254", margin: 0, padding: 0
                                                }}
                                            >
                                                {formatPrice(item?.refund_amount)}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Chip
                                                label={item?.refund_status}
                                                sx={{
                                                    ...statusStyles[item?.refund_status],
                                                    borderRadius: '5px',
                                                    fontWeight: '500',
                                                    textTransform: 'capitalize',
                                                    width: "100%"
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    margin: 0,
                                                    fontSize: 14,
                                                    borderRadius: 4,
                                                    textAlign: "center",
                                                    fontWeight: 500, margin: 0, padding: 0
                                                }}
                                            >
                                                <AccountBalanceIcon onClick={() => handleOpenBank(item, "bank")} style={{ color: "black", cursor: 'pointer' }} />
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ display: "flex", justifyContent: "center", gap: '10px', alignItems: 'center' }}
                                        >
                                            <img onClick={() => handleSetUpdateRefund(item)} src="/images/edit-img.svg" style={{ cursor: 'pointer' }} />
                                            <IoIosInformationCircleOutline style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => { handleOpenBank(item, 'detail'); }} />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))
                            :
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}
                            >
                                No Refund Found...
                            </Grid>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalRefunds} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}