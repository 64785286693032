import React, { useRef, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { globalConstant } from "../../globalConstants";
import { post_data } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slices/user-slice";

export default function OtpComponent({
    formData,
    newNumber,
    type,
    mobileNumber,
    email,
    newEmail,
    setOpen,
    handleResend,
    isSignup
}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [loading2, setLoading2] = useState(false);
    const otpInputRefs = useRef([]);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
    const matches_md = useMediaQuery(theme.breakpoints.down("md"));
    const { user_data } = useSelector(state => state.user);

    const { primaryColor } = globalConstant()

    const [timer, setTimer] = useState(59);
    const [canResend, setCanResend] = useState(false);

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else {
            setCanResend(true);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleFocus = (index) => {
        setFocusedIndex(index);
    };

    const otpBox = {
        width: matches_md ? 35 : 50,
        height: matches_md ? 45 : 60,
        background: "transparent",
        padding: "2.5% 4%",
        borderRadius: 0,
        display: "flex",
        alignItems: "center",
    };

    const handleOtpChange = (index, value) => {
        let arr = value !== "" ? value.split("") : "";
        let currentValue = "";
        if (arr !== "") {
            currentValue = arr[arr.length - 1];
        }

        const newOtp = [...otp];
        newOtp[index] = currentValue;
        setOtp(newOtp);

        if (currentValue !== "" && index < otpInputRefs.current.length - 1) {
            otpInputRefs.current[index + 1].focus();
        }

        if (currentValue === "" && index > 0) {
            otpInputRefs.current[index - 1].focus();
        }
    };

    const handleSubmit = async () => {

        setLoading2(true);

        if (type === 'login') {
            let payload = { phone: mobileNumber, otp: otp.join(""), isSignup };  
            const response = await post_data("customer/verify-otp-for-phone", payload);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    setOpen(false);
                    dispatch(login(response?.data?.user));
                    localStorage.setItem("authToken", response?.data.token);
                    navigate('/dashboard');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }
        else if (type === 'verify-email') {
            let payload = { email: email, otp: otp.join("") };  // customer verify email
            const response = await post_data("customer/verify-otp-for-email", payload);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    setOpen(false);
                    dispatch(login(response?.data));
                    navigate('/dashboard');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }


        else if (type === 'change-email') {
            let payload = { email: email, otp: otp.join(""), new_email: newEmail, customer_id: user_data?._id };  // change customer email
            const response = await post_data("customer/verify-otp-for-change-email", payload);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    setOpen(false);
                    dispatch(login(response?.data));
                    navigate('/dashboard');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }

        else if (type === 'change-number') {
            let payload = { otp: otp.join(""), new_number: newNumber, customer_id: user_data?._id };  // change customer email
            const response = await post_data("customer/verify-otp-for-change-number", payload);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    setOpen(false);
                    dispatch(login(response?.data));
                    navigate('/dashboard');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }

        else if (type === 'change-admin-email') {
            let payload = { email: user_data?.email, otp: otp.join(""), new_email: newEmail };  // change admin email
            const response = await post_data("super-admin/verify-otp-for-change-email", payload);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    dispatch(login(response?.data));
                    navigate('/admin/dashboard/settings');
                    window.scrollTo(0, 0)
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }

        else {

        }

    };

    const handleResendOtp = async () => {
        setTimer(59);
        setCanResend(false);
        handleResend()
    };


    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleSubmit()
        }
    }

    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        margin: '5% 0 2%'
    }

    return (
        <>
            <div className="otp-form">
                <Toaster />
                {otp?.map((digit, index) => {
                    return (
                        <>
                            <div
                                style={{
                                    ...otpBox,
                                    border:
                                        focusedIndex === index
                                            ? `2px solid ${primaryColor}`
                                            : "2px solid #CBCBCB",
                                }}
                            >
                                <TextField
                                    onKeyPress={(event) => handleEnter(event)}
                                    onFocus={() => handleFocus(index)}
                                    value={digit}
                                    inputRef={(ref) => (otpInputRefs.current[index] = ref)}
                                    onChange={(e) =>
                                        handleOtpChange(index, e.target.value, "one")
                                    }
                                    // id="zero"
                                    sx={{
                                        input: {
                                            color: "black",
                                            fontSize: 30,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        },
                                    }}
                                    variant="standard"
                                    hiddenLabel
                                    placeholder=" "
                                    InputProps={{
                                        disableUnderline: true,
                                        inputProps: { maxLength: 1 },
                                    }}
                                />
                            </div>
                        </>
                    );
                })}
                <p className="otp-form-txt" style={{ marginTop: '3%' }}>
                    Please enter your verification code.
                </p>
            </div >

            <Button
                disabled={loading2}
                onClick={handleSubmit}
                fullWidth
                className={loading2 ? "register-btn-disable" : "register-btn"}
                style={button}
            >
                {loading2 ? "Verifying..." : "Verify"}
            </Button>

            <center>
                {canResend ? (
                    <p onClick={handleResendOtp} style={{ cursor: 'pointer', color: primaryColor, fontWeight: 500, textDecoration: 'underline' }}>
                        Resend OTP
                    </p>
                ) : (
                    <p className="otp-form-txt">
                        Didn't receive OTP? Resend in <b>{`00:${timer < 10 ? `0${timer}` : timer} seconds`}</b>
                    </p>
                )}
            </center>

        </>
    );
}
