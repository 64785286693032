import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography, useMediaQuery, Pagination } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { globalConstant } from '../../../globalConstants';
import BookingsCard from '../Components/BookingsCard';
import { get_data } from '../../../api';
import BookingCardSkeleton from '../../../website/Components/Skeleton/BookingCardSkeleton';
import Empty from '../Components/Empty';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
            style={{
                padding: '16px',
                borderRadius: '10px', // Rounded borders
                marginTop: '16px',
            }}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const MyBookings = ({ user_data }) => {

    const [value, setValue] = useState(0);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 960px)');
    const { primaryColor } = globalConstant()
    const [loading, setLoading] = useState(true)


    const [allBookings, setAllBookings] = useState([])
    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalBookings, setTotalBookings] = useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getAllBookings = async (pageNumber) => {
        setLoading(true)
        try {
            const data = await get_data(`booking/get-booking-by-customer/${user_data?._id}?pageNumber=${pageNumber}`);
            if (data.status) {
                setLoading(false)
                setAllBookings(data?.data?.bookings)
                setTotalBookings(data?.data?.totalBookings);
                setTotalPages(data?.data?.totalPages);
            }
            else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            // console.log("Error while fetching Services", error);
        }
    }


    useEffect(() => {
        if (currentPage) {
            getAllBookings(currentPage);
        }
    }, [currentPage])

    const count = allBookings?.length;

    const theme = createTheme({
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        minWidth: isMobile ? 70 : isTablet ? 100 : 120,
                        color: 'black',
                        borderBottom: '3px solid white',
                        '&.Mui-selected': {
                            color: primaryColor, // Text color for the selected tab
                            // backgroundColor: primaryColor,
                            borderBottom: '3px solid ' + primaryColor,
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        display: 'none', // Hide the default indicator
                    },
                },
            },
        },
    });

    const AllBookings = () => {
        return (
            <>
                <div style={{ width: '100%' }}>
                    {
                        allBookings?.map((item) => {
                            return <BookingsCard booking={item} getAllBookings={getAllBookings} />
                        })
                    }
                </div>
            </>
        )
    }

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalBookings);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <div style={{ width: isTablet || isMobile ? '90%' : '95%', padding: '3%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                {
                    loading ?
                        <>
                            <div style={{ width: isTablet || isMobile ? '90%' : '60%', margin: 'auto' }}><BookingCardSkeleton /></div>
                        </>
                        :
                        <>
                            {
                                allBookings?.length === 0 ?
                                    <>
                                        <Empty />
                                    </>
                                    :
                                    <>
                                        <AllBookings />
                                    </>
                            }
                        </>
                }
                <div style={{ width: '90%', margin: 'auto' }}>
                    {allBookings?.length > 0 && (
                        <div
                            style={{
                                borderTop: "1px dashed gainsboro",
                                paddingTop: "2%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                style={{
                                    fontWeight: 500,
                                    fontSize: 15,
                                    color: "black",
                                }}
                            >{`Showing ${startEntry} to ${endEntry} of ${totalBookings} Records`}</Typography>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default MyBookings;