import { Grid, Modal, TextField, Box, Button, FormControl, OutlinedInput, InputLabel, Chip, MenuItem, Select, Autocomplete, FormHelperText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import Swal from "sweetalert2";
import { useTheme } from '@mui/material/styles';
import { indianCities } from "../../../globalConstants";

const AddSubAdmin = ({ open, setOpen, getAllSubAdmins, selectedSubAdmin, isUpdate }) => {
    const theme = useTheme();
    const options = indianCities;
    const [changePassword, setChangePassword] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
        city: '',
        permissions: []
    });
    useEffect(() => {
        if (selectedSubAdmin) {
            setFormValues({
                name: selectedSubAdmin?.name || '',
                email: selectedSubAdmin?.email || '',
                password: selectedSubAdmin?.password || '',
                phone: selectedSubAdmin?.phone || '',
                city: selectedSubAdmin?.city || '',
                permissions: selectedSubAdmin?.permissions?.map(permission => permission?._id) || []
            });
            setChangePassword(false);
            const permissionIds = selectedSubAdmin?.permissions?.map(permission => permission?._id);
            setSelectedPermissions(permissionIds || []);
        }
    }, [selectedSubAdmin, open]);

    const [permissions, setPermissions] = useState([]);
    const [errors, setErrors] = useState({});
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [permissionMap, setPermissionMap] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [disabled, setDisabled] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleClose = () => {
        setFormValues({
            name: '',
            email: '',
            password: '',
            phone: '',
            city: '',
            permissions: []
        });
        setErrors({});
        setSelectedPermissions([]);
        setOpen(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: 'none',
        outline: 'none',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
    };

    const getAllPermissions = async () => {
        const response = await get_data('sub-admin-permission/get-all-sub-admin-permission-for-options');
        if (response?.status) {
            const permissions = response?.data?.subAdminPermission || [];
            setPermissions(permissions);
            const map = permissions.reduce((acc, perm) => {
                acc[perm._id] = perm.permission_name;
                return acc;
            }, {});
            setPermissionMap(map);
        }
    };

    useEffect(() => {
        getAllPermissions();
    }, []);

    const handlePermissionChange = (event) => {
        const { target: { value } } = event;
        setSelectedPermissions(typeof value === 'string' ? value.split(',') : value);
        setFormValues(prev => ({
            ...prev,
            permissions: typeof value === 'string' ? value.split(',') : value
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');

        if (nameParts.length > 1) {
            setFormValues({
                ...formValues,
                [nameParts[0]]: {
                    ...formValues[nameParts[0]],
                    [nameParts[1]]: value
                }
            });
        } else {
            if (name === 'phone') {
                const phoneValue = value.replace(/\D/g, ''); // Remove non-digit characters
                setFormValues({
                    ...formValues,
                    [name]: phoneValue
                });
            } else {
                setFormValues({
                    ...formValues,
                    [name]: value
                });
            }
        }

        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const validate = () => {
        const errors = {};
        let hasError = false;

        if (!formValues.name) {
            hasError = true;
            errors.name = 'Name is required';
        }
        if (!formValues.email) {
            hasError = true;
            errors.email = 'Email is required';
        }
        if (!formValues.password) {
            hasError = true;
            errors.password = 'Password is required';
        }
        if (!formValues.phone) {
            hasError = true;
            errors.phone = 'Phone is required';
        }
        if (!formValues.city) {
            hasError = true;
            errors.city = 'City is required';
        }
        if (!formValues.permissions.length) {
            hasError = true;
            errors.permissions = 'Permissions is required';
        }
        setErrors(errors);
        return hasError;
    };

    const handleAddSubAdmin = async () => {
        const hasError = validate();
        if (!hasError) {
            setDisabled(true);
            const data = await post_data('sub-admin/create-sub-admin', formValues);

            if (data?.status) {
                handleClose();
                getAllSubAdmins();
                Toast.fire({
                    title: "Sub Admin created successfully",
                    icon: 'success',
                    color: 'green'
                });
            } else {
                Toast.fire({
                    title: "Something went wrong",
                    icon: 'warning',
                    color: 'red'
                });
            }
            setDisabled(false);
        }
    };

    const handleUpdateSubAdmin = async () => {
        const hasError = validate();
        if (!hasError) {
            setDisabled(true);
            const data = await post_data(`sub-admin/update-sub-admin/${selectedSubAdmin?._id}`, formValues);

            if (data?.status) {
                handleClose();
                getAllSubAdmins();
                Toast.fire({
                    title: "Sub Admin updated successfully",
                    icon: 'success',
                    color: 'green'
                });
            } else {
                Toast.fire({
                    title: "Something went wrong",
                    icon: 'warning',
                    color: 'red'
                });
            }
            setDisabled(false);
        }
    };

    const handleUpdatePassword = async () => {
        let hasError = false;

        if (!newPassword) {
            hasError = true;
            setErrors({ password: 'Password is required' });
        }

        if (!hasError) {
            setDisabled(true);
            const data = await post_data(`sub-admin/update-sub-admin-password/${selectedSubAdmin?._id}`, { password: newPassword, email: formValues?.email, name: formValues?.name });
            if (data?.status) {
                handleClose();
                getAllSubAdmins();
                Toast.fire({
                    title: "Password updated successfully",
                    icon: 'success',
                    color: 'green'
                });
            } else {
                Toast.fire({
                    title: "Something went wrong",
                    icon: 'warning',
                    color: 'red'
                });
            }
            setDisabled(false);

        }
    }


    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Grid container spacing={2}>
                    {(isUpdate ? !changePassword : changePassword) && <Grid item xs={6}>
                        <TextField
                            label="Name"
                            name="name"
                            variant="outlined"
                            value={formValues.name}
                            error={!!errors.name}
                            helperText={errors.name}
                            onChange={handleChange}
                        />
                    </Grid>}
                    {(isUpdate ? !changePassword : changePassword) && <Grid item xs={6}>
                        <TextField
                            label="Email"
                            name="email"
                            variant="outlined"
                            value={formValues.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </Grid>}

                    {(isUpdate ? !changePassword : changePassword) && <Grid item xs={6}>
                        <TextField
                            label="Phone"
                            name="phone"
                            value={formValues.phone}
                            inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                            variant="outlined"
                            error={!!errors.phone}
                            helperText={errors.phone}
                            onChange={handleChange}
                        />
                    </Grid>}
                    {(isUpdate ? !changePassword : changePassword) && <Grid item xs={6}>
                        <FormControl fullWidth >
                            <Autocomplete
                                fullWidth
                                value={formValues.city}
                                name="city"
                                onChange={(event, newValue) => {
                                    setFormValues({
                                        ...formValues,
                                        city: newValue
                                    })
                                }}

                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                options={options}
                                renderInput={(params) => <TextField {...params} label="City" error={!!errors.city} helperText={errors.city} />}
                            />
                        </FormControl>
                    </Grid>}

                    {(isUpdate ? !changePassword : changePassword) && <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Permissions</InputLabel>
                            <Select
                                multiple
                                value={selectedPermissions}
                                onChange={handlePermissionChange}
                                input={<OutlinedInput label="Permissions" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((id) => (
                                            <Chip key={id} label={permissionMap[id] || id} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {permissions.map((permission) => (
                                    <MenuItem key={permission._id} value={permission._id}>
                                        {permission.permission_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>}

                    <Grid item xs={changePassword && isUpdate ? 12 : 6}>
                        {changePassword ? <TextField
                            label="Password"
                            name="password"
                            fullWidth
                            variant="outlined"
                            value={changePassword && isUpdate ? newPassword : formValues.password}
                            error={!!errors.password}
                            helperText={errors.password}
                            onChange={changePassword && isUpdate ? (e) => {
                                setNewPassword(e.target.value);
                                setErrors({});
                            } : handleChange}
                        /> :
                            <Typography>
                                <span style={{ cursor: 'pointer', fontSize: '14px', color: "#5db761" }} onClick={() => setChangePassword(true)}>Change Password</span>
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={disabled}
                            sx={{
                                boxShadow: 'none', padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761', boxShadow: 'none' }
                            }}
                            onClick={changePassword && isUpdate ? handleUpdatePassword : isUpdate ? handleUpdateSubAdmin : handleAddSubAdmin}
                        >
                            {changePassword && isUpdate ? "Update Password" : isUpdate ? "Update Sub Admin" : "Add Sub Admin"}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal >
    );
};

export default AddSubAdmin;