import AboutHero from "../Components/AboutHero";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import OurValues from "../Components/OurValues";
import StatsSection from "../Components/StatsSection";
import Testimonials from "../Components/Testimonials";

export default function About() {
    return (
        <>
            <Header />
            <AboutHero />
            <StatsSection />
            <OurValues />
            <Testimonials />
            <Newsletter />
            <Footer />
        </>
    )
}