import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { globalConstant } from '../../globalConstants';
import CloseIcon from '@mui/icons-material/Close';
import OtpComponent from './OtpComponent';
import { post_data } from '../../api';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';

const backdropStyle = {
    backgroundColor: '#000000c5',
};

export default function LoginModal({ open, setOpen }) {

    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [mobileNumber, setMobileNumber] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const { primaryColor } = globalConstant()
    const [otpForm, setOtpForm] = React.useState(false)
    const [loginForm, setLoginForm] = React.useState(true)
    const [loading, setLoading] = React.useState(false)
    const [isSignup, setSignup] = React.useState(false);
    const dispatch = useDispatch()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isDesktop ? 400 : '80%',
        bgcolor: 'white',
        border: 'none',
        boxShadow: 24,
        borderRadius: 3,
        padding: isDesktop ? '4% 2%' : '8% 5%',
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setMobileNumber(value);
            if (value.length === 10) {
                setError(false);
                setHelperText('');
            }
        }
    };


    // const getLocation = () => {
    //     return new Promise((resolve, reject) => {
    //         if (navigator.geolocation) {
    //             navigator.geolocation.getCurrentPosition(resolve, reject);
    //         } else {
    //             reject(new Error('Geolocation is not supported by this browser.'));
    //         }
    //     });
    // };

    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
        if (mobileNumber.length !== 10) {
            setError(true);
            setHelperText('Please enter a 10 digit mobile number.');
        } else {
            setLoading(true)
            setError(false);
            setHelperText('');

            // const position = await getLocation();
            // const lat = position?.coords?.latitude;
            // const long = position?.coords?.longitude;

            const data = await post_data("customer/send-otp-for-phone", {
                phone: mobileNumber
            });

            if (data.status) {
                setOtpForm(true);
                setLoginForm(false);
                // alert(data.data)
            }
            else if (!data.response.data.status && data.response.data.message === "Customer Not Found") {
                const data = await post_data("customer/send-otp-for-signup", {
                    phone: mobileNumber,
                });

                if (data?.status) {
                    setOtpForm(true);
                    setLoginForm(false);
                    setSignup(true);
                    
                    // setTimeout(() => {
                    //     setLoading(false);
                    //     setOpen(false);
                    //     dispatch(login(data?.data?.user));
                    //     localStorage.setItem("authToken", data?.data.token);
                    //     navigate('/');
                    // }, 1000);
                }
                else {
                    setOpen(false);
                    setLoading(false);
                    Toast.fire({
                        title: "Something Went Wrong!",
                        color: "red",
                        icon: "warning"
                    });
                }
                // setOtpForm(true);
                // setLoginForm(false);
            }
            else if (!data.response.data.status && data.response.data.message === "Number is not active") {
                setLoading(false);
                setOpen(false);
                Toast.fire({
                    title: "Number is not active!",
                    color: "red",
                    icon: "warning"
                });
            }
            else {
                setLoading(false);
                setOpen(false);
                Toast.fire({
                    title: "Something Went Wrong!",
                    color: "red",
                    icon: "warning"
                });
            }


        }
    };


    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%'
    }

    return (
        <div>
            <Modal
                BackdropProps={{
                    style: backdropStyle,
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CloseIcon className="modal-close-btn" onClick={handleClose} />
                    {loginForm && (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Enter Mobile Number
                            </Typography>
                            <TextField
                                onKeyPress={(event) => handleEnter(event)}
                                style={{ margin: '20px 0' }}
                                id="outlined-basic"
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                value={mobileNumber}
                                onChange={handleChange}
                                error={error}
                                helperText={helperText}
                                inputProps={{ maxLength: 10 }}
                            />
                            <Button
                                disabled={loading}
                                onClick={handleSubmit}
                                style={button}
                            >
                                {loading ? 'Continue...' : 'Continue'}
                            </Button>
                        </>
                    )}
                    {otpForm && (
                        <div style={{ margin: '5% 0' }}>
                            <OtpComponent
                                mobileNumber={mobileNumber}
                                type={'login'}
                                setOpen={setOpen}
                                handleResend={handleSubmit}
                                isSignup={isSignup}
                            />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}
