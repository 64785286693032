import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export default function SubServicesSkeleton() {

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs>
                    <Skeleton variant="rounded" width={'100%'} height={250} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rounded" width={'100%'} height={250} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rounded" width={'100%'} height={250} />
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '1%' }}>
                <Grid item xs>
                    <Skeleton variant="rounded" width={'100%'} height={250} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rounded" width={'100%'} height={250} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rounded" width={'100%'} height={250} />
                </Grid>
            </Grid>
        </>
    );
}
