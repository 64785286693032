import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import CustomerTable from "../Components/CustomerTable";
import { get_data, post_data } from "../../../api";
import SearchComponent from "../Components/SearchComponent";
import ExportModal from "../Components/ExportModal";
import MembershipCustomersTable from "../Components/MembershipCustomersTable";

export default function MembershipCustomers({ type }) {

    const [loading, setLoading] = useState("true")
    const [customerData, setCustomerData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [value, setValue] = useState('')
    const [open, setOpen] = useState(false)
    const itemsPerPage = 15;
    const [totalPages, setTotalPages] = useState(null);
    const [totalCustomers, setTotalCustomers] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);


    const getAllCustomer = async (pageNumber) => {
        try {
            const data = await get_data(`membership-purchase/get-all-membership-customers?pageNumber=${pageNumber}`);
            if (data.status) {
                setLoader(false);
                setCustomerData(data?.data?.membershipCustomers);
                setTotalPages(data?.data?.totalPages);
                setTotalCustomers(data?.data?.totalCustomers);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error While Fetching All Customers", error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllCustomer(currentPage);
        }
    }, [currentPage])


    const customer_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Phone", grid: 3 },
        { title: "Name", grid: 2 },
        { title: "City", grid: 2 },
        { title: "Activated", grid: 2 },
        { title: "Expiry", grid: 2 },
    ];

    const count = customerData?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...customer_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Membership Customers' />

                {/* <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button variant="contained" style={{ borderRadius: 7, background: 'black', boxShadow: 'none' }} onClick={() => setOpen(true)}>
                        Export
                    </Button>
                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div> */}

                <div style={page_container}>
                    <MembershipCustomersTable
                        tableHeader={tableHeader}
                        data={customerData}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setLoader={setLoader}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPages={totalPages}
                        loader={loader}
                        totalCustomers={totalCustomers}
                        getAllCustomer={getAllCustomer}
                    />
                </div>
                {/* <ExportModal isOpen={open} setOpen={setOpen} type='membershipCustomers' /> */}
            </Grid>
        </>
    )
}