import React from "react"
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useNavigate } from "react-router-dom";
import { globalConstant } from "../../../globalConstants"
import { useSelector } from "react-redux";

export default function Sidebar({
    menu_items
}) {

    const navigate = useNavigate()
    const { primaryColor } = globalConstant()
    const { user_data } = useSelector(state => state.user);

    const user_dashboard_sidebar = {
        background: '#011102',
        width: '100%',
        height: '100%',
        position: 'sticky',
        borderRight: '1px solid gainsboro',
        top: 0
    }

    const logo_section = {
        padding: '7.5% 10%',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
    }

    const sidebar_items_div = {
        padding: '2% 0'
    }

    const handleListItem = (item, i) => {
        if (item?.link) {
            navigate(item.link);
            window.scrollTo(0, 0);
        }
    }

    let current_path = window.location.pathname;

    const filtered_menu_items = user_data?.is_sub_admin
        ? menu_items?.filter(item => item?.title !== 'Sub Admins' && item?.title !== 'Permissions')
        : menu_items;

    const display_sidebar_items = () => {
        return (
            <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
                {filtered_menu_items?.map((item, i) => {
                    return (
                        <div>
                            <ListItemButton
                                key={i}
                                onClick={() => handleListItem(item, i)}
                                style={{
                                    padding: '6%',
                                    background: item?.type !== 'dropdown' ? current_path === item.link ? primaryColor : 'transparent' : ''
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    gap: '8%',
                                    width: '100%',
                                    alignItems: item?.type !== 'dropdown' ? "center" : 'center'
                                }}>
                                    {item.icon}
                                    <p style={{ color: current_path === item?.link ? 'white' : 'white', fontWeight: 400, fontSize: 14, margin: 0, padding: 0 }}>
                                        {item?.type !== 'dropdown' ? item?.title : ''}
                                    </p>
                                </div>
                            </ListItemButton>
                        </div>
                    );
                })}
            </List>
        )
    }

    return (
        <>
            <div style={user_dashboard_sidebar} className="sidebar-div">
                <div style={logo_section}>
                    <img src={`/images/nivishka-icon.png`} style={{ width: '40%', cursor: 'pointer' }} />
                </div>
                <div style={sidebar_items_div}>
                    {display_sidebar_items()}
                </div>
            </div>
        </>
    )
}