import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box, CircularProgress, Divider, Avatar, Select, MenuItem, IconButton, Tooltip as MuiTooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Doughnut, Line, Pie } from 'react-chartjs-2';
import moment from 'moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { formatDateTime, formatPrice } from '../../../globalConstants';
import { FaThumbsUp, FaShippingFast, FaUndo } from 'react-icons/fa';
import { LuDot } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { get_data, post_data } from '../../../api';
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from 'react-redux';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

const Section = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
}));

const IconAvatar = styled(Avatar)(({ theme, color }) => ({
    backgroundColor: color,
    width: theme.spacing(5),
    height: theme.spacing(5),
}));

const statsTitle = {
    color: 'black',
    fontWeight: 500
};

const notification_div = {
    borderBottom: "1px solid #F1F1F2",
    padding: "1% 3%",
    display: "flex",
    alignItems: "start",
    textAlign: 'left',
    cursor: 'pointer'
};

const StatisticsComp = ({ socket, permissions }) => {

    const navigate = useNavigate();
    const { user_data } = useSelector(state => state.user);

    const handleClick = () => {
        navigate('/admin/dashboard/bookings');
    };

    const [chartLabels, setChartLabels] = useState([]);
    const [chartValue, setChartValue] = useState([]);
    const [totalSales, setTotalSales] = useState(0);
    const [city, setCity] = useState('Gwalior');
    const [memberShipCity, setMemberShipCity] = useState('Gwalior');
    const [grossTotalSales, setGrossTotalSales] = useState('')
    const [customers, setCustomers] = useState('')
    const [vendors, setVendors] = useState('')
    const [successfullBookings, setSuccessfullBookings] = useState('')
    const [amountRefunded, setAmountRefunded] = useState('')
    const [membershipCustomerCount, setMembershipCustomerCount] = useState('');
    const [totalMemberShipSales, setTotalMemberShipSales] = useState('');
    const [memberShipChartLabels, setMemberShipChartLabels] = useState('');
    const [memberShipChartValue, setMemberShipChartValue] = useState('');
    const [latestBookings, setLatestBookings] = useState([]);
    const [newCustomers, setNewCustomers] = useState([]);
    const [monthBookings, setMonthBookings] = useState([]);
    const [cities, setCitites] = useState([]);

    const defaultRange = [moment().subtract(30, 'days').toDate(), moment().toDate()];
    const [dateRange, setDateRange] = useState(defaultRange);
    const [startDate, endDate] = dateRange;
    const [memberShipDateRange, setMemberShipDateRange] = useState(defaultRange);
    const [startMemberShipDate, endMemberShipDate] = memberShipDateRange;

    const getCities = async () => {
        const data = await get_data("service-area/get-all-service-area");
        const city = [...new Set(data?.data?.map((item) => item?.city))];
        setCitites(city)
    }

    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    useEffect(() => {
        if (startDate && endDate && city) {
            fetchData();
        }
    }, [dateRange, city]);


    const handleMemberShipDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setMemberShipDateRange(dates);
        }
    };


    useEffect(() => {
        if (startMemberShipDate && endMemberShipDate && memberShipCity) {
            fetchMemberShipData();
        }
    }, [memberShipCity, memberShipDateRange]);

    const rangePickerStyle = {
        display: 'flex',
        position: 'absolute',
        right: '1%',
        top: '2%',
        fontSize: 14,
        background: '#F1F1F2',
        color: '#7E8299',
        border: 'none',
        cursor: 'pointer'
    };

    const getStatisticsData = async () => {
        const response = await get_data('booking/get-dashboard-stats')
        if (response?.status) {
            setGrossTotalSales(response?.data?.totalSales)
            setCustomers(response?.data?.customerCount)
            setVendors(response?.data?.vendorCount)
            setAmountRefunded(response?.data?.amountRefunded)
            setSuccessfullBookings(response?.data?.successfullBookings)
            setMembershipCustomerCount(response?.data?.membershipCustomerCount)
        }
    }

    const getLatestBookings = async () => {
        const response = await get_data('booking/get-latest-bookings')
        if (response?.status) {
            setLatestBookings(response?.data)
        }
    }

    const getNewcustomers = async () => {
        const response = await get_data('customer/get-new-customers')
        if (response?.status) {
            setNewCustomers(response?.data)
        }
    }

    const getMonthBookings = async () => {
        const response = await get_data('booking/get-bookings-of-current-month')
        if (response?.status) {
            setMonthBookings(response?.data)
        }
    }

    useEffect(() => {
        socket.on("booking-created", (data) => {
            if (data?.status) {
                getLatestBookings();
            }
        })
    }, [socket])


    useEffect(() => {
        getStatisticsData()
        getLatestBookings()
        getMonthBookings()
        getCities()
        getNewcustomers()
    }, [])

    const fetchData = async () => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            const response = await post_data('booking/get-all-bookings-by-city-date', {
                from: fromDate,
                to: toDate,
                city: city
            });

            if (response?.data) {
                const fetchedLabels = response?.data?.map((entry) => entry?.fromDate);
                const fetchedValues = response?.data?.map((entry) => entry?.totalSales);

                const getTotalValue = (data) => {
                    return data?.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue?.totalSales;
                    }, 0);
                };

                const total_sales = getTotalValue(response?.data);

                setTotalSales(total_sales || 0);
                setChartLabels(fetchedLabels || []);
                setChartValue(fetchedValues || []);
            } else {
                setChartLabels([]);
                setChartValue([]);
                setTotalSales(0);
            }
        } catch (error) {
            setChartLabels([]);
            setChartValue([]);
            setTotalSales(0);
        }
    };

    const fetchMemberShipData = async () => {
        try {
            const fromDate = moment(startMemberShipDate).toISOString();
            const toDate = moment(endMemberShipDate).toISOString();

            const response = await post_data('membership-purchase/get-all-membership-purchased-by-city-date', {
                from: fromDate,
                to: toDate,
                city: memberShipCity
            });

            if (response?.data) {
                const fetchedLabels = response?.data?.map((entry) => entry?.fromDate);
                const fetchedValues = response?.data?.map((entry) => entry?.totalSales);

                const getTotalValue = (data) => {
                    return data?.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue?.totalSales;
                    }, 0);
                };

                const total_sales = getTotalValue(response?.data);

                setTotalMemberShipSales(total_sales || 0);
                setMemberShipChartLabels(fetchedLabels || []);
                setMemberShipChartValue(fetchedValues || []);
            } else {
                setMemberShipChartLabels([]);
                setMemberShipChartValue([]);
                setTotalMemberShipSales(0);
            }
        } catch (error) {
            setMemberShipChartLabels([]);
            setMemberShipChartValue([]);
            setTotalMemberShipSales(0);
        }
    };

    const salesData = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Sales',
                fill: true,
                data: chartValue,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
            },
        ],
    };

    const earningsData = {
        labels: memberShipChartLabels,
        datasets: [
            {
                label: 'Membership purchased',
                fill: true,
                data: memberShipChartValue,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
            },
        ],
    };

    const doughnutData = {
        labels: ['Scheduled', 'Completed', 'Cancelled'],
        datasets: [
            {
                data: [monthBookings?.scheduled, monthBookings?.completed, monthBookings?.cancelled],
                backgroundColor: ['#ebe300', '#02c913', '#ff0000'],
            },
        ],
    };

    return (
        <>
            <Box sx={{ flexGrow: 1, padding: '1% 0' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', height: 130, position: 'relative' }}>
                            <MuiTooltip title='Total Completed Sales'>
                                <IconButton style={{ position: 'absolute', right: 0, top: 0, }}>
                                    <IoIosInformationCircleOutline style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </MuiTooltip>
                            <IconAvatar color="#FFB300">
                                <CheckCircleIcon />
                            </IconAvatar>
                            <Typography variant="h6">Total Sales</Typography>
                            <Typography variant="h4" style={statsTitle}>{formatPrice(grossTotalSales)}</Typography>
                        </Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', height: 130, position: 'relative' }}>
                            <MuiTooltip title='Total Customers'>
                                <IconButton style={{ position: 'absolute', right: 0, top: 0, }}>
                                    <IoIosInformationCircleOutline style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </MuiTooltip>
                            <IconAvatar color="#29B6F6">
                                <CheckCircleIcon />
                            </IconAvatar>
                            <Typography variant="h6">Total Customer</Typography>
                            <Typography variant="h4" style={statsTitle}>{customers || 0}</Typography>
                        </Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', height: 130, position: 'relative' }}>
                            <MuiTooltip title='Total Vendors'>
                                <IconButton style={{ position: 'absolute', right: 0, top: 0, }}>
                                    <IoIosInformationCircleOutline style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </MuiTooltip>
                            <IconAvatar color="#66BB6A">
                                <CheckCircleIcon />
                            </IconAvatar>
                            <Typography variant="h6">Total Vendors</Typography>
                            <Typography variant="h4" style={statsTitle}>{vendors || 0}</Typography>
                        </Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', height: 130, position: 'relative' }}>
                            <MuiTooltip title='Total successfull Bookings'>
                                <IconButton style={{ position: 'absolute', right: 0, top: 0, }}>
                                    <IoIosInformationCircleOutline style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </MuiTooltip>
                            <IconAvatar color="#FF7043">
                                <CheckCircleIcon />
                            </IconAvatar>
                            <Typography variant="h6">Total Successfull Bookings</Typography>
                            <Typography variant="h4" style={statsTitle}>{successfullBookings || 0}</Typography>
                        </Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ marginTop: '1%' }}>
                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', position: 'relative' }}>
                            <Typography variant="h6">Total Sales</Typography>
                            <Typography variant="h4" style={statsTitle}>{formatPrice(totalSales)}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ position: 'absolute', left: '2%', top: '2%' }}>
                                    <Select
                                        size='small'
                                        value={city}
                                        onChange={handleCityChange}
                                        style={{ width: '150px', marginRight: '20px' }}
                                    >
                                        {cities?.map((cityName) => (
                                            <MenuItem key={cityName} value={cityName}>
                                                {cityName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={rangePickerStyle}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        dateFormat="dd-MM-yyyy"
                                        maxDate={new Date()}
                                        customInput={
                                            <button>
                                                {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                            </button>
                                        }
                                    />
                                </div>
                            </div>
                            <Line data={salesData} />
                        </Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{ marginTop: '1%' }}>
                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro' }}>
                            <Typography variant="h6">Bookings this Month</Typography>
                            <Typography variant="h4" style={statsTitle}>
                                {(parseInt(monthBookings?.scheduled) + parseInt(monthBookings?.completed) + parseInt(monthBookings?.cancelled)) || 0}
                            </Typography>
                            <div style={{ marginTop: 20 }}><Doughnut data={doughnutData} /></div>
                        </Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{ marginTop: '1%' }}>

                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', paddingLeft: 0, paddingRight: 0 }}>
                            <Typography variant="h6">Recent Bookings</Typography>

                            {
                                user_data?.is_sub_admin ? (
                                    permissions?.includes('bookings') ? (
                                        <>
                                            {latestBookings?.map((item, i) => (
                                                <div key={i} onClick={handleClick} style={notification_div} className='notifications-box'>
                                                    <div> <LuDot style={{ marginTop: 17 }} /> </div>
                                                    <div>
                                                        <h3 style={{ color: "#3F4254", fontSize: 16, marginBottom: 1, fontWeight: 400 }}>
                                                            New Booking for {item?.service_name} Services
                                                        </h3>
                                                        <p style={{ color: "#B5B5C3", fontSize: 14, fontWeight: 400 }}>
                                                            {item?.customer_name || 'Someone'} booked a {item?.service_name} service for {item?.service_date.split(" ").join("-")} at {item?.service_time} from {item?.city}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <>No Permission</>
                                    )
                                ) : (
                                    <>
                                        {latestBookings?.map((item, i) => (
                                            <div key={i} onClick={handleClick} style={notification_div} className='notifications-box'>
                                                <div> <LuDot style={{ marginTop: 17 }} /> </div>
                                                <div>
                                                    <h3 style={{ color: "#3F4254", fontSize: 16, marginBottom: 1, fontWeight: 400 }}>
                                                        New Booking for {item?.service_name} Services
                                                    </h3>
                                                    <p style={{ color: "#B5B5C3", fontSize: 14, fontWeight: 400 }}>
                                                        {item?.customer_name || 'Someone'} booked a {item?.service_name} service for {item?.service_date.split(" ").join("-")} at {item?.service_time} from {item?.city}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )
                            }

                        </Section>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} style={{ marginTop: '3%' }}>
                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', height: 130, position: 'relative' }}>
                            <MuiTooltip title='Total Plus Membership Customers'>
                                <IconButton style={{ position: 'absolute', right: 0, top: 0, }}>
                                    <IoIosInformationCircleOutline style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </MuiTooltip>
                            <IconAvatar color="#FFB300">
                                <CheckCircleIcon />
                            </IconAvatar>
                            <Typography variant="h6">Plus Membership Customers</Typography>
                            <Typography variant="h4" style={statsTitle}>{membershipCustomerCount}</Typography>
                        </Section>

                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', height: 130, position: 'relative', marginTop: 20 }}>
                            <MuiTooltip title='Total amount Refunded to Customers'>
                                <IconButton style={{ position: 'absolute', right: 0, top: 0, }}>
                                    <IoIosInformationCircleOutline style={{ width: 25, height: 25 }} />
                                </IconButton>
                            </MuiTooltip>
                            <IconAvatar color="#55AD9B">
                                <CheckCircleIcon />
                            </IconAvatar>
                            <Typography variant="h6">Amount Refunded</Typography>
                            <Typography variant="h4" style={statsTitle}>{formatPrice(amountRefunded)}</Typography>
                        </Section>

                    </Grid>

                    <Grid item xs={12} sm={6} md={3} style={{ marginTop: '3%' }}>
                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', paddingLeft: 0, paddingRight: 0 }}>
                            <Typography variant="h6" style={{ marginBottom: 10 }}>New Customers</Typography>
                            {
                                user_data?.is_sub_admin ? (
                                    permissions?.includes('customers') ? (
                                        <>
                                            {newCustomers?.map((item, i) => (
                                                <div key={i} onClick={() => navigate('/admin/dashboard/customers')} style={{ ...notification_div, display: 'flex', alignItems: 'start', gap: 10, padding: '4% 3%' }} className='notifications-box'    >
                                                    <div>
                                                        <FaUserCircle style={{ color: 'gainsboro', height: 30, width: 30 }} />
                                                    </div>
                                                    <div>
                                                        <h3 style={{ color: "#3F4254", fontSize: 16, marginTop: 0, marginBottom: 1, fontWeight: 400 }}>
                                                            {item?.customer_number}
                                                        </h3>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <>No Permission</>
                                    )
                                ) : (
                                    <>
                                        {newCustomers?.map((item, i) => (
                                            <div key={i} onClick={() => navigate('/admin/dashboard/customers')} style={{ ...notification_div, display: 'flex', alignItems: 'start', gap: 10, padding: '4% 3%' }} className='notifications-box'    >
                                                <div>
                                                    <FaUserCircle style={{ color: 'gainsboro', height: 30, width: 30 }} />
                                                </div>
                                                <div>
                                                    <h3 style={{ color: "#3F4254", fontSize: 16, marginTop: 0, marginBottom: 1, fontWeight: 400 }}>
                                                        {item?.customer_number}
                                                    </h3>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )
                            }
                        </Section>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ marginTop: '3%' }}>
                        <Section style={{ boxShadow: 'none', border: '1px solid gainsboro', position: 'relative' }}>
                            <Typography variant="h6">Membership Sales</Typography>
                            <Typography variant="h4" style={statsTitle}>{formatPrice(totalMemberShipSales)}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ position: 'absolute', left: '2%', top: '2%' }}>
                                    <Select
                                        size='small'
                                        value={memberShipCity}
                                        onChange={(e) => setMemberShipCity(e.target.value)}
                                        style={{ width: '150px', marginRight: '20px' }}
                                    >
                                        {cities?.map((cityName) => (
                                            <MenuItem key={cityName} value={cityName}>
                                                {cityName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={rangePickerStyle}>
                                    <DatePicker
                                        selected={startMemberShipDate}
                                        onChange={handleMemberShipDateChange}
                                        startDate={startMemberShipDate}
                                        endDate={endMemberShipDate}
                                        selectsRange
                                        dateFormat="dd-MM-yyyy"
                                        maxDate={new Date()}
                                        customInput={
                                            <button>
                                                {startMemberShipDate && endMemberShipDate ? `${moment(startMemberShipDate).format('DD-MM-YYYY')} to ${moment(endMemberShipDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                            </button>
                                        }
                                    />
                                </div>
                            </div>
                            <Line data={earningsData} />
                        </Section>
                    </Grid>

                </Grid>
            </Box>
        </>
    );
};

export default StatisticsComp;
