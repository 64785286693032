import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import NewslettersTable from "../Components/NewslettersTable";
import ExportModal from "../Components/ExportModal";
import ServiceAreasTable from "../Components/ServiceAreasTable";

export default function ServiceAreas({ type }) {

    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);

    const permissions_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };


    const getCitiesStates = async () => {
        try {
            const data = await get_data(`service-area/get-all-service-area`);
            if (data.status) {
                setLoader(false);
                setData(data?.data);
            }
        } catch (error) {
            setLoader(false);
        }
    }

    useEffect(() => {
        getCitiesStates();
    }, [])


    useEffect(() => {
        setLoading(false);
    }, []);


    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "City", grid: 3 },
        { title: "State", grid: 4 },
        { title: "Pincodes", grid: 2 },
        { title: "Action", grid: 2 },
    ];


    return (
        <>
            <Grid container spacing={0} style={{ ...permissions_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Service Areas' />
                <div style={page_container}>
                    <ServiceAreasTable
                        tableHeader={tableHeader}
                        type="permissions"
                        data={data}
                        loader={loader}
                        setLoader={setLoader}
                        getAllServiceArea={getCitiesStates}
                    />
                </div>
            </Grid>
        </>
    )
}