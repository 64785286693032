import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { formatPrice } from '../../../globalConstants';

const VendorEarningModal = ({ open, setOpen, currentVendor }) => {

    console.log('open', open)

    const handleClose = () => {
        setOpen(false);
    };

    console.log('current vendor ;: ', currentVendor);

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        }
    };


    const heading = {
        fontWeight: 600,
        fontSize: 22,
        marginBottom: '10px',
        textAlign: 'left',
        p: 3,
        borderBottom: '1px solid gainsboro'
    }

    const DataRow = ({ label, value }) => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2% 0',
        }}>
            {
                label === 'thirdLast' ?
                    <>
                        <Typography variant="body1">{getMonthNameByLabel(label)}:</Typography>
                        <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{formatPrice(getThirdLastMonthEarnings(value))}</Typography>
                    </>
                    :
                    label === 'secondLast' ?
                        <>
                            <Typography variant="body1">{getMonthNameByLabel(label)}:</Typography>
                            <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{formatPrice(getSecondLastMonthEarnings(value))}</Typography>
                        </>
                        :
                        label === 'lastMonth'
                            ?
                            <>
                                <Typography variant="body1">{getMonthNameByLabel(label)}:</Typography>
                                <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{formatPrice(getLastMonthEarnings(value))}</Typography>
                            </>
                            :
                            <>
                                <Typography variant="body1">{label}:</Typography>
                                <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{formatPrice(getCurrentMonthEarnings(value))}</Typography>
                            </>
            }
        </Box>
    )

    function getMonthNameByLabel(label) {
        const currentDate = new Date();
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        let monthIndex;
        switch (label) {
            case 'lastMonth':
                monthIndex = currentDate.getMonth() - 1;
                break;
            case 'secondLast':
                monthIndex = currentDate.getMonth() - 2;
                break;
            case 'thirdLast':
                monthIndex = currentDate.getMonth() - 3;
                break;
            default:
                return null;
        }

        if (monthIndex < 0) {
            monthIndex += 12;
        }

        return monthNames[monthIndex];
    }

    function getThirdLastMonthEarnings(data) {
        const currentDate = new Date();
        const thirdLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
        const thirdLastMonthIndex = thirdLastMonth.getMonth();
        const thirdLastYear = thirdLastMonth.getFullYear();

        return data.reduce((total, entry) => {
            const date = new Date(entry.date);
            if (date.getMonth() === thirdLastMonthIndex && date.getFullYear() === thirdLastYear) {
                return total + entry.earnings;
            }
            return total;
        }, 0);
    }

    function getSecondLastMonthEarnings(data) {
        const currentDate = new Date();
        const secondLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
        const secondLastMonthIndex = secondLastMonth.getMonth();
        const secondLastYear = secondLastMonth.getFullYear();

        return data.reduce((total, entry) => {
            const date = new Date(entry.date);
            if (date.getMonth() === secondLastMonthIndex && date.getFullYear() === secondLastYear) {
                return total + entry.earnings;
            }
            return total;
        }, 0);
    }

    function getLastMonthEarnings(data) {
        const currentDate = new Date();
        const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        const lastMonthIndex = lastMonth.getMonth();
        const lastYear = lastMonth.getFullYear();

        return data.reduce((total, entry) => {
            const date = new Date(entry.date);
            if (date.getMonth() === lastMonthIndex && date.getFullYear() === lastYear) {
                return total + entry.earnings;
            }
            return total;
        }, 0);
    }

    function getCurrentMonthEarnings(data) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        return data.reduce((total, entry) => {
            const date = new Date(entry.date);
            if (date.getMonth() === currentMonth && date.getFullYear() === currentYear) {
                return total + entry.earnings;
            }
            return total;
        }, 0);
    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                border: 'none',
                "& .MuiBackdrop-root": {
                }
            }}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={heading}>
                    {currentVendor?.full_name || 'Vendor'}'s Earning
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, padding: '2% 6% 6% 6%' }}>
                    <DataRow label="Current Month Earning" value={currentVendor?.total_earnings || '-'} />
                    <DataRow label="lastMonth" value={currentVendor?.total_earnings || '-'} />
                    <DataRow label="secondLast" value={currentVendor?.total_earnings || '-'} />
                    <DataRow label="thirdLast" value={currentVendor?.total_earnings || '-'} />
                </Typography>
            </Box>
        </Modal>
    );
};

export default VendorEarningModal;