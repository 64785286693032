import React, { useEffect, useState } from "react";
import { Grid, Typography, Pagination, Switch, Box, Modal, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, FormGroup, FormControlLabel, Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { MdDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { get_data, post_data } from "../../../api";
import Loader from "../../../website/Components/Loader";
import { IoIosInformationCircleOutline } from "react-icons/io";
import CustomerDetail from "./CustomerDetail";
import styled from 'styled-components';

const ServiceBox = styled.div`
display: flex;
width: 100%;
height:100%;
background: #fff;
box-sizing:border-box;
overflow: hidden;
`;

export default function CustomerTable({
    tableHeader,
    data,
    itemsPerPage = 6,
    currentPage,
    setCurrentPage,
    count,
    totalPages,
    addCustomer,
    setAddCustomer,
    setLoader,
    loader,
    getAllCustomer,
    totalCustomers,
}) {

    const [switchLoaders, setSwitchLoaders] = useState({});
    const [open, setOpen] = useState(false);
    const [currentCustomer, setCurrentCustomer] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [updateID, setUpdateID] = useState('');
    const [states, setStates] = useState([])
    const [cities, setCitites] = useState([])
    const [isActive, setIsActive] = useState(true);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        maxHeight: '80%',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: 'none',
        outline: 'none',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalCustomers);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const handleDeleteCustomer = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#5DB761",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`customer/delete-customer/${id}`, {})
                    if (data.status) {
                        Toast.fire({
                            title: "Customer Deleted!",
                            color: "green",
                            icon: "success"
                        });
                        getAllCustomer();
                    }
                    else {
                        Toast.fire({
                            title: "Something Went Wrong!",
                            color: "red",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    console.log("error while delete service ", error);
                }
            }
        });
    };

    const handleSwitch = async (item) => {
        setSwitchLoaders(prev => ({ ...prev, [item._id]: true }));
        const updatedItem = { ...item, is_active: !item.is_active };
        const data = await post_data(`customer/update-customer/${item._id}`, { is_active: updatedItem.is_active });
        if (data.status) {
            Toast.fire({
                title: "Customer Status updated!",
                color: "green",
                icon: "success"
            });
            getAllCustomer();
        } else {
            Toast.fire({
                title: "Something Went Wrong!",
                color: "red",
                icon: "warning"
            });
        }
        setSwitchLoaders(prev => ({ ...prev, [item._id]: false }));
    };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };


    const handleClose = () => {
        setFullName('');
        setPhone('');
        setStreet('');
        setCity('');
        setEmail('')
        setState('');
        setZipCode('');
        setAddCustomer(false)
    }

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }

    const validate = () => {
        let error = false;
        if (fullName.length === 0) {
            error = true;
            handleError("fullName", "Full Name is required");
        }
        if (phone.length === 0) {
            error = true;
            handleError("phone", "Phone is required");
        }
        if (email.length === 0) {
            error = true;
            handleError("email", "Email is required");
        }
        if (city.length === 0) {
            error = true;
            handleError("city", "City is required");
        }
        return error;
    }


    const handleAddCustomer = async () => {
        if (!validate()) {

            const data = {
                full_name: fullName, phone, email,
                address: [{ street, city, state, zip_code: zipCode, country: "India" }],
                is_active: isActive,
            }

            const res = await post_data("customer/create-customer", data);
            if (res?.status) {
                Toast.fire({
                    title: "Customer Created",
                    color: "green",
                    icon: "success"
                });
                getAllCustomer();
            }
            else {
                Toast.fire({
                    title: "Something Went Wrong!",
                    color: "red",
                    icon: "warning"
                });
            }
            handleClose();
        }
    }


    const handleUpdateCustomer = async () => {
        if (!validate()) {

            const data = {
                full_name: fullName,
                phone, email,
                address: [{ street, city, state, zip_code: zipCode, country: "India" }],
                is_active: isActive,
            }

            const res = await post_data(`customer/update-customer/${updateID}`, data);
            if (res?.status) {
                Toast.fire({
                    title: "Customer Updated",
                    color: "green",
                    icon: "success"
                });
                getAllCustomer();
            }
            else {
                Toast.fire({
                    title: "Something Went Wrong!",
                    color: "red",
                    icon: "warning"
                });
            }
            handleClose();
        }
    }


    const getCityAndStates = async () => {
        const data = await get_data("service-area/get-all-service-area");
        if (data?.status) {
            const city = [...new Set(data?.data?.map((item) => item?.city))];
            setCitites(city);

            const state = [...new Set(data?.data?.map((item) => item?.state))];
            setStates(state);
        }
    }


    useEffect(() => {
        getCityAndStates();
    }, [])


    const handleSetUpdateCustomer = (item) => {
        setIsUpdate(true);
        setUpdateID(item?._id);
        setFullName(item?.full_name || '');
        setEmail(item?.email || '')
        setPhone(item?.phone || '');
        setStreet(item?.address[0]?.street || '');
        setCity(item?.address[0]?.city || '');
        setState(item?.address[0]?.state || '');
        setZipCode(item?.address[0]?.zip_code || '');
        setIsActive(item?.is_active);
        setAddCustomer(true);
    }

    const displayAllHeader = () => {
        return (
            <div>
                {open && <CustomerDetail getAllCustomer={getAllCustomer} open={open} setOpen={setOpen} currentCustomer={currentCustomer} />}

                <Modal
                    open={addCustomer}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <Typography>
                            <ServiceBox>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="outlined"
                                            label="Enter Name"
                                            value={fullName}
                                            error={errors.fullName}
                                            helperText={errors.fullName}
                                            sx={{ mt: 2 }}
                                            onChange={(e) => {
                                                setFullName(e.target.value);
                                            }}
                                            onFocus={() => {
                                                setErrors({ ...errors, fullName: "" })
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            variant="outlined"
                                            label="Enter Email"
                                            value={email}
                                            error={errors.email}
                                            helperText={errors.email}
                                            sx={{ mt: 2 }}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            onFocus={() => {
                                                setErrors({ ...errors, email: "" })
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            variant="outlined"
                                            label="Enter Phone"
                                            value={phone}
                                            error={errors.phone}
                                            helperText={errors.phone}
                                            sx={{ mt: 2 }}
                                            onChange={(e) => {
                                                setPhone(e.target.value);
                                            }}
                                            onFocus={() => {
                                                setErrors({ ...errors, phone: "" })
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Street"
                                            name="street"
                                            sx={{ mt: 2 }}
                                            variant="outlined"
                                            value={street}
                                            onChange={(e) => {
                                                setStreet(e.target.value)
                                            }}
                                            error={!!errors.street}
                                            helperText={errors.street}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl fullWidth sx={{ mt: 2 }} variant="outlined" error={!!errors.city}>
                                            <InputLabel>City</InputLabel>
                                            <Select
                                                name="city"
                                                value={city}
                                                onChange={(e) => {
                                                    setCity(e.target.value);
                                                    setErrors({ ...errors, city: false })
                                                }}
                                                label="City"
                                            >
                                                {cities?.map((city) => (
                                                    <MenuItem key={city} value={city}>{city}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
                                        </FormControl>
                                    </Grid>


                                    <Grid item xs={6}>
                                        <FormControl sx={{ mt: 2 }} fullWidth variant="outlined" error={!!errors.state}>
                                            <InputLabel>States</InputLabel>
                                            <Select
                                                name="state"
                                                value={state}
                                                onChange={(e) => {
                                                    setState(e.target.value);
                                                }}
                                                label="State"
                                            >
                                                {states?.map((state) => (
                                                    <MenuItem key={state} value={state}>{state}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.state && <FormHelperText>{errors.state}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Zip Code"
                                            name="zipCode"
                                            sx={{ mt: 2 }}
                                            type="number"
                                            variant="outlined"
                                            value={zipCode}
                                            onChange={(e) => {
                                                setZipCode(e.target.value)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormGroup>
                                            <InputLabel>Active</InputLabel>
                                            <FormControlLabel control={
                                                <Switch
                                                    checked={isActive}
                                                    onChange={(e) => {
                                                        setIsActive(e.target.checked)
                                                    }} />} />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            disabled={disabled}
                                            sx={{
                                                padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
                                            }}
                                            onClick={isUpdate ? handleUpdateCustomer : handleAddCustomer}
                                        >
                                            {isUpdate ? "Update Customer" : "Add Customer"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ServiceBox>
                        </Typography>
                    </Box>
                </Modal>

                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        : data?.length > 0 ? data?.map((item, i) => (
                            <div style={{ width: "100%" }} key={i}>
                                <Grid
                                    container
                                    spacing={0}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        padding: 5,
                                        alignItems: "center",
                                        borderTop: "1px dashed gainsboro",
                                    }}
                                >
                                    <Grid item xs={1}>
                                        <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>{startEntry + i}</h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                margin: 0,
                                                fontWeight: 500,
                                                fontSize: 14,
                                                color: "#3F4254", margin: 0, padding: 0
                                            }}
                                        >
                                            {item?.phone || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}
                                    >
                                        <h3
                                            style={{
                                                ...table_row_font,
                                                color: "#3F4254", margin: 0, padding: 0
                                            }}
                                        >
                                            {item?.full_name || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                            {item?.email || '-'}
                                        </h3>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                margin: 0,
                                                fontSize: 14,
                                                borderRadius: 4,
                                                textAlign: "center",
                                                fontWeight: 500, margin: 0, padding: 0
                                            }}
                                        >
                                            {item?.address?.[0]?.city || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center", margin: 0, padding: 0,
                                            color: "#3F4254",
                                        }}
                                    >
                                        <div style={{ width: "60%", margin: "auto" }}>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    borderRadius: 4,
                                                    textAlign: "center",
                                                    fontWeight: 500, margin: 0, padding: 0
                                                }}
                                            >
                                                {switchLoaders[item._id] ? <CircularProgress size={20} />
                                                    : <Switch onChange={() => handleSwitch(item)} {...label} checked={item.is_active} />}
                                            </p>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1}
                                        style={{ display: "flex", justifyContent: "center", gap: '10px', alignItems: 'center' }}
                                    >
                                        <img onClick={() => handleSetUpdateCustomer(item)} src="/images/edit-img.svg" style={{ cursor: 'pointer' }} />
                                        <img onClick={() => handleDeleteCustomer(item?._id)} src="/images/delete-img.svg" style={{ cursor: 'pointer' }} />
                                        <IoIosInformationCircleOutline style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => { setOpen(true); setCurrentCustomer(item); }} />
                                    </Grid>
                                </Grid>
                            </div>
                        ))
                            :
                            <div>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#3F4254",
                                        marginTop: '5%'
                                    }}
                                >
                                    No Customer Found...
                                </Grid>
                            </div>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalCustomers} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}
