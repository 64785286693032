import styled from 'styled-components';
import { useState } from 'react';
import { post_data } from '../../../api';
import Swal from "sweetalert2";
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { login } from '../../../redux/slices/user-slice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Container = styled.div`
  width:100vw; 
  height: 100vh;
  background: linear-gradient(to right, #E1F2E2, white);
`;

const LoginBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const FormSection = styled.div`
  width: 25%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  boxSizing:border-box;
`;

const ImageSection = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  margin-bottom: 1px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const Subtitle = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Link = styled.a`
  font-size: 14px;
  color: #6c63ff;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

function ResetPassword() {

    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    let { token } = useParams();

    const dispatch = useDispatch();

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }));
    };

    const validate = () => {
        let error = false;

        if (password.length === 0) {
            error = true;
            handleError('password', 'Password is Required');
        }

        if (confirmPassword.length === 0) {
            error = true;
            handleError('confirmPassword', 'Confirm Password is Required');
        }

        if (password !== confirmPassword) {
            error = true;
            handleError('confirmPassword', 'Passwords do not match');
        }

        return error;
    };

    const handleResetPassword = async () => {
        const error = validate();
        if (!error) {
            try {
                setLoader(true);
                const data = await post_data("super-admin/reset-password", { new_password: password, token: token });
                if (data.status) {
                    Toast.fire({
                        title: "Password Reset Successfully",
                        icon: "success",
                        color: "green"
                    });
                    setPassword('');
                    setConfirmPassword('');
                    setLoader(false);
                    dispatch(login(data?.data?.user));
                    localStorage.setItem("authToken", data?.data.token);
                    navigate('/admin/login');
                }
                else {
                    setLoader(false);
                    Toast.fire({
                        title: "Error resetting password",
                        icon: "error",
                        color: "red"
                    });
                }
            } catch (error) {
                setLoader(false);
                // console.log("Error While Resetting Password", error);
            }
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    return (
        <Container>
            <LoginBox>
                <FormSection>
                    <Logo>
                        <img src="/images/nivishka-logo.png" alt="Logo" style={{ width: "30%" }} />
                    </Logo>
                    <Title>Reset Your Password!</Title>
                    <Subtitle>Please enter your new password and confirm it</Subtitle>
                    <TextField
                        className={errors.password ? "errorborder" : ""}
                        fullWidth
                        label="Enter New Password"
                        name="newPassword"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={() => setErrors({ ...errors, password: "" })}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.password}</small>
                    <TextField
                        className={errors.confirmPassword ? "errorborder" : ""}
                        fullWidth
                        label="Confirm New Password"
                        name="confirmPassword"
                        type={showPassword2 ? 'text' : 'password'}
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onFocus={() => setErrors({ ...errors, confirmPassword: "" })}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword2}
                                        edge="end"
                                    >
                                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.confirmPassword}</small>
                    <Button variant='contained'
                        style={{
                            boxShadow: 'none', padding: '3% 0', background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
                        }}
                        disabled={loader} onClick={handleResetPassword}>Reset Password</Button>
                </FormSection>
            </LoginBox>
        </Container>
    );
};

export default ResetPassword;