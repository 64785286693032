import React, { useEffect, useState } from "react";
import { Grid, Typography, Pagination, Switch, Chip } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { MdDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { post_data } from "../../../api";
import { GoPencil } from "react-icons/go";
import Loader from "../../../website/Components/Loader";
import { formatDate } from "../../../globalConstants";

export default function NewslettersTable({
    tableHeader,
    data,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    count,
    setLoader,
    totalNews,
    totalPages,
    loader,
}) {

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalNews);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const displayAllHeader = () => {
        return (
            <div>
                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        : data?.length > 0 ? data?.map((item, i) => (
                            <div style={{ width: "100%" }} key={i}>
                                <Grid
                                    container
                                    spacing={0}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        padding: '1.2% 0',
                                        alignItems: "center",
                                        borderTop: "1px dashed gainsboro",
                                    }}
                                >
                                    <Grid item xs={1}>
                                        <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>{startEntry + i}</h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <h3
                                            style={{
                                                ...table_row_font,
                                                color: "#3F4254", margin: 0, padding: 0
                                            }}
                                        >
                                            {item?.email}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                            {formatDate(item?.created_at)}
                                        </h3>
                                    </Grid>

                                </Grid>
                            </div>
                        ))
                            :
                            <div>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#3F4254",
                                        marginTop: '5%'
                                    }}
                                >
                                    No Newsletter Found...
                                </Grid>
                            </div>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalNews} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}