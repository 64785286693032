import { Modal, Box, Typography, Button, TextField, Chip, Divider, RadioGroup, FormControlLabel, Radio, CircularProgress, FormControl, Backdrop, useMediaQuery } from "@mui/material"
import { formatPrice, globalConstant } from "../../../globalConstants";
import useRazorpay from "react-razorpay";
import { useState } from "react";
import { post_data } from "../../../api";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import zIndex from "@mui/material/styles/zIndex";

const OnsitePaymentModal = ({ socket,
    subTotal,
    serviceIDs,
    subServiceIDs,
    setSaved,
    setTotal,
    user_data,
    open,
    setOpen,
    total,
    memberShipSaved,
    saved,
    isMember,
    gst,
    totalDiscount,
    appliedCoupon,
    setAppliedCoupon,
    couponDisount,
    setCouponDiscount
}) => {


    const [Razorpay] = useRazorpay();
    const { primaryColor } = globalConstant();
    const [alertMessage, setAlertMessage] = useState('');
    const [couponDisabled, setCouponDisabled] = useState();
    const [pod, setPod] = useState(null);
    const [dropOpen, setDropOpen] = useState(false);
    const [coupon, setCoupon] = useState('');
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isDesktop ? 500 : '80%',
        bgcolor: 'background.paper',
        border: 'none',
        outline: 'none',
        boxShadow: 24,
        p: isDesktop ? 4 : 3,
        borderRadius: '10px',
        zIndex: 1300,

    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '20px',
            backgroundColor: '#fff',
            position: 'relative',
        },
        subServicesContainer: {
            padding: '20px',
            overflow: 'hidden',
        },
        subheading: {
            fontSize: '16px',
            marginBottom: '20px',
        },
        cartSection: {
            padding: '20px',
            backgroundColor: 'white',
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            boxSizing: "border-box",
        },
        summarySection: {
            flex: 1,
            padding: '20px',
            height: 400,
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            zIndex: 2,
            marginLeft: '0',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
        },
        cartItem: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
            alignItems: 'start'
        },
        itemDetails: {
            display: 'flex',
            flexDirection: 'column',
        },

        discountCode: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            marginTop: "2%"
        },
        priceRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1% 0',
        },
        placeOrderButton: {
            padding: '13px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '100%',
            marginTop: '20px',
            boxShadow: 'none',
        },

    };


    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    const handleClose = () => {
        setOpen(false);
        if (coupon)
            handleRemoveCoupon()
    }

    const handleApplyCoupon = async () => {

        setCouponDisabled(true);

        const data = await post_data("coupon/get-coupon", { couponCode: coupon })

        if (data.status) {
            setCouponDiscount(data.data.discount_value);
            setAppliedCoupon(coupon);
            Toast.fire({
                icon: "success",
                text: 'Coupon code applied successfully.'
            });
        } else {
            Toast.fire({
                icon: "warning",
                text: 'Coupon code invalid.'
            });
        }
        setCouponDisabled(false);
    };

    const handleRemoveCoupon = () => {
        setTotal(total + saved);
        setSaved(0);
        setAppliedCoupon('');
        Toast.fire({
            icon: 'warning',
            text: 'Coupon code removed.'
        });
    };

    const handleMakePayment = () => {
        if (pod === null) {
            Toast.fire({
                icon: "warning",
                text: "Select Payment mode"
            })
        } else {
            handleRzrpPayment();
        }
    }

    const options = {
        key: "rzp_test_GQ6XaPC6gMPNwH",
        amount: pod === "true" ? 100 * 100 : total * 100,
        currency: "INR",
        name: "Nivishka Services",
        description: "Services with a Lifestyle",
        image: '/images/nivishka-logo.png',

        handler: async function (response) {
            setDropOpen(true);

            let payload = {
                customer_id: user_data?._id,
                service_id: serviceIDs,
                sub_service_id: subServiceIDs,
                total_amount: total,
                transaction_id: response.razorpay_payment_id,
                is_pod: pod,
                remaining_amount: pod === "true" ? total - 100 : 0,
                paid_amount: pod === "true" ? 100 : total,
                booking_status: "scheduled",
                gst: user_data?.gst ? 18 : 5,
                discount: totalDiscount,
            };


            const result = await post_data('booking/on-site-booking', payload);

            if (result?.status) {
                localStorage.removeItem("selected_service");
                localStorage.removeItem("selected_service_amount");
                localStorage.removeItem("service_id");
                localStorage.removeItem("all_selected_service");
                const socketData = {
                    title: serviceIDs,
                    booking_id: result?.data?._id,
                    customer_id: user_data?._id,
                }

                socket.emit('booking-created', socketData);

                setDropOpen(false);

                navigate('/payment-status', { state: { status: 'success' } })
                window.scrollTo(0, 0)
            }
            else {
                setDropOpen(false);
                navigate('/payment-status', { state: { status: 'failure' } })
                window.scrollTo(0, 0)
            }

        },
        prefill: {
            name: `${user_data?.full_name}`,
            email: user_data?.email,
            contact: user_data?.phone,
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#5DB761",
        },
    };

    const handleRzrpPayment = async (params) => {
        handleClose();

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <Typography id="coupon-modal-title" variant="h6" component="h2" gutterBottom>
                    Apply Coupon
                </Typography>
                <Typography sx={{ display: 'flex', width: '100%', gap: '10px' }}>
                    <TextField
                        style={{ width: "70%" }}
                        fullWidth
                        label="Coupon Code"
                        variant="outlined"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                    />
                    <Typography style={{ width: "30%", textAlign: 'center' }}>
                        <Button
                            fullWidth
                            style={{ backgroundColor: primaryColor, color: '#fff', padding: '14px 15px' }}
                            onClick={handleApplyCoupon}
                        >
                            {couponDisabled ? "Continue..." : "Continue"}
                        </Button>
                    </Typography>
                </Typography>

                <Box sx={styles.discountCode}>
                    {appliedCoupon && (
                        <Chip
                            label={appliedCoupon}
                            onDelete={handleRemoveCoupon}
                            deleteIcon={<FaTimes />}
                            sx={{ borderRadius: '10px' }}
                        />
                    )}
                </Box>

                <Box sx={styles.summarySection} style={{ height: 'auto', marginTop: '5%' }}>
                    <Typography variant="h6" gutterBottom>Select Payment Mode</Typography>
                    <FormControl>
                        <RadioGroup
                            onChange={(e) => {
                                if (e.target.value == "true") {
                                    setPod(e.target.value)
                                }
                                else {
                                    setPod(e.target.value)
                                }
                            }}
                            value={pod}
                        >
                            <FormControlLabel value={false} control={<Radio sx={{
                                '&.Mui-checked': {
                                    color: "green",
                                },
                            }} />} label="Pay Now" />
                            <FormControlLabel value={true} control={<Radio sx={{
                                '&.Mui-checked': {
                                    color: "green",
                                },
                            }} />} label="Pay Booking Amount only (₹100)" />
                        </RadioGroup>
                        {pod == "true" && <p style={{ color: "green" }}>In this mode you only need to pay ₹100 now, and rest after the service</p>}
                    </FormControl>
                </Box>


                <Typography sx={{ mt: 2 }}>
                    <Box>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <Box sx={styles.priceRow}>
                                <Typography variant="body1">Total:</Typography>
                                <Typography variant="body1">{formatPrice(subTotal)}</Typography>
                            </Box>
                            <Box sx={styles.priceRow}>
                                <Typography variant="body1">Saved:</Typography>
                                <Typography variant="body1">-{formatPrice(saved)}</Typography>
                            </Box>
                            {isMember && <Box sx={styles.priceRow}>
                                <Typography variant="body1">Plus Member Discount:</Typography>
                                <Typography variant="body1">-{formatPrice(memberShipSaved)}</Typography>
                            </Box>}
                            <Box sx={styles.priceRow}>
                                <Typography variant="body1">GST:</Typography>
                                <Typography variant="body1">+{formatPrice(gst)}</Typography>
                            </Box>
                            {pod === "true" && <Box sx={styles.priceRow}>
                                <Typography variant="body1" style={{ fontWeight: 500 }}>Remaining:</Typography>
                                <Typography variant="body1" style={{ fontWeight: 500 }}>{formatPrice(total - 100)}</Typography>
                            </Box>}
                            <Box sx={styles.priceRow}>
                                <Typography variant="body1" style={{ fontWeight: 500 }}>Payable:</Typography>
                                <Typography variant="body1" style={{ fontWeight: 500 }}>{pod === "true" ? formatPrice(100) : formatPrice(total)}</Typography>
                            </Box>

                        </Typography>
                        <Typography>
                            <Button style={styles.placeOrderButton} onClick={handleMakePayment}>Make Payment</Button>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={dropOpen}
                            // onClick={handleClose}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </Typography>
                    </Box>
                </Typography>
            </Box>
        </Modal>
    )
};

export default OnsitePaymentModal;