import React, { useState } from "react"
import { TextField, Grid } from "@mui/material"

export default function SearchComponent({ value, setValue, handleSearch }) {

    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleSearch()
        }
    }

    return (
        <>
            <TextField
                variant="outlined"
                fillwidth
                label='Search'
                onKeyPress={(event) => handleEnter(event)}
                size="small"
                value={value}
                style={{ background: 'white', width: 300 }}
                onChange={(e) => setValue(e?.target?.value)}
            />
        </>
    )
}