import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import SalesTable from "../Components/SalesTable";
import { get_data } from "../../../api";
import SalesInvoicesModal from "../Components/SalesInvoicesModal";

export default function Sales({ type }) {

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalSales, setTotalSales] = useState(null);
    const [open, setOpen] = useState(false)

    const sales_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    const getAllSales = async (currentPage) => {
        const data = await get_data(`booking/get-all-sales?pageNumber=${currentPage}`);
        if (data?.status) {
            setLoader(false);
            setData(data?.data?.bookings);
            setTotalPages(data?.data?.totalPages);
            setTotalSales(data?.data?.totalSales);
        }
        else {
            setLoader(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllSales(currentPage);
        }
    }, [currentPage]);


    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Phone", grid: 2 },
        { title: "Name", grid: 2 },
        { title: "Billing Date", grid: 3 },
        { title: "Amount", grid: 3 },
        // { title: "Remaining Amount", grid: 2 },
        { title: "Receipt", grid: 1 },
    ];

    const count = data?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...sales_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Sales' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button variant="contained" style={{ borderRadius: 7, background: 'black', boxShadow: 'none' }} onClick={() => setOpen(true)}>
                        Export
                    </Button>
                </div>

                <div style={page_container}>
                    <SalesTable
                        tableHeader={tableHeader}
                        type="Users"
                        data={data}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalSales={totalSales}
                        loader={loader}
                        setLoader={setLoader}
                        totalPages={totalPages}
                    />
                </div>
                <SalesInvoicesModal
                    // currentInvoice={currentInvoice}
                    // currentIndex={currentIndex}
                    open={open}
                    setOpen={setOpen}
                />
            </Grid>
        </>
    )
}