import React from "react"

export default function DashboardHeading({
    title
}) {

    const heading_styles = {
        color: 'black',
        fontWeight: 600,
        fontSize: 22,
        margni: 0
    }

    return (
        <>
            <h2 style={heading_styles}>{title}</h2>
        </>
    )
}