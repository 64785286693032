import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import AllFeedbacks from "../Components/AllFeedbacks";
import Loader from "../../../website/Components/Loader";

export default function Feedbacks({ type }) {

    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalFeedbacks, setTotalFeedbacks] = useState(null);

    const permissions_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };


    const getAllFeedbacks = async () => {
        try {
            const data = await get_data(`feedback/get-all-feedback?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setData(data?.data?.feedbacks);
                setTotalPages(data?.data?.totalPages);
                setTotalFeedbacks(data?.data?.totalFeedbacks);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Newsletters", error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllFeedbacks(currentPage);
        }
    }, [currentPage])


    const itemsPerPage = 15;
    const count = data?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...permissions_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Feedbacks' />

                <div style={page_container}>
                    {
                        loader ?
                            <>
                                <Loader />
                            </>
                            :
                            <>
                                <AllFeedbacks
                                    data={data}
                                    setLoader={setLoader}
                                    getAllFeedbacks={getAllFeedbacks}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    count={count}
                                    totalPages={totalPages}
                                    itemsPerPage={itemsPerPage}
                                    totalFeedbacks={totalFeedbacks}
                                />
                            </>
                    }
                </div>
            </Grid>
        </>
    )
}