import React, { useEffect, useState } from "react";
import { Grid, Typography, Pagination, CircularProgress, Modal, Box, TextField, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip, FormHelperText } from "@mui/material";
import { MdDeleteForever } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { IoIosAddCircleOutline } from "react-icons/io";
import { endPointURL, get_data, post_data } from "../../../api";
import Swal from 'sweetalert2';
import { IoClose } from "react-icons/io5";
import styled from 'styled-components';
import Loader from "../../../website/Components/Loader";


const ServiceBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  background: #fff;
  box-sizing:border-box;
  overflow: hidden;
  `;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: 'none',
    outline: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};


export default function CouponTable({
    tableHeader,
    data,
    setLoader,
    itemsPerPage = 6,
    currentPage,
    setCurrentPage,
    count,
    totalPages,
    loader,
    getAllCoupons,
    totalCoupons,
    setAddCoupon,
    addCoupon
}) {
    const [open, setOpen] = useState(false);
    const [couponName, setCouponName] = useState('');
    const [discount, setDiscount] = useState('');

    const [errors, setErrors] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [updateID, setUpdateID] = useState('');
    const [disabled, setDisabled] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleOpen = () => setAddCoupon(true);

    const handleClose = () => {
        setIsUpdate(false);
        setDisabled(false);
        setAddCoupon(false);
        setErrors({});
        setCouponName('')
        setDiscount('')
    };

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };


    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalCoupons);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }


    function validate() {
        let error = false;

        if (couponName.length === 0) {
            error = true;
            handleError('couponName', 'Coupon name is required')
        }
        if (discount.length === 0) {
            error = true;
            handleError('discount', 'Discount is required')
        }
        return error;
    }

    const handleAddCoupon = async () => {
        const error = validate();
        if (!error) {
            try {
                setDisabled(true);
                const payload = {
                    coupon_code: couponName,
                    discount_value: discount
                }

                const data = await post_data("coupon/create-coupon", payload);

                if (data.status) {
                    handleClose();
                    getAllCoupons();
                    Toast.fire({
                        icon: 'success',
                        title: "Coupon added successfully",
                        color: "green"
                    })
                }
                else {
                    handleClose();
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "green"
                    })
                }
            } catch (error) {
                handleClose();
                // console.log("Error While adding coupon", error);
            }
        }
    }

    const handleSetUpdateCoupon = (item) => {
        setIsUpdate(true);
        setUpdateID(item?._id);
        setCouponName(item?.coupon_code);
        setDiscount(item?.discount_value);
        handleOpen();
    }


    const handleDeleteCoupon = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#5DB761",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`coupon/delete-coupon/${id}`, {})
                    if (data.status) {
                        Toast.fire({
                            title: "Coupon Deleted!",
                            color: "green",
                            icon: "success"
                        });
                        getAllCoupons();
                    }
                    else {
                        Toast.fire({
                            title: "Something Went Wrong!",
                            color: "red",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    console.log("error while delete Profession ", error);
                }
            }
        });
    }

    const handleUpdateCoupon = async () => {
        const error = validate();
        if (!error) {
            setDisabled(true);
            try {
                const body = {
                    coupon_code: couponName, discount_value: discount
                }
                const data = await post_data(`coupon/update-coupon/${updateID}`, body);

                if (data.status) {
                    handleClose();
                    getAllCoupons();
                    Toast.fire({
                        icon: 'success',
                        title: "Coupon Updated Successfully",
                        color: "green"
                    })
                }
                else {
                    handleClose();
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "red"
                    })
                }
            } catch (error) {
                handleClose();
                // console.log("Error while updating Coupon", error);
            }
        }
    }


    const displayAllHeader = () => {
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                        cursor: 'pointer',
                    }}
                >
                    <span style={{ display: 'flex', alignItems: 'center', gap: '3px', color: '#34eb52' }}>
                        <Modal
                            open={addCoupon}
                            onClose={handleClose}
                        >
                            <Box sx={style}>
                                <Typography>
                                    <ServiceBox>
                                        <Grid container spacing={2}>

                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Enter Coupon code"
                                                    value={couponName}
                                                    error={errors.couponName}
                                                    helperText={errors.couponName}
                                                    sx={{ mt: 2 }}
                                                    onChange={(e) => {
                                                        setCouponName(e.target.value);
                                                    }}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, couponName: "" })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Enter Discount"
                                                    value={discount}
                                                    error={errors.discount}
                                                    helperText={errors.discount}
                                                    sx={{ mt: 2 }}
                                                    onChange={(e) => {
                                                        setDiscount(e.target.value);
                                                    }}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, discount: "" })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    disabled={disabled}
                                                    sx={{
                                                        padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
                                                    }}
                                                    onClick={isUpdate ? handleUpdateCoupon : handleAddCoupon}
                                                >
                                                    {isUpdate ? "Update Coupon" : "Add Coupon"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ServiceBox>
                                </Typography>
                            </Box>
                        </Modal>
                    </span>
                </div>
                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        :
                        data.length > 0 ?
                            data?.map((item, i) => (
                                <div style={{ width: "100%" }} key={i}>
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{
                                            width: "100%",
                                            padding: "0.6% 0",
                                            display: "flex",
                                            alignItems: "center",
                                            borderTop: "1px dashed gainsboro",
                                        }}
                                    >
                                        <Grid item xs={1}>
                                            <h3 style={{ ...table_row_font, color: "#3F4254" }}>{startEntry + i}</h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={7}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254",
                                                    margin: 0
                                                }}
                                            >
                                                {item?.coupon_code}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h3 style={{ ...table_row_font, margin: 0 }}>
                                                {item?.discount_value}%
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: '10px'
                                            }}
                                        >
                                            <img onClick={() => handleSetUpdateCoupon(item)} src="/images/edit-img.svg" style={{ cursor: 'pointer' }} />
                                            <img onClick={() => handleDeleteCoupon(item._id)} src="/images/delete-img.svg" style={{ cursor: 'pointer' }} />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))
                            :
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}
                            >
                                No Coupons Found...
                            </Grid>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalCoupons} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}