import { useMediaQuery } from "@mui/material";

export default function Heading({ title, color }) {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const titleStyles = {
        fontSize: isDesktop ? 35 : 24,
        fontWeight: '600',
        color: color,
        marginBottom: '10px',
    }

    return (
        <>
            <h2 style={titleStyles}>{title}</h2>
        </>
    )
}