import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, MenuItem, Grid, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { formatPrice, globalConstant } from '../../../globalConstants';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../../api';
import useRazorpay from "react-razorpay";
import { useNavigate } from 'react-router-dom';

const CancelModal = ({ open, setOpen, selectedBookingAmount, selectedBookingId, cancelType, getAllBookings }) => {


    const [Razorpay] = useRazorpay();
    const navigate = useNavigate()

    const { user_data } = useSelector(state => state.user);
    const [reason, setReason] = useState('');
    const [bankDetails, setBankDetails] = useState({
        bankName: user_data?.bank_details?.bank_name,
        accountNumber: user_data?.bank_details?.account_number,
        ifscCode: user_data?.bank_details?.ifsc,
        accountHolderName: user_data?.bank_details?.account_holder_name,
        branchName: user_data?.bank_details?.branch_name
    });
    const [upi, setUpi] = useState(user_data?.upi)
    const [errors, setErrors] = useState({});

    const { primaryColor } = globalConstant();

    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
        boxShadow: 'none',
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        border: 'none',
        borderRadius: '10px',
        boxShadow: 24,
        p: isDesktop ? 4 : 3,
        overflowY: 'auto',
        maxHeight: isDesktop ? '90%' : '80%',
        width: isDesktop ? 600 : '80%',
        outline: 'none',
    };

    const reasons = [
        'Change of plans',
        'Found a better service',
        'Booked by mistake',
        'Other'
    ];


    const handleConfirmCancellation = async () => {
        if (cancelType === 'request' && selectedBookingAmount - 500 < 0) {
            handleRzrpPayment()
        }
        else {
            try {
                let payload = {
                    booking_id: selectedBookingId,
                    customer_id: user_data?._id,
                    org_amount: selectedBookingAmount,
                    refund_amount: cancelType === 'request' ? Math.max(0, selectedBookingAmount - 500) : selectedBookingAmount,
                    reason: reason,
                    refund_status: 'processed',
                    cancel_type: cancelType === 'request' && selectedBookingAmount - 500 > 0 ? 'requested' : 'normal',
                    bank_details: {
                        bank_name: bankDetails?.bankName,
                        account_number: bankDetails?.accountNumber,
                        ifsc: bankDetails?.ifscCode,
                        account_holder_name: bankDetails?.accountHolderName,
                        branch_name: bankDetails?.branchName
                    },
                    upi: upi
                };
                const data = await post_data(`refund/create-refund`, payload);
                if (data.status) {
                    setOpen(false);
                    getAllBookings(1)
                    Toast.fire({
                        icon: 'success',
                        title: "Refund request raised successfully",
                        color: "green"
                    });
                } else {
                    setOpen(false);
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "red"
                    });
                }
            } catch (error) {
                // console.log("Error While submitting review", error);
            }
        }
    };


    const handleRzrpPayment = async (params) => {
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    }

    const options = {
        key: "rzp_test_GQ6XaPC6gMPNwH",
        amount: 500 * 100,
        currency: "INR",
        name: "Nivishka Services",
        description: "Services with a Lifestyle",
        image: '/images/nivishka-logo.png',

        handler: async function (response) {

            let payload = {
                booking_id: selectedBookingId,
                customer_id: user_data?._id,
                org_amount: selectedBookingAmount,
                refund_amount: cancelType === 'request' ? Math.max(0, selectedBookingAmount - 500) : selectedBookingAmount,
                reason: reason,
                refund_status: 'processed',
                cancel_type: cancelType === 'request' && selectedBookingAmount - 500 > 0 ? 'requested' : 'normal',
                bank_details: {
                    bank_name: bankDetails?.bankName,
                    account_number: bankDetails?.accountNumber,
                    ifsc: bankDetails?.ifscCode,
                    account_holder_name: bankDetails?.accountHolderName,
                    branch_name: bankDetails?.branchName
                },
                upi: upi
            };
            const data = await post_data(`refund/create-refund`, payload);
            if (data.status) {
                setOpen(false);
                getAllBookings(1)
                Toast.fire({
                    icon: 'success',
                    title: "Refund request raised successfully",
                    color: "green"
                });
            } else {
                setOpen(false);
                Toast.fire({
                    icon: 'warning',
                    title: "Something Went Wrong",
                    color: "red"
                });
            }

        },
        prefill: {
            name: `${user_data?.full_name}`,
            email: user_data?.email,
            contact: user_data?.phone,
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#5DB761",
        },
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setBankDetails({
            ...bankDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setErrors({
            ...errors,
            reason: ''
        });
    };

    const refundAmount = cancelType === 'request' ? Math.max(0, selectedBookingAmount - 500) : selectedBookingAmount;
    const cancellationCharge = cancelType === 'request' && selectedBookingAmount < 500 ? 500 - selectedBookingAmount : 0;

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="cancellation-modal-title"
            aria-describedby="cancellation-modal-description"
        >
            <Box sx={style}>
                <IconButton onClick={() => setOpen(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                            Cancel Reason
                        </Typography>
                        <TextField
                            select
                            label="Reason to Cancel"
                            value={reason}
                            onChange={handleReasonChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            {reasons.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                            Total Paid Amount:
                        </Typography>
                        <Typography variant="h6" component="h3">
                            {formatPrice(selectedBookingAmount)}
                        </Typography>

                        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                            Total Refund:
                        </Typography>
                        <Typography variant="h6" component="h3">
                            {formatPrice(refundAmount)}
                        </Typography>
                        {cancelType === 'request' && (
                            <Typography sx={{ mt: 2, fontSize: 14, color: 'red' }}>
                                {selectedBookingAmount - 500 < 0 ? `You need to pay ${formatPrice(cancellationCharge)} as a cancellation charge` : '500 rs cancellation charges are applied'}
                            </Typography>
                        )}
                        <Typography sx={{ mt: 2, mb: 2, fontSize: 14 }}>
                            Refund will be credited to your bank account in 24 hours.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                            Bank Details
                        </Typography>
                        <TextField
                            label="Bank Name"
                            name="bankName"
                            value={bankDetails.bankName}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            label="Account Number"
                            name="accountNumber"
                            value={bankDetails.accountNumber}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                        <TextField
                            label="IFSC Code"
                            name="ifscCode"
                            value={bankDetails.ifscCode}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            label="Account Holder Name"
                            name="accountHolderName"
                            value={bankDetails.accountHolderName}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            label="Branch Name"
                            name="branchName"
                            value={bankDetails.branchName}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <Typography sx={{ mt: 2 }}>OR</Typography>
                        <TextField
                            label="UPI Handle"
                            name="upi"
                            value={upi}
                            onChange={(e) => setUpi(e?.target?.value)}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" style={button} fullWidth onClick={handleConfirmCancellation}>
                    Confirm Cancellation
                </Button>
            </Box>
        </Modal>
    );
};

export default CancelModal;
