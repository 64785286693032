import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar, Backdrop, useMediaQuery, Modal, TextField, Alert, Chip, IconButton, CircularProgress, Checkbox, FormGroup, FormControlLabel, Radio, RadioGroup, FormLabel, FormControl } from '@mui/material';
import { FaShieldAlt, FaTruck, FaPercentage, FaPlus, FaTimes } from 'react-icons/fa';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { convertMinutes, formatPrice, globalConstant } from '../../globalConstants';
import SubServicesSlider from '../Components/SubServicesSlider';
import Heading from '../Components/Heading';
import Newsletter from '../Components/Newsletter';
import { get_data, post_data } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import Empty from '../../web-app/user-dashboard/Components/Empty';
import useRazorpay from "react-razorpay";
import { useNavigate } from 'react-router-dom';
import { login } from '../../redux/slices/user-slice';
import BillingDetail from '../Components/BillingDetail';
import Slot from '../Components/Slot';
import { endPointURL } from '../../api';
import Swal from 'sweetalert2';
import CartServicesSkeleton from '../Components/Skeleton/CartServicesSkeleton';
import TrustBadges from '../../web-app/user-dashboard/Components/TrustBadges';
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const Checkout = ({ socket }) => {

    const [Razorpay] = useRazorpay();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [coupon, setCoupon] = useState('');
    const [appliedCoupon, setAppliedCoupon] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [total, setTotal] = useState(105.00);
    const [saved, setSaved] = useState(0.00);
    const [refresh, setRefresh] = useState(false);
    const [serviceDate, setServiceDate] = useState('');
    const [serviceTime, setServiceTime] = useState('');
    const [pod, setPod] = useState(null);
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const [allSubServices, setAllSubServices] = useState([]);
    const [loading, setLoading] = useState([]);
    const [loadingIds, setLoadingIds] = useState([]); // State to track loading state for each item
    const [isChecked, setIsChecked] = useState(false);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 960px)');
    const { primaryColor } = globalConstant();
    const [city, setCity] = useState('');
    const [open, setOpen] = useState(false);
    const [openModalPayment, setOpenModalPayment] = useState(false);
    const [services, setServices] = useState([]);
    const [subServiceIDs, setSubServiceIDs] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [errorMsg, setErrorMsg] = useState('');
    const [billingDetail, setBillingDetail] = useState({});
    const [billingError, setBillingError] = useState({});
    const [vendorAssign, setVendorAssign] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [serviceIDs, setServiceIDs] = useState([]);
    const [couponDisabled, setCouponDisabled] = useState();
    const [subServiceVendorNotFound, setSubServiceVendorNotFound] = useState([]);
    const [isMember, setIsMember] = useState(false);
    const [memberShipSaved, setMemberSaved] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [finalAmtMemberSaved, setFinalAmtMemberSaved] = useState(0);
    const [amountToPay, setAmountToPay] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [finalSaved, setFinalSaved] = useState(0);
    const [finalGst, setFinalGst] = useState(0);
    const [gst, setGst] = useState(0);

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isDesktop ? 430 : '80%',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        padding: isDesktop ? '2% 2.5% 2.5%' : '6% 5% 8%',
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpenModalPayment(false);
    };
    const handleModalOpen = () => {
        setOpenModalPayment(true);
    };

    const handleApplyCoupon = async () => {

        setCouponDisabled(true);

        const data = await post_data("coupon/get-coupon", { couponCode: coupon })

        if (data.status) {
            setCouponDiscount(data.data.discount_value);
            // const lastAmt = total - discount;
            // setSaved(discount);
            // const gstAmt = Math.ceil((lastAmt * data.data.discount_value) / 100);
            // setGst(gstAmt);
            // setTotal(lastAmt + gstAmt)
            // setTotalDiscount(totalDiscount + data.data.discount_value)
            setAppliedCoupon(coupon);
            setAlertMessage('Coupon code applied successfully.');
        } else {
            setAlertMessage('Coupon code invalid.');
        }
        setCouponDisabled(false);
        setOpenModal(false);
    };


    const handleRemoveCoupon = () => {
        setTotal(total + saved); // Restore the total
        setSaved(0);
        setTotalDiscount(totalDiscount - couponDiscount);
        setCouponDiscount(0);
        setAppliedCoupon('');
        setAlertMessage('Coupon code removed.');
    };

    const calculateTotal = () => {
        const subtotal = allSubServices?.reduce((acc, service) => acc + service?.selected_sub_service_id?.discounted_price, 0);
        setTotal(subtotal);
        setGrandTotal(subtotal)
        setSubTotal(subtotal);

        let lastAmount = subtotal;
        if (user_data?.is_membership) {
            const discount = Math.ceil((subtotal * 10) / 100);
            setIsMember(true);
            setTotal(subtotal - discount);
            lastAmount = subtotal - discount;
            setMemberSaved(discount);
            setTotalDiscount(10);
        }

        if (appliedCoupon) {
            const discount = Math.ceil((subtotal * couponDiscount) / 100);
            if (user_data?.is_membership) {
                setTotalDiscount(10 + couponDiscount);
            }
            else {
                setTotalDiscount(couponDiscount);
            }
            setTotal(lastAmount - discount);
            lastAmount = lastAmount - discount;
            setSaved(discount);
        }

        if (user_data?.gst) {
            const gstPrice = Math.ceil((lastAmount * 18) / 100);
            setGst(gstPrice);
            setTotal(lastAmount + gstPrice);
        } else {
            const gstPrice = Math.ceil((lastAmount * 5) / 100);
            setGst(gstPrice);
            setTotal(lastAmount + gstPrice);
        }

    };


    useEffect(() => {
        calculateTotal();
    }, [allSubServices, appliedCoupon]);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            padding: isMobile || isTablet ? '20px' : '5% 10%',
            backgroundColor: '#fff',
            position: 'relative',
        },
        subServicesContainer: {
            padding: isMobile || isTablet ? '20px' : '0 7% 3%',
            overflow: 'hidden',
        },
        subheading: {
            fontSize: '16px',
            marginBottom: isMobile || isTablet ? '20px' : '3%',
        },
        cartSection: {
            padding: '20px',
            backgroundColor: 'white',
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            boxSizing: "border-box",
        },
        summarySection: {
            flex: 1,
            padding: '20px',
            height: 500,
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            zIndex: 2,
            marginLeft: isMobile ? '0' : '20px',
            marginTop: isMobile ? '20px' : '0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
        },
        cartItem: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            marginBottom: '20px',
            alignItems: 'start',
        },
        itemDetails: {
            display: 'flex',
            flexDirection: 'column',
        },
        itemActions: {
            display: 'flex',
            alignItems: 'center',
        },
        itemImage: {
            width: '80px',
            height: '80px',
            marginRight: '20px',
            borderRadius: 5
        },
        discountPrice: {
            fontWeight: '600',
            fontSize: '18px',
        },
        price: {
            fontWeight: '400',
            textDecoration: 'line-through',
            marginLeft: '10px',
            color: '#9E9E9E',
            fontSize: '18px',
        },
        discountCode: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
        },
        placeOrderButton: {
            padding: '13px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '100%',
            marginTop: '20px',
            boxShadow: 'none',
        },
        priceRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1% 0',
        },
        trustPoints: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            background: '#f5f5f5',
            padding: '2% 5%',
        },
        trustPoint: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            flex: 1,
        },
        icon: {
            fontSize: '30px',
            marginBottom: '10px',
            color: primaryColor,
        },
        amenity: {
            fontSize: '12px',
            color: '#666',
            backgroundColor: '#fff',
            border: '1px solid green',
            borderRadius: '15px',
            padding: '2px 7px',
            marginRight: '5px',
            marginBottom: '5px',
            color: 'green'
        },
        amenities: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '5px'
        },
        date: {
            fontSize: '14px',
            color: '#333',
            marginBottom: '5px',
        },
        rating: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
        },
        removeButton: {
            color: 'red',
        },
    };


    const fetchSelectedServices = async () => {
        setLoading(true)
        const response = await get_data(`selected-service/get-selected-services-by-customer/${user_data?._id}`);
        if (response?.status) {
            setLoading(false)
            setAllSubServices(response?.data);
        }
    };

    useEffect(() => {
        fetchSelectedServices();
    }, [user_data, refresh]);

    const handleDelete = async (id) => {
        setLoadingIds((prev) => [...prev, id]); // Add id to loading state
        setTimeout(async () => {
            try {
                const response = await post_data(`selected-service/delete-selected-service-by-serviceId/${id}`, {
                    customer_id: user_data?._id
                });
                if (response?.status) {
                    fetchSelectedServices();
                    setRefresh(!refresh)
                }
            } catch (e) {
                console.log("error ", e);
            } finally {
                setLoadingIds((prev) => prev.filter((loadingId) => loadingId !== id)); // Remove id from loading state
            }
        }, 1000);
    };


    const handleBillingError = (label, value) => {
        setBillingError((prev) => ({ ...prev, [label]: value }))
    }


    const validate = () => {
        let error = false;
        if (!billingDetail?.fullName) {
            handleBillingError("fullName", "Name is Required");
            error = true;
        }
        if (!billingDetail?.phoneNumber) {
            handleBillingError("phoneNumber", "Phone Number is Required");
            error = true;
        }
        if (!billingDetail?.email) {
            handleBillingError("email", "Email is Required");
            error = true;
        }
        if (!billingDetail?.street) {
            handleBillingError("street", "Street is Required");
            error = true;
        }
        if (!billingDetail?.city) {
            handleBillingError("city", "City is Required");
            error = true;
        }
        if (!billingDetail?.state) {
            handleBillingError("state", "State is Required");
            error = true;
        }
        if (!billingDetail?.zipCode) {
            handleBillingError("zipCode", "ZipCode is Required");
            error = true;
        }

        if (error) {
            return error;
        }

        if (pod === null) {
            Toast.fire({
                icon: "warning",
                text: "Select Payment mode"
            })
            return true
        }

        if (!serviceTime) {
            Toast.fire({
                icon: "warning",
                text: "Select a Slot"
            })

            return true
        }

        return false
    }

    const handleBookNow = async () => {

        if (!validate()) {

            handleOpen();
            setVendorList([]);

            const services = [...new Set(allSubServices?.map((item) => {
                return (item?.selected_sub_service_id?.parent_service_id?._id);
            }))]

            setServices(services);

            const payload = {
                services: services,
                service_date: serviceDate,
                service_time: serviceTime,
                city: city || user_data?.address[0]?.city,
                zipcode: billingDetail?.zipCode || user_data?.address[0]?.zip_code,
                isChecked: isChecked,
            }

            const data = await post_data("vendor/search-vendor", payload);

            handleClose();

            if (data?.status) {

                if (data.data.length > 0) {

                    const vendorList = data?.data?.map(service => {
                        const serviceId = Object.keys(service)[0];
                        const vendors = service[serviceId].map(vendor => vendor._id);
                        return {
                            service_id: serviceId,
                            vendors: vendors
                        };
                    });

                    setVendorList(vendorList);

                    const vendorsAssign = data?.data?.map(service => {
                        const serviceId = Object.keys(service)[0];
                        return {
                            service_id: serviceId,
                            vendor_id: null,
                        }
                    });

                    setVendorAssign(vendorsAssign);

                    const serviceIds = data?.data?.map(service => {
                        const serviceId = Object.keys(service)[0];
                        return serviceId
                    });

                    setServiceIDs(serviceIds);

                    const subServiceIDs = [];

                    allSubServices.forEach((item) => {
                        if (serviceIds.includes(item?.selected_sub_service_id?.parent_service_id?._id)) {
                            subServiceIDs.push({ id: item?.selected_sub_service_id?._id, amount: item?.selected_sub_service_id?.discounted_price });
                        }
                    });

                    setSubServiceIDs(subServiceIDs)

                    const subservicevendornotfound = [];

                    let finalAmt = 0;

                    allSubServices.forEach((item) => {
                        if (!serviceIds.includes(item?.selected_sub_service_id?.parent_service_id?._id)) {
                            subservicevendornotfound.push(item?.selected_sub_service_id?.sub_service_name);
                        }
                        else {
                            finalAmt = finalAmt + item?.selected_sub_service_id?.discounted_price;
                        }
                    });

                    setFinalAmount(finalAmt);
                    let discount = 0;
                    if (user_data?.is_membership) {
                        discount = Math.ceil((finalAmt * 10) / 100);
                    }
                    const savedAmt = Math.ceil((finalAmt * couponDiscount) / 100);
                    const gstPercent = user_data?.gst ? 18 : 5;
                    const gstAmt = Math.ceil((finalAmt - discount - savedAmt) * gstPercent / 100);
                    setAmountToPay(finalAmt - discount - savedAmt + gstAmt);
                    setFinalSaved(savedAmt);
                    setFinalAmtMemberSaved(discount);
                    setFinalGst(gstAmt);

                    setSubServiceVendorNotFound(subservicevendornotfound);

                    handleModalOpen();
                }
                else {
                    handleModalOpen();
                    setErrorMsg("Vendors not found!")
                }
            }
            else {
                handleModalOpen();
                setErrorMsg(data?.message)
            }
        }
    }


    const handleRzrpPayment = async (params) => {
        handleModalClose();

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    }

    const options = {
        key: "rzp_test_GQ6XaPC6gMPNwH",
        amount: pod === "true" ? 100 * 100 : amountToPay * 100,
        currency: "INR",
        name: "Nivishka Services",
        description: "Services with a Lifestyle",
        image: '/images/nivishka-logo.png',

        handler: async function (response) {

            // create booking
            let payload = {
                customer_id: user_data?._id,
                service_id: serviceIDs,
                sub_service_id: subServiceIDs,
                booking_date: new Date(),
                service_date: serviceDate,
                service_time: serviceTime,
                total_amount: amountToPay,
                vendors: vendorAssign,
                transaction_id: response.razorpay_payment_id,
                restrict_booking: isChecked,
                is_pod: pod,
                remaining_amount: pod == "true" ? amountToPay - 100 : 0,
                paid_amount: pod == "true" ? 100 : amountToPay,
                gst: user_data?.gst ? 18 : 5,
                discount: totalDiscount,
                user_data: {
                    full_name: user_data?.full_name,
                    email: user_data?.email,
                    phone: user_data?.phone
                },
                booking_status: "scheduled",
                billing_info: {
                    full_name: billingDetail?.fullName,
                    phone: billingDetail?.phoneNumber,
                    email: billingDetail?.email,
                    street: billingDetail?.street,
                    city: billingDetail?.city,
                    state: billingDetail?.state,
                    zip_code: billingDetail?.zipCode
                },
                vendorList
            };

            handleOpen();

            const result = await post_data('booking/create-booking', payload);

            if (result?.status) {

                const res = await get_data(`selected-service/clear-cart-by-customer/${user_data?._id}`);

                const socketData = {
                    title: serviceIDs,
                    booking_id: result?.data?._id,
                    customer_id: user_data?._id,
                }

                socket.emit('booking-created', socketData);

                handleClose();

                navigate('/payment-status', { state: { status: 'success' } })
                window.scrollTo(0, 0)
            }
            else {
                handleClose();
                navigate('/payment-status', { state: { status: 'failure' } })
                window.scrollTo(0, 0)
            }

        },
        prefill: {
            name: `${user_data?.full_name}`,
            email: user_data?.email,
            contact: user_data?.phone,
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#5DB761",
        },
    };

    return (
        <>
            <Header />
            {/* <Box sx={styles.container}>
                <img src='/images/blur-div.svg' style={{ position: 'absolute', right: 0, top: 0 }} />
            </Box> */}

            <Box sx={styles.container}>
                <img src='/images/blur-div.svg' style={{ position: 'absolute', right: 0, top: 0 }} />

                <Box style={{ flex: 3 }}>
                    <Box style={styles.cartSection}>
                        <BillingDetail user_data={user_data} billingError={billingError} setBillingError={setBillingError} setBillingDetail={setBillingDetail} setCity={setCity} />
                    </Box>

                    <Box style={styles.cartSection} sx={{ mt: 2 }}>
                        <Slot serviceDate={serviceDate} serviceTime={serviceTime} setServiceDate={setServiceDate} setServiceTime={setServiceTime} />
                    </Box>

                    <Box style={styles.cartSection} sx={{ mt: 2 }}>
                        <Typography variant="h6" gutterBottom>Cart Services</Typography>
                        {
                            loading && isLoggedIn ?
                                <>
                                    <CartServicesSkeleton />
                                </>
                                :
                                <>
                                    {
                                        allSubServices?.length === 0 ?
                                            <>
                                                <Empty />
                                            </>
                                            :
                                            <>
                                                <List>
                                                    {allSubServices?.map((item, index) => (
                                                        <>
                                                            <ListItem key={index} sx={styles.cartItem}>
                                                                <ListItemAvatar>
                                                                    <img src={`${endPointURL}/uploads/service-picture/${item?.selected_sub_service_id?.sub_service_image}`} style={styles.itemImage} />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={item?.selected_sub_service_id?.sub_service_name + ' | ' + item?.selected_sub_service_id?.parent_service_id?.service_name}
                                                                    secondary={
                                                                        <>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                Duration: {convertMinutes(item?.selected_sub_service_id?.duration)}

                                                                                <IconButton
                                                                                    onClick={() => handleDelete(item?._id)}
                                                                                    style={styles.removeButton}
                                                                                    disabled={loadingIds.includes(item?._id)}
                                                                                >
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        sx={{
                                                                                            boxShadow: 'none',
                                                                                            padding: '2px 10px',
                                                                                            borderRadius: 10,
                                                                                            background: 'white',
                                                                                            border: '1px solid red',
                                                                                            color: 'red',
                                                                                            '&:hover': {
                                                                                                backgroundColor: 'white',
                                                                                                boxShadow: 'none',
                                                                                                cursor: 'pointer'
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {loadingIds?.includes(item?._id) ? 'Removing...' : 'Remove'}
                                                                                    </Button>

                                                                                </IconButton>

                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                />
                                                                <Box sx={styles.itemActions}>
                                                                    <Typography variant="h6" sx={styles.discountPrice}>{formatPrice(item?.selected_sub_service_id?.discounted_price)}</Typography>
                                                                    <Typography variant="h6" sx={styles.price}>{formatPrice(item?.selected_sub_service_id?.price)}</Typography>
                                                                </Box>
                                                            </ListItem>
                                                            {index !== allSubServices?.length - 1 && <Divider />}
                                                        </>
                                                    ))}
                                                </List>
                                            </>
                                    }
                                </>
                        }

                    </Box>
                </Box>

                <Box style={{ height: "100%", position: "sticky", top: "10%", flex: 2 }}>
                    <Box sx={styles.summarySection}>
                        <Typography variant="h6" gutterBottom>Discount Code</Typography>
                        <Box sx={styles.discountCode}>
                            <Typography variant="body1">Coupon</Typography>
                            {appliedCoupon ? (
                                <Chip
                                    label={appliedCoupon}
                                    onDelete={handleRemoveCoupon}
                                    deleteIcon={<FaTimes />}
                                    sx={{ borderRadius: '10px' }}
                                />
                            ) : (
                                <IconButton onClick={() => setOpenModal(true)} style={{ color: 'black', width: 35 }}>
                                    <FaPlus style={{ cursor: 'pointer' }} />
                                </IconButton>
                            )}
                        </Box>
                        <Divider />
                        <Typography variant="h6" gutterBottom>Pricing Details</Typography>
                        <Box sx={styles.priceRow}>
                            <Typography variant="body1">Total:</Typography>
                            <Typography variant="body1">{formatPrice(subTotal)}</Typography>
                        </Box>
                        <Box sx={styles.priceRow}>
                            <Typography variant="body1">Saved:</Typography>
                            <Typography variant="body1">-{formatPrice(saved)}</Typography>
                        </Box>
                        {isMember && <Box sx={styles.priceRow}>
                            <Typography variant="body1">Plus Member Discount:</Typography>
                            <Typography variant="body1">-{formatPrice(memberShipSaved)}</Typography>
                        </Box>}
                        <Box sx={styles.priceRow}>
                            <Typography variant="body1">GST {user_data?.gst ? '(18%)' : '(5%)'}:</Typography>
                            <Typography variant="body1">+{formatPrice(gst)}</Typography>
                        </Box>
                        {pod === "true" && <Box sx={styles.priceRow}>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>Remaining:</Typography>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>{formatPrice(total - 100)}</Typography>
                        </Box>}
                        <Box sx={styles.priceRow}>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>Payable:</Typography>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>{pod === "true" ? formatPrice(100) : formatPrice(total)}</Typography>
                        </Box>
                        {alertMessage && (
                            <Alert severity={alertMessage.includes('successfully') ? 'success' : 'error'}>{alertMessage}</Alert>
                        )}
                        <Button style={styles.placeOrderButton} onClick={handleBookNow}>Book Now</Button>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                        // onClick={handleClose}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Modal
                            open={openModalPayment}
                            onClose={handleModalClose}
                        >
                            <Box sx={style}>
                                <center>
                                    {/* <img src={'/images/no-data.svg'} style={{ width: 270 }} /> */}
                                    {
                                        vendorList?.length > 0 ?
                                            <IoCheckmarkCircleOutline style={{ width: 120, height: 120, color: 'green' }} />
                                            :
                                            <img src={'/images/no-data.svg'} style={{ width: 270 }} />
                                    }
                                </center>
                                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontWeight: 500, textAlign: 'center', marginBottom: '5%', fontSize: 21 }}>
                                    {vendorList?.length > 0 ? "Vendors found successfully for your service" : errorMsg}
                                    {
                                        vendorList?.length === 0 && (
                                            <p style={{ fontSize: 15, opacity: '70%', fontWeight: 400 }}>Try selecting a different slot</p>
                                        )
                                    }
                                </Typography>

                                {
                                    subServiceVendorNotFound.length === 0
                                        ?
                                        <></>
                                        :
                                        <Typography id="modal-modal-title" style={{ textAlign: 'center', marginBottom: '5%', fontSize: 14 }}>
                                            {subServiceVendorNotFound.map((item) => item).join(", ")} Vendor are currently not available
                                        </Typography>
                                }
                                {vendorList.length > 0 &&
                                    <Box>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            <Box sx={styles.priceRow}>
                                                <Typography variant="body1">Total:</Typography>
                                                <Typography variant="body1">{formatPrice(finalAmount)}</Typography>
                                            </Box>
                                            <Box sx={styles.priceRow}>
                                                <Typography variant="body1">Saved:</Typography>
                                                <Typography variant="body1">-{formatPrice(finalSaved)}</Typography>
                                            </Box>
                                            {isMember && <Box sx={styles.priceRow}>
                                                <Typography variant="body1">Plus Member Discount:</Typography>
                                                <Typography variant="body1">-{formatPrice(finalAmtMemberSaved)}</Typography>
                                            </Box>}
                                            <Box sx={styles.priceRow}>
                                                <Typography variant="body1">Gst {user_data?.gst ? '(18%)' : '(5%)'}:</Typography>
                                                <Typography variant="body1">+{formatPrice(finalGst)}</Typography>
                                            </Box>
                                            {pod === "true" && <Box sx={styles.priceRow}>
                                                <Typography variant="body1" style={{ fontWeight: 500 }}>Remaining:</Typography>
                                                <Typography variant="body1" style={{ fontWeight: 500 }}>{formatPrice(amountToPay - 100)}</Typography>
                                            </Box>}
                                            <Box sx={styles.priceRow}>
                                                <Typography variant="body1" style={{ fontWeight: 500 }}>Payable:</Typography>
                                                <Typography variant="body1" style={{ fontWeight: 500 }}>{pod === "true" ? formatPrice(100) : formatPrice(amountToPay)}</Typography>
                                            </Box>
                                        </Typography>
                                        <Typography>
                                            <Button style={styles.placeOrderButton} onClick={handleRzrpPayment}>Make Payment</Button>
                                        </Typography>
                                    </Box>}
                            </Box>
                        </Modal>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox sx={{
                                '&.Mui-checked': {
                                    color: "green",
                                },
                            }}
                                onChange={(e) => {
                                    setIsChecked(e.target.checked)
                                }} />} label="(Book only if all the vendors of all services are available)" />
                        </FormGroup>
                    </Box>

                    <Box sx={styles.summarySection} style={{ height: 'auto', marginTop: '5%' }}>
                        <Typography variant="h6" gutterBottom>Select Payment Mode</Typography>
                        <FormControl>
                            <RadioGroup
                                onChange={(e) => {
                                    if (e.target.value == "true") {
                                        setPod(e.target.value)
                                    }
                                    else {
                                        setPod(e.target.value)
                                    }
                                }}
                                value={pod}
                            >
                                <FormControlLabel value={false} control={
                                    <Radio sx={{
                                        '&.Mui-checked': {
                                            color: "green",
                                        },
                                    }} />} label="Pay Now" />
                                <FormControlLabel value={true} control={
                                    <Radio sx={{
                                        '&.Mui-checked': {
                                            color: "green",
                                        },
                                    }} />} label="Pay Booking Amount only (₹100)" />
                            </RadioGroup>
                            {pod === "true" && <p style={{ color: "green" }}>In this mode you only need to pay ₹100 now, and rest after the service</p>}
                        </FormControl>
                    </Box>

                    <Box sx={styles.summarySection} style={{ height: 'auto', marginTop: '5%' }}>
                        <TrustBadges />
                    </Box>
                </Box>


            </Box>

            <div style={styles.subServicesContainer}>
                <Heading title={'Frequenlty added services'} color={'black'} />
                <p style={styles.subheading}>Best quality services you can get at best price.</p>
                <SubServicesSlider refresh={refresh} setRefresh={setRefresh} screen='checkout' />
            </div>

            {/* <Box sx={styles.trustPoints}>
                <Box sx={styles.trustPoint}>
                    <FaShieldAlt style={styles.icon} />
                    <Typography variant="body1">Secure Payments</Typography>
                </Box>
                <Box sx={styles.trustPoint}>
                    <FaTruck style={styles.icon} />
                    <Typography variant="body1">Free Service</Typography>
                </Box>
                <Box sx={styles.trustPoint}>
                    <FaPercentage style={styles.icon} />
                    <Typography variant="body1">Satisfying Service</Typography>
                </Box>
                <Box sx={styles.trustPoint}>
                    <FaPercentage style={styles.icon} />
                    <Typography variant="body1">Best Prices</Typography>
                </Box>
            </Box> */}

            <Newsletter />
            <Footer />

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="coupon-modal-title"
                aria-describedby="coupon-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? 350 : '80%',
                    bgcolor: 'background.paper',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: isDesktop ? 4 : 3,
                }}>
                    <Typography id="coupon-modal-title" variant="h6" component="h2" gutterBottom>
                        Apply Coupon
                    </Typography>
                    <TextField
                        style={{ margin: '4% 0' }}
                        fullWidth
                        label="Coupon Code"
                        variant="outlined"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                    />  <Button
                        fullWidth
                        style={{ marginTop: '4%', backgroundColor: primaryColor, color: '#fff', padding: '13px 15px' }}
                        onClick={handleApplyCoupon}
                    >
                        {couponDisabled ? "Continue..." : "Continue"}
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default Checkout;