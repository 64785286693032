import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Select, MenuItem, InputLabel, FormControl, Button, FormHelperText, useMediaQuery } from '@mui/material';
import { globalConstant } from '../../globalConstants';
import { get_data, post_data } from "../../api"
import { login } from '../../redux/slices/user-slice';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import VerifyEmailModal from '../../web-app/user-dashboard/Components/VerifyEmailModal';
import ChangeEmailModal from '../../web-app/user-dashboard/Components/ChangeEmailModal';
import ChangeNumberModal from '../../web-app/user-dashboard/Components/ChangeNumberModal';

const PersonalInformation = ({ user_data }) => {

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  const { primaryColor } = globalConstant();
  const dispatch = useDispatch();
  const [cities, setCitites] = useState([]);
  const [states, setStates] = useState([]);
  const [open, setOpen] = useState(false);
  const [openChangeEmail, setOpenChangeEmail] = useState(false);
  const [openChangeNumber, setOpenChangeNumber] = useState(false);

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const [formValues, setFormValues] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    // latitude: '',
    // longitude: '',
    upi: '',
    bank_details: {
      bank_name: '',
      account_number: '',
      ifsc: '',
      account_holder_name: '',
      branch_name: '',
      gst: ''
    }
  });

  const getCityAndStates = async () => {
    const data = await get_data("service-area/get-all-service-area");
    const city = [...new Set(data?.data?.map((item) => item.city))];
    setCitites(city);
    const state = [...new Set(data?.data?.map((item) => item.state))];
    setStates(state);
  };

  useEffect(() => {
    setFormValues({
      fullName: user_data?.full_name || '',
      email: user_data?.email || '',
      street: user_data?.address?.[0]?.street || '',
      city: user_data?.address?.[0]?.city || '',
      state: user_data?.address?.[0]?.state || '',
      zipCode: user_data?.address?.[0]?.zip_code || '',
      // latitude: user_data?.address?.[0]?.latitude || '',
      // longitude: user_data?.address?.[0]?.longitude || '',
      phoneNumber: user_data?.phone || '',
      upi: user_data?.upi || '',
      bank_details: {
        bank_name: user_data?.bank_details?.bank_name || '',
        account_number: user_data?.bank_details?.account_number || '',
        ifsc: user_data?.bank_details?.ifsc || '',
        account_holder_name: user_data?.bank_details?.account_holder_name || '',
        branch_name: user_data?.bank_details?.branch_name || '',
      },
      gst: user_data?.gst || '',
    });
    getCityAndStates();
  }, [user_data]);


  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const nameParts = name.split('.');

    if (nameParts.length > 1) {
      setFormValues({
        ...formValues,
        [nameParts[0]]: {
          ...formValues[nameParts[0]],
          [nameParts[1]]: value
        }
      });
    } else {
      if (name === 'phoneNumber') {
        const phoneValue = value.replace(/\D/g, ''); 
        setFormValues({
          ...formValues,
          [name]: phoneValue
        });
      } else {
        setFormValues({
          ...formValues,
          [name]: value
        });
      }
    }

    setFormErrors({
      ...formErrors,
      [name]: ''
    });
  };


  const handleSubmit = () => {
    // const errors = {};
    // if (!formValues.fullName) errors.fullName = 'Full Name is required';
    // if (!formValues.phoneNumber) {
    //   errors.phoneNumber = 'Phone Number is required';
    // } else if (formValues.phoneNumber.length !== 10) {
    //   errors.phoneNumber = 'Phone Number must be 10 digits';
    // }
    // if (!formValues.street) errors.street = 'Street is required';
    // if (!formValues.city) errors.city = 'City is required';
    // if (!formValues.state) errors.state = 'State is required';
    // if (!formValues.zipCode) errors.zipCode = 'Zip Code is required';
    // setFormErrors(errors);

    // if (Object.keys(errors).length === 0) {
    setLoading(true);
    setTimeout(() => {
      handleSave();
    }, 1000);
    // }
  };


  const handleSave = async () => {
    const payload = {
      phoneNumber: formValues?.phoneNumber,
      email: formValues?.email,
      full_name: formValues?.fullName,
      address: [
        {
          street: formValues?.street,
          city: formValues?.city,
          state: formValues?.state,
          zip_code: formValues?.zipCode,
          // latitude: formValues?.latitude,
          // longitude: formValues?.longitude
        }
      ],
      upi: formValues?.upi,
      bank_details: {
        bank_name: formValues?.bank_details?.bank_name,
        account_number: formValues?.bank_details?.account_number,
        ifsc: formValues?.bank_details?.ifsc,
        account_holder_name: formValues?.bank_details?.account_holder_name,
        branch_name: formValues?.bank_details?.branch_name
      },
      gst: formValues?.gst
    };

    try {
      const response = await post_data(`customer/update-customer/${user_data?._id}`, payload);
      if (response?.status) {
        setLoading(false);
        dispatch(login(response?.data));
        Toast.fire({
          icon: 'success',
          title: "Profile Updated Successfully",
          color: "green"
        });
      } else {
        setLoading(false);
        Toast.fire({
          icon: 'warning',
          title: "Something Went Wrong",
          color: "red"
        });
      }
    } catch (e) {
      setLoading(false);
      Toast.fire({
        icon: 'error',
        title: "An error occurred",
        color: "red"
      });
    }
  };

  const button = {
    padding: '13px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: primaryColor,
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    marginTop: '3%',
    boxShadow: 'none'
  };

  const disbaledBtn = {
    opacity: '70%'
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ padding: '30px', borderRadius: '10px', background: 'white', border: '1px solid #E0E0E0' }}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '3%' }}>
        Personal Information
      </Typography>

      {/* First row */}
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', flexDirection: isDesktop ? 'row' : 'column' }}>
        <div style={{ width: isDesktop ? '50%' : '100%' }}>
          <TextField
            fullWidth
            label="Full Name"
            name="fullName"
            variant="outlined"
            value={formValues.fullName}
            onChange={handleChange}
            error={!!formErrors.fullName}
            helperText={formErrors.fullName}
          />
        </div>
        <div style={{ width: isDesktop ? '50%' : '100%' }}>
          <TextField
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            type="tel"
            variant="outlined"
            value={formValues.phoneNumber}
            onChange={handleChange}
            inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
            error={!!formErrors.phoneNumber}
            helperText={formErrors.phoneNumber}
          />
          <div onClick={() => setOpenChangeNumber(true)} style={{ color: primaryColor, textDecoration: 'none', cursor: 'pointer' }}>Change number</div>
        </div>
      </Box>

      {/* Second row */}
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', flexDirection: isDesktop ? 'row' : 'column' }}>
        <div style={{ width: isDesktop ? '50%' : '100%' }}>
          <TextField
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            variant="outlined"
            value={formValues.email}
            onChange={handleChange}
          />
          <div style={{ display: 'flex', gap: 20 }}>
            {user_data?.email && user_data?.is_email_verified === false && (
              <div onClick={handleClick} style={{ color: primaryColor, textDecoration: 'none', cursor: 'pointer' }}>Verify email</div>
            )}
            {
              user_data?.email !== '' && (
                <div onClick={() => setOpenChangeEmail(true)} style={{ color: primaryColor, textDecoration: 'none', cursor: 'pointer' }}>Change email</div>
              )
            }
          </div>
        </div>

        <div style={{ width: isDesktop ? '50%' : '100%' }}>
          <TextField
            fullWidth
            label="Gst"
            name="gst"
            variant="outlined"
            value={formValues.gst}
            onChange={handleChange}
            error={!!formErrors.gst}
            helperText={formErrors.gst}
          />
        </div>
      </Box>

      {/* Third row */}
      <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '3%' }}>
        Address
      </Typography>
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <TextField
          fullWidth
          label="Street"
          name="street"
          variant="outlined"
          value={formValues.street}
          onChange={handleChange}
          error={!!formErrors.street}
          helperText={formErrors.street}
        />
      </Box>

      {/* Fourth row */}
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', flexDirection: isDesktop ? 'row' : 'column' }}>
        <FormControl fullWidth variant="outlined" error={!!formErrors.city}>
          <InputLabel>City</InputLabel>
          <Select
            name="city"
            value={formValues.city}
            onChange={handleChange}
            label="City"
          >
            {cities?.map((city) => (
              <MenuItem key={city} value={city}>{city}</MenuItem>
            ))}
          </Select>
          {formErrors.city && <FormHelperText>{formErrors.city}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth variant="outlined" error={!!formErrors.state}>
          <InputLabel>State</InputLabel>
          <Select
            name="state"
            value={formValues.state}
            onChange={handleChange}
            label="State"
          >
            {states?.map((state) => (
              <MenuItem key={state} value={state}>{state}</MenuItem>
            ))}
          </Select>
          {formErrors.state && <FormHelperText>{formErrors.state}</FormHelperText>}
        </FormControl>
        <TextField
          fullWidth
          label="Zip Code"
          name="zipCode"
          type="number"
          variant="outlined"
          value={formValues.zipCode}
          onChange={handleChange}
          error={!!formErrors.zipCode}
          helperText={formErrors.zipCode}
        />
      </Box>

      <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '3%' }}>
        Bank Details
      </Typography>

      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', flexDirection: isDesktop ? 'row' : 'column' }}>
        <TextField
          label="Bank Name"
          name="bank_details.bank_name"
          fullWidth
          variant="outlined"
          value={formValues?.bank_details?.bank_name}
          onChange={handleChange}
        />
        <TextField
          label="Branch Name"
          fullWidth
          name="bank_details.branch_name"
          variant="outlined"
          value={formValues?.bank_details?.branch_name}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', flexDirection: isDesktop ? 'row' : 'column' }}>
        <TextField
          label="Account Holder Name"
          fullWidth
          name="bank_details.account_holder_name"
          variant="outlined"
          value={formValues?.bank_details?.account_holder_name}
          onChange={handleChange}
        />
        <TextField
          label="Account Number"
          name="bank_details.account_number"
          fullWidth
          variant="outlined"
          value={formValues?.bank_details?.account_number}
          onChange={handleChange}
        />
        <TextField
          label="IFSC Code"
          fullWidth
          name="bank_details.ifsc"
          variant="outlined"
          value={formValues?.bank_details?.ifsc}
          onChange={handleChange}
        />
      </Box>
      <p>OR</p>
      <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', flexDirection: isDesktop ? 'row' : 'column' }}>
        <TextField
          label="UPI Handle"
          fullWidth
          name="upi"
          variant="outlined"
          value={formValues?.upi}
          onChange={handleChange}
        />
      </Box>

      <Button variant="contained" onClick={handleSubmit} style={loading ? { ...button, ...disbaledBtn } : button}>
        {loading ? 'Update...' : 'Update'}
      </Button>
      <VerifyEmailModal open={open} setOpen={setOpen} />
      <ChangeEmailModal open={openChangeEmail} setOpen={setOpenChangeEmail} />
      <ChangeNumberModal open={openChangeNumber} setOpen={setOpenChangeNumber} />
    </Box>
  );
};

export default PersonalInformation;
