import { Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import ReviewsList from "../Components/ReviewsList";

export default function Reviews() {

    const reviews_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    return (
        <>
            <Grid container spacing={0} style={{ ...reviews_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Reviews' />

                <div style={page_container}>
                    <ReviewsList />
                </div>
            </Grid>
        </>
    )
}