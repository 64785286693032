import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Chip } from '@mui/material';

const CustomerCartModal = ({ open, setOpen, currentService }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        }
    };


    const heading = {
        fontWeight: 600,
        fontSize: 22,
        marginBottom: '10px',
        textAlign: 'left',
        p: 3,
        borderBottom: '1px solid gainsboro'
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                border: 'none',
                "& .MuiBackdrop-root": {
                }
            }}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={heading}>
                    Services in Customer's Cart
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, padding: '2% 6% 6% 6%' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {
                            currentService?.map((item) => (
                                <Chip label={item?.sub_service_name} />
                            ))
                        }
                    </Box>
                </Typography>
            </Box>
        </Modal>
    );
};

export default CustomerCartModal;
