import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, TextField, Typography, Button, IconButton, InputAdornment } from '@mui/material';
import { globalConstant } from '../../../globalConstants';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { login } from '../../../redux/slices/user-slice';
import { useSelector } from 'react-redux';
import { post_data } from '../../../api';

const AdminChangePassword = () => {

    const dispatch = useDispatch();
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const { primaryColor } = globalConstant();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTwo, setShowPasswordTwo] = useState(false);
    const [formValues, setFormValues] = useState({
        currentPassword: "",
        newPassword: "",
    })
    const [formErrors, setFormErrors] = useState({});

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    const validate = () => {
        const errors = {};
        let hasError = false;
        if (!formValues.currentPassword) {
            hasError = true;
            errors.currentPassword = 'Current Password is required'
        };
        if (!formValues.newPassword) {
            hasError = true;
            errors.newPassword = 'New Password is required';
        }
        setFormErrors(errors);

        return hasError;
    }

    const handleSubmit = async () => {
        if (!validate()) {
            const res = await post_data(`super-admin/change-password-super-admin/${user_data._id}`, formValues);
            if (res?.status) {
                Toast.fire({
                    title: "Password Changed Succesfully",
                    color: "green",
                    icon: "success"
                });
                dispatch(login(res?.data));
            }
            else {
                console.log("res.stat", res)
                if (!res?.response?.data?.status && res?.response?.data?.message === "Wrong Password") {
                    Toast.fire({
                        title: "Current Password is Wrong!",
                        color: "red",
                        icon: "warning"
                    });
                }
                else {
                    Toast.fire({
                        title: "Something Went Wrong!",
                        color: "red",
                        icon: "warning"
                    });
                }
            }
        }
    };
    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '3%',
        boxShadow: 'none'
    }

    // const handleClickShowPassword = () => {
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    return (
        <>
            <Box sx={{ padding: '30px', borderRadius: '10px', background: 'white', border: '1px solid #E0E0E0', height: 280 }}>
                <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '3%' }}>
                    Change Password
                </Typography>

                {/* First row */}
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                    <TextField
                        fullWidth
                        label="Current Password"
                        name="currentPassword"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={formValues.currentPassword}
                        onChange={handleChange}
                        error={!!formErrors.currentPassword}
                        helperText={formErrors.currentPassword}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                {/* Second row */}
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                    <TextField
                        fullWidth
                        label="New Password"
                        name="newPassword"
                        type={showPasswordTwo ? 'text' : 'password'}
                        variant="outlined"
                        value={formValues.newPassword}
                        onChange={handleChange}
                        error={!!formErrors.newPassword}
                        helperText={formErrors.newPassword}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPasswordTwo(!showPasswordTwo)}
                                        edge="end"
                                    >
                                        {showPasswordTwo ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Button variant="contained" onClick={handleSubmit} style={{ ...button, marginBottom: '3%' }}>
                    Change Password
                </Button>
                <Link style={{ color: primaryColor }} to='/admin/forget-password'>Forget Password</Link>
            </Box>
        </>
    );
};

export default AdminChangePassword;
