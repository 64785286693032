import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid } from "@mui/material";
import { globalConstant } from '../../../globalConstants';
import FeedbackModal from '../../../website/Components/FeedbackModal';
import { get_data } from '../../../api';
import { useSelector } from 'react-redux';

const RateUs = () => {

    const { primaryColor } = globalConstant()
    const [open, setOpen] = useState(false)
    const { user_data } = useSelector(state => state.user);

    const handleOpen = () => {
        setOpen(true)
    }

    const errorPageStyles = {
        section: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
        container: {
            maxWidth: '600px',
            padding: '0 2%',
        },
        gridItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        heading: {
            fontSize: '6rem',
            margin: '0',
        },
        message: {
            fontSize: '1.5rem',
        },
        button: {
            background: primaryColor,
            color: 'white',
            boxShadow: 'none',
            borderRadius: 10,
            border: 'none',
            fontWeight: 500,
            fontSize: 15,
            textTransform: 'capitalize',
            padding: '2% 5%'
        },
    }

    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    const getCustomerFeedBack = async () => {
        try {
            const data = await get_data(`feedback/get-feedback-by-customer/${user_data?._id}`);
            if (data.status) {
                setLoader(false);
                setData(data?.data);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Newsletters", error);
        }
    }


    useEffect(() => {
        getCustomerFeedBack()
    }, [])

    return (
        <div style={errorPageStyles?.section}>
            <FeedbackModal open={open} setOpen={setOpen} getCustomerFeedBack={getCustomerFeedBack} />
            <Grid container spacing={0} style={errorPageStyles?.container}>
                {
                    loader ?
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10%' }}>
                                <CircularProgress />
                            </div>
                        </>
                        :
                        <>
                            <Grid item xs={12} style={errorPageStyles?.gridItem}>
                                {
                                    data?.length > 0 ?
                                        <>
                                            <img src={`/images/success.svg`} style={{ width: 150, height: 150, margin: '5% 0' }} />
                                            <h3 className="global-h3" style={{ margin: '2% 0', fontSize: 25 }}>Thanks For Submitting Your Feedback!</h3>
                                        </>
                                        :
                                        <>
                                            <img src='/images/feedback.svg' style={{ width: 400, height: 400 }} />
                                            <Button
                                                onClick={handleOpen}
                                                variant='contained'
                                                style={errorPageStyles?.button}
                                            >
                                                Leave a Feedback
                                            </Button>
                                        </>
                                }
                            </Grid>
                        </>
                }
            </Grid>
        </div>
    );
}

export default RateUs;
