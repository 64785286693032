import { Avatar, Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import { FaCamera } from "react-icons/fa";
import { endPointURL, post_data } from '../../api';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slices/user-slice';

const ProfileCard = ({ user_data }) => {

    const dispatch = useDispatch()
    const [profile, setProfile] = useState('');
    const fileInputRef = useRef(null);
    const [profileLocal, setProfileLocal] = useState('');

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleUploadPicture = async () => {
        const formData = new FormData();
        formData.append("profile_picture", profile);

        const data = await post_data(`customer/create-profile-picture/${user_data?._id}`, formData);
        if (data?.status) {
            dispatch(login(data?.data));
            Toast.fire({
                icon: "success",
                text: "Profile Uploaded",
                color: "green"
            })
        }
        else {
            Toast.fire({
                icon: "error",
                text: "Something Went Wrong",
                color: "red"
            })
        }
        setProfile('');
        setProfileLocal('');
    }

    
    const handleUpdatePicture = async () => {
        const formData = new FormData();
        formData.append("profile_picture", profile);

        const data = await post_data(`customer/update-profile-picture/${user_data?._id}`, formData);
        
        if (data?.status) {
            dispatch(login(data?.data));
            Toast.fire({
                icon: "success",
                text: "Profile Updated",
                color: "green"
            })
        }
        else {
            Toast.fire({
                icon: "error",
                text: "Something Went Wrong",
                color: "red"
            })
        }
        setProfile('');
        setProfileLocal('');
    }


    return (
        <div className="profile-card">
            <div className="profile-header">
                {user_data?.profile_picture ?
                    <div>
                        <div className="icon-container" onClick={handleIconClick}>
                            <Avatar className="user-icon" src={profileLocal ? profileLocal : `${endPointURL}/uploads/profile-picture/${user_data?.profile_picture}`} style={{ height: '100px', width: '100px' }} />
                            <FaCamera className="camera-icon" />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    setProfile(e.target.files[0]);
                                    setProfileLocal(URL.createObjectURL(e.target.files[0]))
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div className="icon-container" onClick={handleIconClick}>
                        <Avatar className="user-icon" src={profileLocal} sx={{ height: '100px', width: '100px' }} />
                        <FaCamera className="camera-icon" />
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                setProfile(e.target.files[0]);
                                setProfileLocal(URL.createObjectURL(e.target.files[0]))
                            }}
                        />
                    </div>}
                {profileLocal && <div>
                    <Button style={{ color: "#5DB761" }} onClick={user_data?.profile_picture ? handleUpdatePicture : handleUploadPicture}>{user_data?.profile_picture ? "Update" : "Upload"}</Button>
                    <Button style={{ color: "#5DB761" }} onClick={() => {
                        setProfile('');
                        setProfileLocal('');
                    }}>Cancel</Button>
                </div>}
            </div>
            <div className="profile-info">
                <h2 style={{ fontWeight: 600, fontSize: 25 }}>{user_data?.full_name || user_data?.user_name}</h2>
                <p>{user_data?.full_name === '' ? '' : user_data?.user_name}</p>
            </div>
        </div>
    );
};

export default ProfileCard;
