import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import BackButton from "../Components/BackButton";
import { get_data, post_data } from "../../../api";
import ProfessionTable from "../Components/ProfessionTable";
import SearchComponent from "../Components/SearchComponent";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../globalConstants";

export default function Profession({ type }) {

    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(true);
    const [professoinData, setProfessionData] = useState([]);
    const [value, setValue] = useState('')
    const [addProfession, setAddProfession] = useState(false)
    const [totalPages, setTotalPages] = useState(null);
    const [totalProfessions, setTotalProfessions] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchState, setSearchState] = useState(false)

    const handleSearch = () => {
        setLoader(true)
        setTimeout(() => {
            if (value !== '') {
                searchProfessions()
                setSearchState(true)
            }
            else {
                getAllProfession()
                setSearchState(false)
            }
        }, 500);
    }

    const searchProfessions = async () => {
        try {
            const data = await post_data(`profession/search-professions/${value}?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setProfessionData(data?.data?.professions);
                setTotalPages(data?.data?.totalPages);
                setTotalProfessions(data?.data?.totalProfessions);
            }
            else {
                setLoader(false);
                setProfessionData([])
            }
        } catch (error) {
            setLoader(false);
            console.log("Error While Fetching All Customers", error);
        }
    }

    const permissions_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };


    const getAllProfession = async () => {
        try {
            const data = await get_data(`profession/get-all-profession?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setProfessionData(data?.data?.profession);
                setTotalPages(data?.data?.totalPages);
                setTotalProfessions(data?.data?.totalProfessions);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Profession", error);
        }
    }
    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchProfessions()
            }
            else {
                getAllProfession();
            }
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Profession", grid: 7 },
        { title: "Service", grid: 2 },
        { title: "Action", grid: 2 },
    ];

    const count = professoinData.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...permissions_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Professions' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => setAddProfession(true)}>
                        Create
                    </Button>
                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div>

                <div style={page_container}>
                    <ProfessionTable
                        addProfession={addProfession}
                        setAddProfession={setAddProfession}
                        tableHeader={tableHeader}
                        type="permissions"
                        data={professoinData}
                        totalProfessions={totalProfessions}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPages={totalPages}
                        loader={loader}
                        setLoader={setLoader}
                        getAllProfession={getAllProfession}
                    />
                </div>
            </Grid>
        </>
    )
}