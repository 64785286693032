import React from 'react';
import { Box, Typography, Button, Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar, useMediaQuery } from '@mui/material';
import { globalConstant } from '../../globalConstants';
import SubServicesSlider from '../Components/SubServicesSlider';
import Heading from '../Components/Heading';

const FeaturedSubServices = () => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 960px)');
    const { primaryColor } = globalConstant();
    const [refresh, setRefresh] = React.useState(false)

    const styles = {
        container: {
            padding: isMobile || isTablet ? '20px' : '2% 5%',
            backgroundColor: '#fff',
            overflow: 'hidden',
            textAlign: 'center',
            position: 'relative'
        },
        subServicesContainer: {
            padding: isMobile || isTablet ? '20px' : '3% 7%',
            overflow: 'hidden'
        },
        subheading: {
            fontSize: '16px',
            marginBottom: isMobile || isTablet ? '20px' : '3%'
        }
    };

    return (
        <>
            <div style={styles.container}>
                <img src='/images/blur-div.svg' style={{ position: 'absolute', right: 0, top: 0 }} />
                <Heading title={'Featured Sub Services'} color={primaryColor} />
                <p style={styles.subheading}>Go narrow and book from our Featured sub-services</p>
                <SubServicesSlider refresh={refresh} setRefresh={setRefresh} />
            </div>
        </>
    );
};

export default FeaturedSubServices;