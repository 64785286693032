import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import primaryColor, { globalConstant } from "../../../globalConstants";

export default function ScreenLoading() {

    const { primaryColor } = globalConstant();
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(timer);
                    return 100;
                }
                const diff = 5; // Increase by 5% every 100ms
                return Math.min(oldProgress + diff, 100);
            });
        }, 100); // Update every 100ms

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ width: 120 }}>
                <center>
                    <img src='/images/nivishka-favicon.png' className='loading-img' style={{ width: 70, height: 70, marginBottom: '5%' }} />
                    <LinearProgress variant="determinate" value={progress} />
                </center>
            </Box>
        </div>
    );
}