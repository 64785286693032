import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { convertMinutes, formatPrice, globalConstant } from '../../globalConstants';
import CartDrawer from './CartDrawer';
import { get_data, post_data } from "../../api"
import { useDispatch, useSelector } from 'react-redux';
import LoginModal from './LoginModal';
import { allSelectedSubServices } from '../../redux/slices/sub-service-slice';
import CartModal from '../../web-app/user-dashboard/Components/CartModal';

const SubServiceCard = ({ subService, refresh, setRefresh, screen, setOnsitePaymentOpen }) => {
    console.log('subServicesubService', subService)
    const isTablet = useMediaQuery('(max-width: 960px)');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { primaryColor } = globalConstant();
    const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const [allSubServices, setAllSubServices] = useState([]);
    const [isAdded, setIsAdded] = useState(false);
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    const styles = {
        card: {
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            backgroundColor: 'white',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
        },
        leftSection: {
            flex: 1,
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
        },
        icon: {
            color: '#4CAF50',
            marginRight: '10px',
        },
        title: {
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '10px',
        },
        rating: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
        },
        priceSection: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
        },
        originalPrice: {
            textDecoration: 'line-through',
            marginLeft: '10px',
            color: '#9E9E9E',
        },
        description: {
            marginBottom: '10px',
        },
        button: (added) => ({
            backgroundColor: added ? 'black' : 'white',
            color: added ? 'white' : primaryColor,
            border: `2px solid ${added ? 'black' : primaryColor}`,
            borderRadius: '7px',
            textTransform: 'none',
            padding: '5px 15px',
        }),
        list: {
            paddingLeft: '20px',
        },
        listItem: {
            fontSize: 14,
        }
    };

    // const toggleCartDrawer = (open) => (event) => {
    //     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //         return;
    //     }
    //     setIsCartDrawerOpen(open);
    // };

    const handleAddItem = () => {
        if (isLoggedIn) {
            setLoading(true);
            setTimeout(async () => {
                try {
                    let payload = {
                        selected_sub_service_id: subService?._id,
                    }
                    const response = await post_data(`selected-service/create-selected-service/${user_data?._id}`, payload)
                    if (response?.status) {
                        setRefresh(!refresh);
                        setLoading(false);
                        setIsCartDrawerOpen(true);
                    }
                    else {
                        setLoading(false);
                    }
                }
                catch (e) {
                    console.log("error ", e);
                }
            }, 1000);
        }
        else {
            setOpen(true)
        }
    }

    const handleOnsiteAddItem = () => {
        if (isLoggedIn) {
            setLoading(true);
            setTimeout(async () => {
                try {
                    let allselectedsubservices = JSON.parse(localStorage.getItem("all_selected_service"));
                    allselectedsubservices = Array.isArray(allselectedsubservices) ? allselectedsubservices : [];
                    const payload = {
                        sub_service_id: subService?._id,
                        amount: subService?.discounted_price,
                    }

                    localStorage.setItem("all_selected_service", JSON.stringify([...allselectedsubservices, payload]));

                    let serviceid = JSON.parse(localStorage.getItem("service_id"));

                    serviceid = Array.isArray(serviceid) ? serviceid : [];

                    localStorage.setItem("service_id", JSON.stringify([...serviceid, subService?.parent_service_id]));

                    let response = JSON.parse(localStorage.getItem("selected_service"));
                    let amount = parseInt(localStorage.getItem("selected_service_amount"));
                    amount = amount ? amount : 0;
                    response = Array.isArray(response) ? response : [];
                    amount = amount + subService?.discounted_price
                    localStorage.setItem("selected_service", JSON.stringify([...response, subService?._id]));
                    localStorage.setItem("selected_service_amount", JSON.stringify(amount));
                    setRefresh(!refresh);
                    setLoading(false);
                    setIsCartDrawerOpen(true);
                }
                catch (e) {
                    console.log("error ", e);
                }
            }, 1000);
        }
        else {
            setOpen(true)
        }
    }

    const fetchSelectedServices = async () => {
        try {
            const response = await get_data(`selected-service/get-selected-services-by-customer/${user_data?._id}`);
            if (response?.status) {
                setAllSubServices(response?.data);
            }
        } catch (e) {
            console.log("error ", e);
        }
    };

    const checkSelectedOnsiteServices = () => {
        let response = JSON.parse(localStorage.getItem("selected_service"));
        response = Array.isArray(response) ? response : [];
        // console.log('response.some(service => service == subService?._id)', response.some(service => service == subService?._id))
        setIsAdded(response.some(service => service == subService?._id));
    }

    useEffect(() => {
        if (screen === 'onsite') {
            checkSelectedOnsiteServices();
        } else {
            fetchSelectedServices();
        }
    }, [user_data, refresh]);

    useEffect(() => {
        if (screen != 'onsite') {
            setIsAdded(allSubServices.some(service => service?.selected_sub_service_id?._id === subService?._id));
        }
    }, [allSubServices, refresh]);

    return (
        <Box sx={styles.card}>
            <Box sx={styles.leftSection}>
                <Box sx={styles.header}>
                    <Box sx={styles.icon}><StarIcon /></Box>
                    <Typography variant="body2" color="textSecondary">VALUE FOR MONEY</Typography>
                </Box>
                <Typography sx={styles.title}>{subService?.sub_service_name}</Typography>
                {/* <Box sx={styles.rating}>
                    <StarIcon style={{ color: '#FFD700' }} />
                    <Typography variant="body2" color="textSecondary">4.88 (525.9K reviews)</Typography>
                </Box> */}
                <Box sx={styles.priceSection}>
                    <Typography variant="h6">{formatPrice(subService?.discounted_price)}</Typography>
                    <Typography variant="body2" sx={styles.originalPrice}>{formatPrice(subService?.price)}</Typography>
                    <Typography variant="body2">• {convertMinutes(subService?.duration)}</Typography>
                </Box>
                <Typography sx={styles.description}>
                    <p style={styles.listItem}>
                        {subService?.description}
                    </p>
                </Typography>
                <Button
                    style={styles.button(isAdded)}
                    onClick={screen === 'onsite' ? handleOnsiteAddItem : handleAddItem}
                    disabled={isAdded}
                >
                    {isAdded ? 'Added' : loading ? 'Adding...' : 'Add to cart'}
                </Button>
            </Box>
            <LoginModal open={open} setOpen={setOpen} />
            <CartModal open={isCartDrawerOpen} setOpen={setIsCartDrawerOpen} setOnsitePaymentOpen={setOnsitePaymentOpen} screen={screen} />
            {/* <CartDrawer refresh={refresh} setRefresh={setRefresh} isCartDrawerOpen={isCartDrawerOpen} setIsCartDrawerOpen={setIsCartDrawerOpen} /> */}
        </Box>
    );
};

export default SubServiceCard;

