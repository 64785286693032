import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useMediaQuery, Tabs, Tab, Backdrop, CircularProgress } from '@mui/material';
import { globalConstant } from '../../../globalConstants';
import { get_data, post_data } from '../../../api';
import useRazorpay from "react-razorpay";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../redux/slices/user-slice';

const Billings = () => {

    const dispatch = useDispatch();
    const [Razorpay] = useRazorpay();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const navigate = useNavigate();
    const isTablet = useMediaQuery('(max-width: 960px)');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { primaryColor } = globalConstant();
    const [memberShipData, setMemberShipData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const { user_data } = useSelector(state => state.user);
    const [open, setOpen] = useState(false);


    const getAllMemberShip = async () => {
        const data = await get_data("membership/get-all-membership")
        if (data.status) {
            setMemberShipData(data?.data[0]);
        }
    }

    useEffect(() => {
        getAllMemberShip();
    }, [])

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const styles = {
        container: {
            backgroundColor: '#fff',
            padding: isMobile || isTablet ? '20px' : '30px',
            borderRadius: '10px',
            border: '1px solid #E0E0E0',
            backgroundColor: 'white',
            width: isMobile || isTablet ? '80%' : '40%',
            margin: '2% 0 0 0',
            position: 'relative',
        },
        tabsContainer: {
            marginBottom: '20px',
            width: isDesktop ? 400 : 350,
            marginTop: '1%'
        },
        tab: {
            backgroundColor: 'transparent',
            flex: 1,
            fontSize: isDesktop ? 15 : 12,
            '&.Mui-selected': {
                backgroundColor: 'transparent',
                borderBottom: '3px solid ' + primaryColor,
                borderRadius: 0
            },
            '&.MuiTab-textColorPrimary': {
                color: "black",
            },
        },
        totalPayable: {
            fontSize: '24px',
            fontWeight: '600',
        },
        amount: {
            fontSize: '32px',
            fontWeight: '700',
            color: primaryColor,
            marginTop: 0,
            marginBottom: '20px',
        },
        actual_amount: {
            fontSize: '20px',
            fontWeight: '500',
            color: "#6666",
            marginTop: 0,
            marginBottom: '20px',
            textDecoration: "line-through"
        },
        itemRow: {
            display: 'flex',
            alignItems: 'center',
            gap: '1%',
            margin: '15px 0',
        },
        itemName: {
            fontSize: '20px',
            color: '#666',
        },
        itemAmount: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
        divider: {
            width: '100%',
            height: '1px',
            backgroundColor: '#e0e0e0',
            margin: '10px 0',
        },
        button: {
            padding: '13px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: user_data?.is_membership ? 'rgb(91, 91, 91)' : '#011102',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '100%',
            marginTop: '3%'
        }
    };


    const planItems = [
        "10% Discount on all Services at Nivishka"
    ];

    const discountedPrices = [memberShipData?.quaterly_discounted_price, memberShipData?.semi_yearly_discounted_price, memberShipData?.yearly_discounted_price]; // 0: Yearly, 1: Semi-Yearly
    const prices = [memberShipData?.quaterly_price, memberShipData?.semi_yearly_price, memberShipData?.yearly_price]; // 0: Yearly, 1: Semi-Yearly

    const getEndDate = () => {
        const today = new Date();
        if (selectedTab === 0) {
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(today.getMonth() + 3);
            return threeMonthsAgo;
        }
        else if (selectedTab === 1) {
            const sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(today.getMonth() + 6);
            return sixMonthsAgo;
        }
        else {
            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(today.getFullYear() + 1);
            return oneYearAgo;

        }
    }

    const handleRzrpPayment = async (params) => {
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    }

    const options = {
        key: "rzp_test_GQ6XaPC6gMPNwH",
        amount: discountedPrices[selectedTab] * 100,
        currency: "INR",
        name: "Nivishka Services",
        description: "Services with a Lifestyle",
        image: '/images/nivishka-logo.png',

        handler: async function (response) {
            setOpen(true);
            const payload = {
                customer_id: user_data?._id,
                membership_id: memberShipData?._id,
                start_date: new Date(),
                end_date: await getEndDate(),
                membership_tenure: selectedTab === 0 ? "quaterly" : selectedTab === 1 ? "semi-yearly" : "yearly",
                membership_status: "active",
                amount: discountedPrices[selectedTab]
            }

            const result = await post_data('membership-purchase/create-membership-purchase', payload)
            setOpen(false);
            if (result?.status) {
                dispatch(login(result?.data));
                navigate('/payment-status', { state: { status: 'success' } })
                window.scrollTo(0, 0)
            }
            else {
                navigate('/payment-status', { state: { status: 'failure' } })
                window.scrollTo(0, 0)
            }
        },
        prefill: {
            name: `${user_data?.full_name}`,
            email: user_data?.email,
            contact: user_data?.phone,
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#5DB761",
        },
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={styles.tabsContainer}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    centered
                    textColor="primary"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: primaryColor
                        }
                    }}
                >
                    <Tab label="Quaterly" sx={styles.tab} />
                    <Tab label="Semi-Yearly" sx={styles.tab} />
                    <Tab label="Yearly" sx={styles.tab} />
                </Tabs>
            </Box>
            <Box sx={styles.container}>
                <Typography sx={styles.totalPayable}>{memberShipData?.name}</Typography>
                <Typography sx={styles.amount}>₹{(discountedPrices[selectedTab])?.toLocaleString('en-IN')} <span style={styles.actual_amount}>₹{(prices[selectedTab])?.toLocaleString('en-IN')}</span>
                </Typography>

                {
                    planItems.map((item, index) => (
                        <Box sx={styles.itemRow} key={index}>
                            <img src='/images/check-icon.svg' alt="Check Icon" style={{ width: 30, height: 30 }} />
                            <Typography sx={styles.itemName}>{item}</Typography>
                        </Box>
                    ))
                }
                <Button
                    disabled={user_data?.is_membership}
                    style={styles.button}
                    onClick={handleRzrpPayment}
                >
                    {user_data?.is_membership ? 'Already a member of Plus Membership' : 'Activate Plus Membership'}
                </Button>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                // onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        </Box>
    );
};

export default Billings;
