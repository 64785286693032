import React from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { FaUsers, FaHeart, FaAward, FaSmile, FaRocket, FaCog } from 'react-icons/fa';
import { globalConstant } from '../../globalConstants';
import Heading from '../Components/Heading'

const values = [
    { icon: <FaUsers />, title: 'Care about our team', description: "We believe in empowering our employees by understanding what matters to them. Providing the right support ensures they deliver their best work and achieve their fullest potential." },
    { icon: <FaHeart />, title: 'Commit to Excellence', description: "We foster a culture of honesty, openness, and mutual respect. Our commitment to each other is to improve continuously, maintaining integrity and kindness in all interactions." },
    { icon: <FaAward />, title: 'Take Pride in Our Work', description: "Excellence is our standard. We deliver quality and integrity in every service we provide—no exceptions. Your satisfaction is our pride." },

    { icon: <FaSmile />, title: 'Customer-Centric Approach', description: "Our customers' success is our priority. By understanding both their stated and unstated needs, we strive to exceed expectations and deliver outstanding results every time." },
    { icon: <FaRocket />, title: 'Embrace the Challenge', description: "We thrive on solving difficult problems and embrace the unknown with a positive mindset. We ask, 'Why not?' because we believe in turning challenges into opportunities." },
    { icon: <FaCog />, title: 'Attention to Detail', description: "The smallest details make the biggest difference. We ensure precision in our work, recognizing that excellence lies in every meticulous detail." }
];

const OurValues = () => {

    const { primaryColor } = globalConstant()
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 960px)');

    const styles = {
        container: {
            padding: isMobile ? '20px' : isTablet ? '40px' : '5% 15%',
            textAlign: 'center',
        },
        title: {
            fontSize: '24px',
            marginBottom: '10px',
        },
        subTitle: {
            margin: isTablet || isMobile ? '90%' : '50%',
            fontSize: '16px',
            margin: 'auto',
            marginBottom: '30px',
        },
        icon: {
            fontSize: '40px',
            color: primaryColor,
            marginBottom: '10px',
        },
        valueBox: {
            textAlign: 'center',
            padding: '20px',
            border: '1px solid #f0f0f0',
            borderRadius: '10px',
            marginBottom: isMobile ? '20px' : '0',
        },
    };

    return (
        <Box sx={styles.container}>
            <Heading title={'Our values'} color={'black'} />
            <Typography sx={styles.subTitle}>
                At Nivishka Services, our core values are the foundation of everything we do. They guide our actions and shape our interactions with customers, partners, and each other. Here’s what we stand for:
            </Typography>
            <Grid container spacing={3}>
                {values?.map((value, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box sx={styles.valueBox}>
                            <div style={styles.icon}>{value.icon}</div>
                            <Typography variant="h6">{value.title}</Typography>
                            <Typography variant="body2">{value.description}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OurValues;
