import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Chip, Select, MenuItem, InputLabel, FormControl, Grid, CircularProgress, useMediaQuery } from '@mui/material';
import { formatDate, formatDateForSlot, formatDateTime, formatPrice, globalConstant } from '../../../globalConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { endPointURL, get_data, post_data } from '../../../api';
import Swal from 'sweetalert2';
import InvoiceModal from './InvoiceModal';
import RefundServicePayment from './RefundServicePayment';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const BookingDetailsModal = ({ getAllBooking, refund, open, setOpen, currentBooking, type }) => {
    console.log('current booking', currentBooking)
    const { user_data } = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [otpForm, setOtpForm] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showVendorAssingModal, setShowVendorAssingModal] = useState(false);
    const [vendor, setVendor] = useState();
    const [selectedVendorWithService, setSelectedVendorWithService] = useState();
    const [selectedVendor, setSelectedVendor] = useState("");
    const [assignedVendor, setAssignedVendor] = useState([]);
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [currentInvoice, setCurrentInvoive] = useState({});
    const [serviceSiteImages, setServiceSiteImages] = useState([]);
    const [serviceSiteData, setServiceSiteData] = useState('');
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [refundData, setRefundData] = useState([]);
    const [bookingImages, setBookingImages] = useState([]);
    const [files, setFiles] = useState('');
    const [mediaUpload, setMediaUpload] = useState(false);
    // const [currentIndex, setCurrentIndex] = useState('')

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const getVendorByService = async () => {
        const services = currentBooking?.service_id?.map((item) => {
            return item?._id;
        })

        const data = await post_data("vendor/get-vendor-by-service", { services, city: currentBooking?.billing_info?.city });
        const vendorsByService = {};

        data?.data?.forEach(service => {
            const serviceId = Object.keys(service)[0];

            const vendors = service[serviceId];

            const serviceName = vendors[0]?.profession?.profession_name;

            vendorsByService[serviceName] = vendors

        });

        setVendor(vendorsByService)
    }

    const checkAlreadyVendorAssing = () => {
        const vendorList = []
        currentBooking?.vendors?.forEach((item) => {
            if (item?.vendor_id?.full_name) {
                vendorList.push(item?.vendor_id?.full_name)
            }
        })


        setAssignedVendor(vendorList);
    }

    const getServiceSitePhoto = async () => {
        const data = await get_data(`booking-completed/get-booking-completed-by-bookingID/${currentBooking?._id}`);
        if (data?.status) {
            setServiceSiteImages(data?.data?.photos[0]?.split(",").map((item) => item));
            setServiceSiteData(data?.data);
        }
        else {
            setServiceSiteImages(false);
        }
    }

    const getBookingPhotos = () => {
        setBookingImages(currentBooking?.photos ? currentBooking?.photos?.split(",").map((photo) => photo) : []);
    }

    useEffect(() => {
        if (open) {
            getVendorByService();
            checkAlreadyVendorAssing();
            getServiceSitePhoto();
            getBookingPhotos();
        }
    }, [open]);

    const handleClose = () => {
        setOpen(false);
        setEmailError(false);
        setShowVendorAssingModal(false);
        setServiceSiteImages([]);
        setServiceSiteData('');
    };

    const { primaryColor } = globalConstant();
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflowY: 'auto',
            maxHeight: isDesktop ? '95%' : '80%',
            width: showVendorAssingModal ? '30%' : '85%',
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            textAlign: 'center',
            padding: !isDesktop ? 2 : ''
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        },
        amenity: {
            fontSize: '12px',
            color: '#666',
            backgroundColor: '#fff',
            border: '1px solid black',
            borderRadius: '15px',
            padding: '2px 7px',
            marginRight: '5px',
            marginBottom: '5px',
            color: 'black'
        },
        amenities: {
            display: 'flex',
            flexWrap: 'wrap',
            marginRight: 'auto',
            justifyContent: 'right'
        },
        button: {
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            borderRadius: '8px',
            cursor: 'pointer',
            border: '2px solid ' + primaryColor,
            marginTop: '3%',
            width: '30%',
            boxShadow: 'none'
        }
    };


    const statusStyles = {
        scheduled: {
            backgroundColor: '#ffe9a8',
            color: '#bf8f00',
        },
        completed: {
            backgroundColor: '#C8E6C9',
            color: '#388E3C',
        },
        cancelled: {
            backgroundColor: '#FFCDD2',
            color: '#D32F2F',
        }
    };

    const heading = {
        fontWeight: 600,
        fontSize: 22,
        marginBottom: '10px',
        textAlign: 'left',
        p: 3,
        borderBottom: '1px solid gainsboro'
    }


    const handleRaiseClick = (value) => {
        setShowRefundModal(true)
        setRefundData(value);
    }

    const DataRow = ({ label, value }) => (
        <Box sx={{
            display: 'flex',
            padding: '2% 0',
        }}>
            <InvoiceModal currentInvoice={currentInvoice} currentIndex={1} open={invoiceModal} setOpen={setInvoiceModal} />
            <Typography variant="body1" style={{ flex: 2, textAlign: 'left', fontSize: 15 }}>{label}:</Typography>
            <Typography style={{ flex: 2, textAlign: 'right', fontSize: 15 }}>
                {
                    label === 'Raise Refund Request' ?
                        <>
                            {refund && refund?.refund_status != "pending" ?
                                <Button
                                    variant="text"
                                    sx={{
                                        color: '#5DB761',
                                        '&:hover': { color: '#5DB761' }
                                    }}
                                >
                                    {refund?.refund_status}
                                </Button>
                                : <Button
                                    variant="text"
                                    sx={{
                                        color: '#5DB761',
                                        '&:hover': { color: '#5DB761' }
                                    }}
                                    onClick={() => {
                                        console.log('value value', value)
                                        handleRaiseClick(value)
                                    }}
                                >
                                    Refund Request
                                </Button>}

                        </>
                        :
                        label === 'Status' ?
                            <>
                                <Chip
                                    label={value}
                                    sx={{
                                        ...statusStyles[value],
                                        borderRadius: '5px',
                                        fontWeight: '500',
                                        marginLeft: 'auto',
                                        textTransform: 'capitalize'
                                    }}
                                />
                            </>
                            :
                            label === 'Vendor' && type !== 'customer' ?
                                <>
                                    <Box style={{ marginLeft: 'auto' }}>
                                        {assignedVendor.length === 0 ? (
                                            <Button
                                                variant="text"
                                                sx={{
                                                    color: '#5DB761',
                                                    '&:hover': { color: '#5DB761' }
                                                }}
                                                onClick={() => setShowVendorAssingModal(true)}
                                            >
                                                Assign Vendor
                                            </Button>
                                        ) : (
                                            <>{assignedVendor.join(', ')}</>
                                        )}
                                    </Box>
                                </>
                                :
                                label === 'Vendor' && type === 'customer' ?
                                    <>
                                        <Box style={{ marginLeft: 'auto' }}>
                                            {assignedVendor.length === 0 ?
                                                <div>-</div>
                                                :
                                                assignedVendor.map((item) => {
                                                    return (
                                                        <div>{item}</div>
                                                    )
                                                })}
                                        </Box>
                                    </>
                                    : label === 'Receipt' ?
                                        <>
                                            <p style={{ margin: 0, color: primaryColor, fontWeight: 600, cursor: 'pointer' }}
                                                onClick={() => {
                                                    setInvoiceModal(true)
                                                    setCurrentInvoive(currentBooking)
                                                }}
                                            >Download</p>
                                        </>
                                        :
                                        <>
                                            <Typography style={{ marginLeft: 'auto', fontSize: 15, textAlign: 'right' }} variant="body1">{value}</Typography>
                                        </>
                }
            </Typography>
        </Box>
    )

    const handleAssingVendor = async () => {
        const data = await post_data(`vendor/assign-vendor-by-admin/${currentBooking._id}`, { vendors: selectedVendorWithService })

        if (data.status) {
            getAllBooking();
            Toast.fire({
                title: "Vendor Assigned!",
                color: "green",
                icon: "success"
            });
        }
        else {
            Toast.fire({
                title: "Something Went Wrong!",
                color: "red",
                icon: "error"
            })
        }
        handleClose();
    };

    const RowData = ({ label, value }) => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2% 0',
        }}>
            <Typography variant="body1">{label}:</Typography>
            <Typography variant="body1">
                <>
                    <Box style={{ width: 200 }}>
                        <FormControl fullWidth>
                            <InputLabel>Select Vendor</InputLabel>
                            <Select
                                label="Select Vendor"
                                value={selectedVendor[label]}
                                onChange={(event) => {
                                    setSelectedVendor({ ...selectedVendor, [label]: event.target.value })
                                    setSelectedVendorWithService({ ...selectedVendorWithService, [value[0]?.profession?.service_id]: event.target.value })
                                }}
                            >
                                {value?.map((item) =>
                                    <MenuItem value={item._id}>{item.full_name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </>
            </Typography>
        </Box >
    )

    const displayServicesChip = (services) => {
        return (
            <Box sx={styles.amenities}>
                {services?.map((amenity, index) => (
                    <Typography key={index} sx={styles.amenity}>{amenity?.service_name}</Typography>
                ))}
            </Box>
        )
    }

    const displaySubServicesChip = (services) => {
        return (
            <Box sx={styles.amenities}>
                {services?.map((amenity, index) => (
                    <Typography key={index} sx={styles.amenity}>{amenity?.id?.sub_service_name}</Typography>
                ))}
            </Box>
        )
    }


    const displayCompleteChip = (data) => {
        return (
            <Box sx={styles.amenities}>
                {data?.map((amenity) => (
                    amenity?.sub_services.map((subservice, subIndex) => (
                        <Typography key={subIndex} sx={styles.amenity}>
                            {subservice?.sub_service_name}
                        </Typography>
                    ))
                ))}
            </Box>
        );
    };

    let serviceAddress = `${currentBooking?.billing_info?.street}, ${currentBooking?.billing_info?.zip_code}, ${currentBooking?.billing_info?.city}, ${currentBooking?.billing_info?.state}`

    const handleImageChange = (e) => {
        const fileArray = Array.from(e.target.files);
        setFiles(fileArray);

        console.log(e.target.files)
    }

    const handleUpload = async () => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("photos", files[i]);
        }

        setMediaUpload(true);

        const data = await post_data(`booking/upload-media-by-customer/${currentBooking._id}`, formData);
        setMediaUpload(false);
        if (data.status) {
            getAllBooking();
            Toast.fire({
                title: "Media Uploaded!",
                color: "green",
                icon: "success"
            });
        }
        else {
            Toast.fire({
                title: "Something Went Wrong!",
                color: "red",
                icon: "error"
            })
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={heading}>
                    Booking Details
                </Typography>
                {showVendorAssingModal ?
                    <Typography id="modal-modal-description" sx={{ mt: 2, padding: '2% 4% 6% 4%' }}>
                        {Object.keys(vendor).map((item) => {
                            return <RowData label={item} value={vendor[item]} />
                        })
                        }
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                boxShadow: 'none', padding: '2.5% 0', mt: 2, background: '#5DB761', '&:hover': { boxShadow: 'none', backgroundColor: '#5DB761' }
                            }}
                            onClick={handleAssingVendor}>
                            Assign Vendor
                        </Button>
                    </Typography>
                    :
                    <div id="modal-modal-description" style={{ marginTop: 2, padding: '2% 3% 6% 3%' }}>
                        <Grid container spacing={5}>
                            <Grid item md={4} style={{ width: '100%' }}>
                                <DataRow label="Booking ID" value={currentBooking?.booking_id} />
                                {
                                    type !== 'customer' && (
                                        <DataRow label="Customer" value={currentBooking?.customer_id?.full_name} />
                                    )
                                }
                                <DataRow label="City" value={currentBooking?.billing_info?.city} />
                                <DataRow label="Vendor" value={currentBooking?.vendors} />
                                <DataRow label="Service" value={displayServicesChip(currentBooking?.service_id)} />
                                <DataRow label="Sub-service" value={displaySubServicesChip(currentBooking?.sub_service_id)} />
                                <DataRow label="Completed services" value={displayCompleteChip(serviceSiteData?.completed_services)} />
                                <DataRow label="In-completed services" value={displayCompleteChip(serviceSiteData?.incompleted_services)} />
                                {
                                    type === 'customer' && serviceSiteData?.incompleted_services?.length > 0 && (
                                        <>
                                            <DataRow label="Raise Refund Request" value={serviceSiteData?.incompleted_services} />
                                            <RefundServicePayment refund={refund} open={showRefundModal} setOpen={setShowRefundModal} refundData={refundData} booking={currentBooking} getAllBookings={getAllBooking} />
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid item md={4} style={{ width: '100%' }}>
                                <DataRow label="Booked at" value={formatDate(currentBooking?.booking_date)} />
                                <DataRow label="Service date" value={`${currentBooking?.service_date && currentBooking?.service_date?.split(" ")[0]} ${currentBooking?.service_date?.split(" ")[1]}-${currentBooking?.service_date?.split(" ")[2]}-${currentBooking?.service_date?.split(" ")[3]}`} />
                                <DataRow label="Service time" value={currentBooking?.service_time} />
                                <DataRow label="Service address" value={serviceAddress} />
                                <DataRow label="Paid amount" value={formatPrice(currentBooking?.paid_amount)} />
                                <DataRow label="Remaining amount" value={formatPrice(currentBooking?.remaining_amount)} />
                                <DataRow label="Refund amount" value={formatPrice(currentBooking?.refund_amount)} />
                                <DataRow label="Total amount" value={formatPrice(currentBooking?.total_amount)} />
                                <DataRow label="Status" value={currentBooking?.booking_status} />
                                {
                                    type === 'customer' && (
                                        <DataRow label="Receipt" value={'Download'} />
                                    )
                                }
                            </Grid>
                            <Grid item md={4} style={{ width: '100%' }}>

                                <h3 style={{ margin: 0, fontWeight: 500, textAlign: 'left', fontSize: 18 }}>Service Sites Photos</h3>
                                <div
                                    style={{
                                        padding: 0, display: 'flex', gap: 10, flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'auto', height: 120, alignItems: 'start', textAlign: 'left'
                                    }} >
                                    {serviceSiteImages?.length === 0 ? (
                                        <CircularProgress />
                                    ) : serviceSiteImages === false ? (
                                        "No Service site images uploaded yet by vendor"
                                    ) : (
                                        serviceSiteImages?.map((image) => (
                                            image?.substring(image.lastIndexOf(".") + 1) != "mp4" &&
                                            <a href={`${endPointURL}/uploads/booking-picture/${image}`} target='_blank'>
                                                <div
                                                    key={image}
                                                    style={{ minWidth: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                >
                                                    <img
                                                        src={`${endPointURL}/uploads/booking-picture/${image}`}
                                                        alt="Service"
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 0 }}
                                                    />
                                                </div>
                                            </a>
                                        ))
                                    )}
                                </div>


                                <h3 style={{ margin: 0, fontWeight: 500, textAlign: 'left', fontSize: 18 }}>Service Sites Videos</h3>
                                <div
                                    style={{
                                        display: 'flex', gap: 10, flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'auto', height: 120, alignItems: 'start', textAlign: 'left'
                                    }} >
                                    {serviceSiteImages?.length === 0 ? (
                                        <CircularProgress />
                                    ) : serviceSiteImages === false ? (
                                        "No Service site videos uploaded yet by vendor"
                                    ) : (
                                        serviceSiteImages?.map((image) => (
                                            image?.substring(image.lastIndexOf(".") + 1) === "mp4" &&
                                            <a href={`${endPointURL}/uploads/booking-picture/${image}`} target='_blank'>
                                                <div
                                                    key={image}
                                                    style={{ minWidth: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                >
                                                    <video
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 0 }}
                                                        controls
                                                    >
                                                        <source src={`${endPointURL}/uploads/booking-picture/${image}`}></source>
                                                    </video>
                                                </div>
                                            </a>
                                        ))
                                    )}
                                </div>


                                <h3 style={{ margin: 0, fontWeight: 500, textAlign: 'left', fontSize: 18 }}>Photos/Videos by Customer</h3>
                                <div
                                    style={{
                                        display: 'flex', gap: 10, flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'auto', height: 120, alignItems: 'start', textAlign: 'left'
                                    }} >
                                    {bookingImages?.length === 0 ?
                                        files.length === 0 && type === 'customer' && currentBooking?.booking_status === 'scheduled' ?
                                            <div>
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    variant="text"
                                                    sx={{ color: primaryColor, textTransform: 'none' }}
                                                    tabIndex={-1}
                                                >
                                                    Upload Photos/Videos
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        multiple
                                                        accept="image/*,video/mp4"
                                                        onChange={handleImageChange}
                                                    />
                                                </Button>
                                            </div>
                                            :
                                            files.length > 0 ?
                                                <div>
                                                    <div
                                                        style={{
                                                            display: 'flex', gap: 10, flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'auto', height: 120, alignItems: 'start', textAlign: 'left'
                                                        }}>
                                                        {files.map((file, index) => (
                                                            file.type.startsWith('image/') ? (
                                                                <div
                                                                    style={{ minWidth: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                                >
                                                                    <img
                                                                        style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 0 }}
                                                                        key={index} src={URL.createObjectURL(file)} alt={`file-${index}`}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={{ minWidth: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                                >
                                                                    <video
                                                                        key={index}
                                                                        controls
                                                                        style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 0 }}
                                                                    >
                                                                        <source src={URL.createObjectURL(file)} type={file.type} />
                                                                    </video>
                                                                </div>
                                                            )
                                                        ))}
                                                    </div>

                                                </div> :
                                                "No Photos/Videos Uploaded yet by customer"
                                        :
                                        (
                                            bookingImages?.map((image) => (
                                                <a href={`${endPointURL}/uploads/booking-picture/${image}`} target='_blank'>
                                                    {
                                                        image?.substring(image.lastIndexOf(".") + 1) === "mp4" ?
                                                            <div
                                                                key={image}
                                                                style={{ minWidth: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                            >
                                                                <video
                                                                    style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 0 }}
                                                                    controls
                                                                >
                                                                    <source src={`${endPointURL}/uploads/booking-picture/${image}`}></source>
                                                                </video>
                                                            </div>
                                                            :
                                                            <div
                                                                key={image}
                                                                style={{ minWidth: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                            >
                                                                <img
                                                                    src={`${endPointURL}/uploads/booking-picture/${image}`}
                                                                    alt="Service"
                                                                    style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 0 }}
                                                                />
                                                            </div>}
                                                </a>
                                            ))
                                        )}
                                </div>

                                {files.length > 0 &&
                                    <div>
                                        <Button variant="text" sx={{ color: primaryColor, textTransform: 'none' }} onClick={handleUpload} disabled={mediaUpload}>{mediaUpload ? "Upload..." : "Upload"}</Button>
                                        <Button variant="text" sx={{ color: primaryColor, textTransform: 'none' }}
                                            disabled={mediaUpload}
                                            onClick={() => {
                                                setFiles([]);
                                            }}>Cancel</Button>
                                    </div>
                                }

                            </Grid>
                        </Grid>
                    </div>
                }
            </Box>
        </Modal>
    );
};

export default BookingDetailsModal;
