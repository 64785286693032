import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Fade, useMediaQuery } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { globalConstant } from '../../globalConstants';
import SubServiceCard from './SubServiceCard';
import { get_data } from '../../api';
import SubServicesSkeleton from './Skeleton/SubServicesSkeleton';
import { useSelector } from 'react-redux';
import Empty from '../../web-app/user-dashboard/Components/Empty';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const SubServicesSlider = ({ refresh, setRefresh, screen, setOpen, setIsOnsiteBooking }) => {

    const { user_data } = useSelector(state => state.user);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { primaryColor } = globalConstant();
    const [subServices, setSubServices] = useState([]);
    const [loader, setLoader] = useState(true);

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: '' }}
                onClick={onClick}
            >
                <IoIosArrowForward style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <IoIosArrowBack style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 150,
        autoplay: true,
        slidesToShow: isDesktop ? 3.5 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    const styles = {
        container: {
            overflow: screen === 'checkout' ? '' : 'hidden'
        },
        subheading: {
            fontSize: '16px',
            marginBottom: isDesktop ? '3%' : '20px'
        },
        serviceTitle: {
            marginTop: '10px',
            fontWeight: '600',
        },
        serviceCard: {
            padding: '10px',
        },
        serviceImage: {
            width: '100%',
            maxWidth: isDesktop ? 250 : 350,
            height: 'auto',
            margin: '0 auto',
            borderRadius: '10px'
        },
        serviceHeading: {
            color: primaryColor,
            textAlign: isDesktop ? 'left' : 'center',
            fontWeight: 600,
            fontSize: 19,
            marginLeft: '2%'
        },
        slide: {
            padding: '0 7px',
            textAlign: 'left'
        }
    };

    const getAllSubService = async () => {
        if (screen === 'onsite') {
            const data = await get_data(`sub-services/get-sub-services-for-onsite/${user_data._id}`);
            setSubServices(data?.data)
            setLoader(false);
            if (data?.data?.length > 0)
                setIsOnsiteBooking(true);
        }
        else {
            const data = await get_data("sub-services/get-sub-services-suggestion");

            if (data.status) {
                setSubServices(data?.data);
                setLoader(false)
            }
        }
    }

    useEffect(() => {
        getAllSubService();
    }, []);

    const showSlider = (subServices) => {
        return subServices?.map((subService, index) => {
            return (<div key={index}>
                <div style={styles.slide}>
                    <SubServiceCard screen={screen} setOnsitePaymentOpen={setOpen} subService={subService} refresh={refresh} setRefresh={setRefresh} />
                </div>
            </div>)
        })
    }

    return (
        <div style={styles.container}>
            {
                loader ?
                    <SubServicesSkeleton />
                    :
                    <>
                        {
                            screen === 'checkout' || screen === 'onsite' ?
                                <>
                                    {
                                        subServices && subServices.length > 0
                                            ?
                                            <Slider {...settings}>
                                                {showSlider(subServices)}
                                            </Slider>
                                            :
                                            <Empty />
                                    }
                                </>
                                :
                                <>
                                    <Slider {...settings}>
                                        {showSlider(subServices?.slice(0, Math.ceil(subServices?.length / 2)))}
                                    </Slider>
                                    <Slider {...settings}>
                                        {showSlider(subServices?.slice(Math.ceil(subServices?.length / 2), Math.ceil(subServices?.length / 2) * 2))}
                                    </Slider>
                                </>
                        }
                    </>
            }
        </div>
    );
};

export default SubServicesSlider;