import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import AdminInfo from '../Components/AdminInfo';
import DashboardHeading from '../Components/DashboardHeading';
import AdminChangePassword from '../Components/AdminChangePassword';
import AdminProfileCard from '../Components/AdminProfileCard';
import { useSelector } from 'react-redux';


const AdminProfile = () => {

    const { user_data, isLoggedIn } = useSelector(state => state.user);

    const settings_page = {
        width: '100%',
        marginBottom: '2%'
    };

    return (
        <>
            <Grid container spacing={0} style={settings_page}>
                <DashboardHeading title='Settings' />
            </Grid>
            <div style={{
                display: 'flex', overflowX: 'hidden', overflowY: 'auto',
                width: '100%',
            }}>
                <div style={{ width: '100%', display: 'flex', gap: '3%' }}>
                    <div style={{ flex: 2 }}>
                        <AdminProfileCard />
                    </div>

                    <div style={{ flex: 3 }}>
                        <AdminInfo />
                    </div>

                    <div style={{ flex: 3 }}>
                        {
                            user_data?.is_super_admin && (
                                <AdminChangePassword />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};


export default AdminProfile;
