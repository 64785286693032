import React, { useEffect, useState } from "react";
import { Grid, Typography, Pagination, CircularProgress, Modal, Box, TextField, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip, FormHelperText } from "@mui/material";
import { MdDeleteForever } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import styled from 'styled-components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@mui/material/styles';
import { endPointURL, get_data, post_data } from "../../../api";
import Swal from 'sweetalert2';
import Loader from "../../../website/Components/Loader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const ServiceBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  background: #fff;
  box-sizing:border-box;
  overflow: hidden;
  `;

export default function ServicesTable({
    tableHeader, data, itemsPerPage = 6, currentPage,
    setCurrentPage, count, totalPages, loader, setLoader,
    getAllService, addServices, setAddServices, totalServices
}) {

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [subService, setSubService] = useState([]);
    const [serviceName, setServiceName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedSubService, setSelectedSubService] = useState([]);
    const [serviceImage, setServiceImage] = useState('');
    const [localServiceImage, setLocalServiceImage] = useState('');
    const [errors, setErrors] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [updateID, setUpdateID] = useState('');
    const [disabled, setDisabled] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleOpen = () => setAddServices(true);
    const handleClose = () => {
        setServiceName('');
        setSelectedSubService([]);
        setDescription('');
        setServiceImage('');
        setLocalServiceImage('');
        setIsUpdate(false);
        setDisabled(false);
        setAddServices(false);
        setErrors({});
    };


    const handleChange = (event) => {
        const { target: { value } } = event;
        setSelectedSubService(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const getSubServices = async () => {
        try {
            const data = await get_data("sub-services/get-all-sub-services");
            if (data.status) {
                const subServiceName = data?.data?.subServices.map((item) => {
                    return { id: item._id, subServiceName: item.sub_service_name };
                })
                setSubService(subServiceName);
            }
        } catch (error) {
            console.log("Erro While Fetching Sub Services ", error);
        }
    }


    useEffect(() => {
        getSubServices();
    }, [])

    function getStyles(name, selectedSubService, theme) {
        return {
            fontWeight:
                selectedSubService?.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        background: "white",
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalServices);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: 'none',
        outline: 'none',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
    };

    const handleDeleteService = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#5DB761",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`services/delete-service/${id}`, {})
                    if (data.status) {
                        Toast.fire({
                            title: "Service Deleted!",
                            color: "green",
                            icon: "success"
                        });
                        getAllService();
                    }
                    else {
                        Toast.fire({
                            title: "Something Went Wrong!",
                            color: "red",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    Toast.fire({
                        title: "Something Went Wrong!",
                        color: "red",
                        icon: "warning"
                    });
                    console.log("error while delete service ", error);
                }
            }
        });
    }


    const handleSetUpdateService = (item) => {
        setIsUpdate(true);
        setUpdateID(item?._id);
        setServiceName(item?.service_name);
        setDescription(item?.description);
        setServiceImage(item?.service_image);
        const subService = item?.sub_services.map((item) => {
            return item._id
        })
        setSelectedSubService(subService);
        handleOpen();
    }

    const idToServiceName = subService.reduce((acc, item) => {
        acc[item.id] = item.subServiceName;
        return acc;
    }, {});

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }

    function validate() {
        let error = false;

        if (serviceName.length === 0) {
            error = true;
            handleError('serviceName', 'Service Name is required')
        }
        if (description.length === 0) {
            error = true;
            handleError('description', 'Description is required')
        }
        // if (selectedSubService.length === 0) {
        //     error = true;
        //     handleError('subService', 'Sub Service is required')
        // }
        if (serviceImage.length === 0) {
            error = true;
            handleError('serviceImage', 'Service Image is required')
        }
        return error;
    }

    const handleAddService = async () => {
        const error = validate();
        if (!error) {
            try {
                setDisabled(true);
                const formdata = new FormData();
                formdata.append("service_name", serviceName);
                formdata.append("description", description);
                selectedSubService.forEach((item, index) => {
                    formdata.append(`sub_services[${index}]`, item);
                })
                formdata.append("service_image", serviceImage);
                const data = await post_data("services/create-service", formdata);

                if (data.status) {
                    handleClose();
                    getAllService();
                    Toast.fire({
                        icon: 'success',
                        title: "Service Added Successfully",
                        color: "green"
                    })
                }
                else {
                    handleClose();
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "green"
                    })
                }
            } catch (error) {
                handleClose();
                console.log("Error While Add Service ", error);
            }
        }
    }


    const handleUpdateService = async () => {
        const error = validate();
        if (!error) {
            setDisabled(true);
            if (localServiceImage) {
                try {
                    const formdata = new FormData();
                    formdata.append("service_name", serviceName);
                    formdata.append("description", description);
                    selectedSubService.forEach((item, index) => {
                        formdata.append(`sub_services[${index}]`, item);
                    })
                    formdata.append("service_image", serviceImage);
                    const data = await post_data(`services/update-service-with-picture/${updateID}`, formdata);

                    if (data.status) {
                        handleClose();
                        Toast.fire({
                            icon: 'success',
                            title: "Service Updated Successfully",
                            color: "green"
                        })
                        getAllService();
                    }
                    else {
                        handleClose();
                        Toast.fire({
                            icon: 'Warning',
                            title: "Something Went Wrong",
                            color: "red"
                        })
                    }
                } catch (error) {
                    handleClose();
                    console.log("Error While Update Service ", error);
                }
            }
            else {
                try {
                    const body = { service_name: serviceName, description: description, sub_services: selectedSubService }
                    const data = await post_data(`services/update-service/${updateID}`, body);

                    if (data.status) {
                        handleClose();
                        getAllService();
                        Toast.fire({
                            icon: 'success',
                            title: "Service Updated Successfully",
                            color: "green"
                        })
                    }
                    else {
                        handleClose();
                        Toast.fire({
                            icon: 'warning',
                            title: "Something Went Wrong",
                            color: "red"
                        })
                    }
                } catch (error) {
                    handleClose();
                    console.log("Error While update Service ", error);
                }
            }
        }
    }

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader?.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 || index === 2 || index === 3 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };



    const displayAllHeader = () => {
        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'right',
                    cursor: 'pointer',
                }}
                >
                    <span style={{ display: 'flex', alignItems: 'center', gap: '3px', color: '#34eb52' }}>
                        <Modal
                            open={addServices}
                            onClose={handleClose}
                        >
                            <Box sx={style}>
                                <Typography>
                                    <ServiceBox>
                                        {/* <FormSection> */}
                                        <Grid container spacing={2}>

                                            <Grid item xs={12} style={{ display: "flex", alignItems: 'center', flexDirection: 'column', marginTop: 0 }}>

                                                {
                                                    isUpdate && localServiceImage ?
                                                        <Grid item xs={6}>
                                                            <img alt="service" src={localServiceImage} style={{ width: 150, height: 150, borderRadius: '50%' }} />
                                                        </Grid>
                                                        : isUpdate ?
                                                            <Grid item xs={6}>
                                                                <img alt="service" src={`${endPointURL}/uploads/service-picture/${serviceImage}`} style={{ width: 150, height: 150, borderRadius: '50%' }} />
                                                            </Grid>
                                                            : localServiceImage ?
                                                                <Grid item xs={6}>
                                                                    <img alt="service" src={localServiceImage} style={{ width: 150, height: 150, borderRadius: '50%' }} />
                                                                </Grid>
                                                                :
                                                                <></>
                                                }
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Enter Service Name"
                                                    value={serviceName}
                                                    error={errors.serviceName}
                                                    helperText={errors.serviceName}
                                                    sx={{ mt: 2 }}
                                                    onChange={(e) => {
                                                        setServiceName(e.target.value);
                                                    }}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, serviceName: "" })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Enter Description"
                                                    error={errors.description}
                                                    helperText={errors.description}
                                                    sx={{ mt: 2 }}
                                                    value={description}
                                                    onChange={(e) => {
                                                        setDescription(e.target.value);
                                                    }}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, description: "" })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <FormControl error={errors.subService} fullWidth sx={{ mt: 2 }}>
                                                    <InputLabel>Select Sub Service</InputLabel>
                                                    <Select
                                                        multiple
                                                        value={selectedSubService}
                                                        onChange={handleChange}
                                                        input={<OutlinedInput label="Select Sub Service" />}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {selected.map((value) => (
                                                                    <Chip key={value} label={idToServiceName[value]} />
                                                                ))}
                                                            </Box>
                                                        )}
                                                        MenuProps={MenuProps}
                                                        onFocus={() => {
                                                            setErrors({ ...errors, subService: "" })
                                                        }}
                                                    >
                                                        {subService.map((name) => (
                                                            <MenuItem
                                                                key={name.id}
                                                                value={name.id}
                                                                style={getStyles(name.subServiceName, selectedSubService, theme)}
                                                            >
                                                                {name.subServiceName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{errors.subService}</FormHelperText>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <FormControl fullWidth error={errors.serviceImage}>
                                                    <Button
                                                        fullWidth
                                                        component="label"
                                                        size="large"
                                                        role={undefined}
                                                        variant="contained"
                                                        tabIndex={-1}
                                                        style={{ boxShadow: 'none', background: 'transparent', border: '1px dashed gainsboro', color: 'black' }}
                                                        startIcon={<CloudUploadIcon />}
                                                        sx={{ mt: 2 }}
                                                        error={errors.serviceImage}
                                                        onFocus={() => {
                                                            setErrors({ ...errors, serviceImage: "" })
                                                        }}
                                                    >
                                                        {isUpdate ? "Change file" : "Upload file"}
                                                        <input type="file" required hidden accept="images/*" onChange={(e) => {
                                                            setServiceImage(e.target.files[0])
                                                            setLocalServiceImage(URL.createObjectURL(e.target.files[0]))
                                                        }} />
                                                    </Button>
                                                    <FormHelperText>{errors.serviceImage}</FormHelperText>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    disabled={disabled}
                                                    sx={{
                                                        padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
                                                    }}
                                                    onClick={isUpdate ? handleUpdateService : handleAddService}
                                                >
                                                    {isUpdate ? "Update Service" : "Add Service"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {/* </FormSection> */}
                                    </ServiceBox>
                                </Typography>
                            </Box>
                        </Modal>
                    </span>
                </div>
                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        :
                        data?.length > 0 ?
                            data?.map((item, i) => (
                                <div style={{ width: "100%" }} key={i}>
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{
                                            width: "100%",
                                            padding: 6,
                                            display: "flex",
                                            alignItems: "center",
                                            borderTop: "1px dashed gainsboro",
                                        }}
                                    >
                                        <Grid item xs={0.5}>
                                            <h3 style={{ ...table_row_font, color: "#3F4254" }}>{startEntry + i}</h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ display: "flex", alignItems: "center", justifyContent: 'left' }}
                                        >
                                            <img src={`${endPointURL}/uploads/service-picture/${item?.service_image}`} style={{ width: 45, height: 45, borderRadius: 5 }} />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4.5}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254",
                                                }}
                                            >
                                                {item.service_name}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254",
                                                }}
                                            >
                                                {item.description?.slice(0, 100)}...
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{ display: "flex", justifyContent: "center", gap: '10px' }}
                                        >
                                            <img onClick={() => handleSetUpdateService(item)} src="/images/edit-img.svg" style={{ cursor: 'pointer' }} />
                                            <img onClick={() => handleDeleteService(item._id)} src="/images/delete-img.svg" style={{ cursor: 'pointer' }} />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))
                            :
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}
                            >
                                No Service Found...
                            </Grid>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalServices} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}