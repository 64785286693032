import { Box, Modal, Typography, useMediaQuery } from "@mui/material"
import { useState } from "react";
import SubServiceCard from './SubServiceCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Empty from "../../web-app/user-dashboard/Components/Empty";

const SearchModal = ({ open, setOpen, searchData, modalData }) => {

    const [refresh, setRefresh] = useState(false);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const handleClose = () => {
        setOpen(false);
    }

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "70%",
            border: 'none',
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
        },
        slide: {
            padding: '0 7px',
            textAlign: 'left',
        }
    }

    const heading = {
        fontWeight: 600,
        fontSize: 22,
        marginBottom: '10px',
        textAlign: 'left',
        p: 3,
        borderBottom: '1px solid gainsboro'
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 150,
        autoplay: true,
        slidesToShow: isDesktop ? 3 : 1.2,
        slidesToScroll: 1,
    };

    const showSlider = (subServices) => {
        return subServices?.map((subService, index) => {
            return (<div key={index}>
                <div style={styles.slide}>
                    <SubServiceCard subService={subService} refresh={refresh} setRefresh={setRefresh} />
                </div>
            </div>)
        })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                border: 'none',
                "& .MuiBackdrop-root": {
                }
            }}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={heading}>
                    Result of: '{searchData}'
                </Typography>

                <div style={{ margin: '3%' }}>
                    {
                        modalData?.length === 0 ?
                            <>
                                <Empty />
                            </>
                            :
                            <>
                                <Slider {...settings}>
                                    {showSlider(modalData)}
                                </Slider>
                            </>
                    }
                </div>
            </Box>
        </Modal >
    )
}

export default SearchModal;