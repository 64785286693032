import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Pagination } from "@mui/material";
import { formatDate, formatPrice } from "../../../globalConstants";
import Loader from "../../../website/Components/Loader";
import InvoiceModal from "./InvoiceModal";

export default function SalesTable({
    tableHeader,
    type,
    data,
    itemsPerPage = 6,
    currentPage,
    setCurrentPage,
    loader,
    count,
    setLoader,
    totalPages,
    totalSales
}) {

    const [invoiceModal, setInvoiceModal] = useState(false);
    const [currentInvoice, setCurrentInvoive] = useState({})
    const [currentIndex, setCurrentIndex] = useState('')

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };

    const table_heading = {
        fontWeight: 600,
        fontSize: 17,
        color: "#3F4254",
    };

    const table_tagline = {
        fontWeight: 500,
        color: "#A1A5B7",
        fontSize: 13,
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalSales);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };


    function displayTableHeader() {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const displayAllInvoices = () => {
        return (
            <div>
                <InvoiceModal currentInvoice={currentInvoice} currentIndex={currentIndex} open={invoiceModal} setOpen={setInvoiceModal} />
                {displayTableHeader()}
                {
                    loader ?
                        <>
                            <Grid
                                item
                                xs={12}
                                style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                            >
                                <Loader />
                            </Grid>
                        </>
                        :
                        <>
                            {data?.map((item, i) => (
                                <div style={{ width: "100%" }} key={i}>
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{
                                            width: "100%",
                                            padding: '1% 0',
                                            display: "flex",
                                            alignItems: "center",
                                            borderTop: "1px dashed gainsboro",
                                        }}
                                    >
                                        <Grid item xs={1}>
                                            <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>{startEntry + i}</h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{ display: "flex", alignItems: "center", justifyContent: 'left' }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: '#3F4254',
                                                    margin: 0, padding: 0
                                                }}
                                            >
                                                {item?.billing_info?.phone || '-'}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                                {item?.billing_info?.full_name || '-'}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    margin: 0,
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                    color: "#3F4254",
                                                    margin: 0, padding: 0
                                                }}
                                            >
                                                {formatDate(item?.booking_date)}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    margin: 0,
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                    color: "#3F4254",
                                                    margin: 0, padding: 0
                                                }}
                                            >
                                                {formatPrice(item?.total_amount)}
                                            </h3>
                                        </Grid>
                                        {/* <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    margin: 0,
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                    margin: 0, padding: 0,
                                                    color: "#3F4254",
                                                }}
                                            >
                                                {formatPrice(item?.remaining_amount)}
                                            </h3>
                                        </Grid> */}
                                        <Grid
                                            item
                                            xs={1}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                color: "#3F4254",
                                            }}
                                        >
                                            <div style={{ width: "60%", margin: "auto" }}>
                                                <p
                                                    onClick={() => {
                                                        setInvoiceModal(true)
                                                        setCurrentInvoive(item)
                                                        setCurrentIndex(i)
                                                    }}
                                                    style={{
                                                        fontSize: 14,
                                                        borderRadius: 4,
                                                        color: "#50CD89",
                                                        margin: 0, padding: 0,
                                                        textAlign: "center",
                                                        fontWeight: 500,
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Download
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllInvoices()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalSales} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}