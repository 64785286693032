import React from "react";
import AdminAvatar from "./AdminAvatar";
import NotificationsDrawer from "./NotificationsDrawer";
import { useSelector } from "react-redux";
import BlogAdminAvatar from "../../../blog/components/BlogAdminAvatar";
import { Button } from "@mui/material";
import { globalConstant } from "../../../globalConstants";
import { ImBlog } from "react-icons/im";
import { useNavigate } from "react-router-dom";

export default function TopBar({ socket, permissions, type }) {

    const { user_data } = useSelector(state => state.user);
    const { primaryColor } = globalConstant()
    const navigate = useNavigate()

    const top_section = {
        display: 'flex',
        alignItems: 'center',
        height: 65,
        padding: '0 3%',
        background: 'white',
        borderBottom: '1px solid gainsboro',
        boxShadow: '0 3px 4px 0 #00000008'
    };

    const button = {
        padding: '3px 20px',
        fontSize: '16px',
        color: 'black',
        border: '1px solid black',
        // backgroundColor: primaryColor,
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        boxShadow: 'none',
    }

    const handleBlog = () => {
        navigate('/blog/dashboard/posts')
    }

    return (
        <>
            <div style={top_section}>
                <div style={{ width: '30%', position: "relative" }}>
                    <h2>Hii {user_data?.name} 👋</h2>
                </div>
                <div style={{ width: '70%', display: "flex", justifyContent: "right", alignItems: "center" }}>

                    <div style={{ marginRight: '2%' }}>
                        {
                            type !== 'blog' && (
                                <>
                                    {
                                        user_data?.is_sub_admin ? (
                                            permissions?.includes('blog') && (
                                                <Button onClick={handleBlog} variant="outlined" style={button} startIcon={<ImBlog />}>
                                                    Blog
                                                </Button>
                                            )
                                        ) : (
                                            <Button onClick={handleBlog} variant="outlined" style={button} startIcon={<ImBlog />}>
                                                Blog
                                            </Button>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>

                    <div style={{ marginRight: '2%' }}>
                        {
                            type !== 'blog' && (
                                <>
                                    {
                                        user_data?.is_sub_admin ? (
                                            permissions?.includes('notifications') && (
                                                <NotificationsDrawer socket={socket} />
                                            )
                                        ) : (
                                            <NotificationsDrawer socket={socket} />
                                        )
                                    }
                                </>
                            )
                        }
                    </div>

                    <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                        {
                            type === 'blog' ?
                                <>
                                    <BlogAdminAvatar />
                                </>
                                :
                                <>
                                    <AdminAvatar />
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}