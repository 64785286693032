import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Grid, TextField, MenuItem, Button, InputLabel, Select, Chip, Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDropzone } from 'react-dropzone';
import { globalConstant } from '../../globalConstants';
import { endPointURL, get_data, post_data } from '../../api';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';

const EditPostForm = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { postData } = location.state;

    const { primaryColor } = globalConstant();
    const [title, setTitle] = useState(postData?.title || '');
    const [slug, setSlug] = useState(postData?.slug || '');
    const [content, setContent] = useState(postData?.content || '');
    const [selectedCategories, setSelectedCategories] = useState(postData?.category?.map((item) => item._id) || []);
    const [tags, setTags] = useState(postData?.tags?.map((item) => item)?.join(",")?.split(",") || []);
    const [status, setStatus] = useState(postData?.status || 'draft');
    const [featuredImage, setFeaturedImage] = useState(null);
    const [tagInput, setTagInput] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [errors, setErrors] = useState({});
    const [image, setImage] = useState(null);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    // Fetch all categories from the API
    const getAllCategory = async () => {
        try {
            const data = await get_data('category/get-all-category-v2');
            if (data.status) {
                setCategoryData(data?.data);
            }
        } catch (error) {
            console.error('Failed to fetch categories:', error);
        }
    };

    useEffect(() => {
        getAllCategory();
    }, []);

    // Handle category selection
    const handleCategoryChange = (event) => {
        const selectedIds = event.target.value;
        setSelectedCategories(selectedIds);
    };

    // Other event handlers
    const handleTagChange = (event) => {
        setTagInput(event.target.value);
    };

    const handleTagKeyPress = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            if (tagInput.trim() && !tags.includes(tagInput.trim())) {
                setTags([...tags, tagInput.trim()]);
                setTagInput('');
            }
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };

    const handleSlugChange = (event) => {
        const titleValue = event.target.value;
        const slugValue = titleValue
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
        setSlug(slugValue);
    };

    const validate = () => {
        let validationErrors = {};
        if (!title.trim()) validationErrors.title = 'Title is required';
        if (!slug.trim()) validationErrors.slug = 'Slug is required';
        if (!content.trim()) validationErrors.content = 'Content is required';
        if (selectedCategories.length === 0) validationErrors.selectedCategories = 'At least one category must be selected';
        // if (!featuredImage) validationErrors.featuredImage = 'Featured image is required';

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (validate()) {
            if (image) {
                const formdata = new FormData();

                formdata.append("title", title);
                formdata.append("content", content);
                formdata.append("tags", tags);
                formdata.append("status", status);
                formdata.append("slug", slug);

                selectedCategories?.forEach((item, index) => {
                    formdata.append(`category[${index}]`, item);
                })

                formdata.append("featured_image", image);
                try {
                    const data = await post_data(`post/update-post-with-picture/${postData?._id}`, formdata);

                    if (data.status) {
                        navigate("/blog/dashboard/posts")
                        Toast.fire({
                            icon: 'success',
                            title: "Post Updated Successfully",
                            color: "green"
                        })
                    }
                    else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something Went Wrong",
                            color: "green"
                        })
                    }
                } catch (error) {
                }
            } else {
                const Payload = {
                    title,
                    content,
                    tags,
                    status,
                    slug,
                    category: selectedCategories
                }


                const data = await post_data(`post/update-post/${postData._id}`, Payload);

                if (data.status) {
                    Toast.fire({
                        icon: 'success',
                        title: "Post Updated Successfully",
                        color: "green"
                    })

                    navigate("/blog/dashboard/posts")
                }
                else {
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "green"
                    })
                }
            }
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        setFeaturedImage(URL.createObjectURL(file));
        setImage(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxFiles: 1,
    });

    const button = {
        background: primaryColor,
        color: 'white',
        borderRadius: 10,
        boxShadow: 'none',
        marginTop: '3%',
        padding: '3% 6%',
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                    ['image', 'link', 'video'],
                    ['code-block'],
                    [
                        {
                            color: [
                                '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc',
                                '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
                                '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
                                '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00',
                                '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
                                '#663d00', '#666600', '#003700', '#002966', '#3d1466',
                            ],
                        },
                    ],
                ],
                imageResize: {
                    displaySize: true,
                },
            },
        }),
        []
    );

    return (
        <Grid container spacing={2}>
            {/* Left Grid (Title and Content) */}
            <Grid item xs={12} md={8.5}>
                <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    required
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                        handleSlugChange(e);
                    }}
                    style={{ marginBottom: '16px' }}
                    error={!!errors.title}
                    helperText={errors.title}
                />
                <ReactQuill
                    value={content}
                    onChange={setContent}
                    style={{ height: 900 }}
                    modules={modules}
                />
                {errors.content && <p style={{ color: 'red', marginTop: '8px' }}>{errors.content}</p>}
            </Grid>

            {/* Right Grid (Other Fields) */}
            <Grid item xs={12} md={3.5}>
                <TextField
                    label="Slug"
                    variant="outlined"
                    fullWidth
                    required
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    style={{ marginBottom: '16px' }}
                    error={!!errors.slug}
                    helperText={errors.slug}
                />

                <InputLabel>Categories</InputLabel>
                <Select
                    multiple
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                    renderValue={(selected) =>
                        selected
                            .map((id) => {
                                const category = categoryData?.find((cat) => cat._id === id);
                                return category ? category?.category_name : '';
                            })
                            .join(', ')
                    }
                    fullWidth
                    style={{ marginBottom: '16px' }}
                    error={!!errors.selectedCategories}
                >
                    {categoryData?.map((category) => (
                        <MenuItem key={category?._id} value={category?._id}>
                            {category?.category_name}
                        </MenuItem>
                    ))}
                </Select>
                {errors.selectedCategories && (
                    <p style={{ color: 'red', marginTop: '8px' }}>{errors.selectedCategories}</p>
                )}

                <TextField
                    label="Tags"
                    variant="outlined"
                    fullWidth
                    value={tagInput}
                    onChange={handleTagChange}
                    onKeyPress={handleTagKeyPress}
                    style={{ marginBottom: '16px' }}
                />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {tags?.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag}
                            style={{ background: primaryColor, color: 'white' }}
                            onDelete={() => handleDeleteTag(tag)}
                        />
                    ))}
                </Box>

                <InputLabel>Status</InputLabel>
                <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    fullWidth
                    style={{ marginBottom: '16px' }}
                >
                    <MenuItem value="draft">Draft</MenuItem>
                    <MenuItem value="published">Published</MenuItem>
                </Select>

                <div {...getRootProps()} style={{
                    border: '2px dashed #ccc',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginBottom: '16px',
                }}>
                    <input {...getInputProps()} />
                    <p>Drag & drop a file here, or click to select one</p>
                </div>
                {featuredImage ? (
                    <img
                        src={featuredImage}
                        alt="Featured"
                        style={{ width: '100%', borderRadius: '8px', marginTop: '16px' }}
                    />
                )
                    :
                    (
                        <img
                            src={`${endPointURL}/uploads/post-pictures/${postData?.featured_image}`}
                            alt='featured'
                            style={{ width: '100%', borderRadius: '8px', marginTop: '16px' }}
                        />
                    )
                }
                {errors.featuredImage && <p style={{ color: 'red', marginTop: '8px' }}>{errors.featuredImage}</p>}

                <Button variant="contained" style={button} onClick={handleSubmit}>
                    Update Post
                </Button>
            </Grid>
        </Grid>
    );
};

export default EditPostForm;
