import { TextField, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globalConstant } from '../../globalConstants'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { post_data } from '../../api';
import SearchModal from './SearchModal';

// const HeroSection = () => {

//     const isDesktop = useMediaQuery('(min-width: 768px)');
//     const { primaryColor } = globalConstant()

//     const styles = {
//         container: {
//             display: 'flex',
//             flexDirection: isDesktop ? 'row' : 'column',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             padding: isDesktop ? '0 3%' : '20px 1px',
//             backgroundColor: '#efffef',
//         },
//         leftSide: {
//             flex: 1,
//             padding: '20px',
//             order: isDesktop ? 1 : 2
//         },
//         rightSide: {
//             flex: 1,
//             padding: '20px',
//             display: 'flex',
//             justifyContent: 'center',
//             order: isDesktop ? 2 : 1
//         },
//         title: {
//             fontSize: 20,
//             fontWeight: 500,
//         },
//         heading: {
//             fontSize: isDesktop ? 50 : '32px',
//             fontWeight: '700',
//             marginBottom: '20px',
//             marginTop: 0
//         },
//         highlight: {
//             color: primaryColor,
//         },
//         description: {
//             fontSize: '16px',
//             marginBottom: '20px',
//         },
//         button: {
//             padding: isDesktop ? '3% 8%' : '10px 20px',
//             fontSize: '16px',
//             color: '#fff',
//             backgroundColor: primaryColor,
//             border: 'none',
//             borderRadius: '5px',
//             cursor: 'pointer',
//             marginTop: "2%"
//         },
//         image: {
//             maxWidth: isDesktop ? '80%' : '100%',
//             height: 'auto',
//             borderRadius: '10px',
//         }
//     };

//     const headings = ["Home Maintenance", "Office Security", "Gardening"];

//     const [currentHeading, setCurrentHeading] = useState(0);
//     const [displayText, setDisplayText] = useState("");
//     const [charIndex, setCharIndex] = useState(0);
//     const [searchData, setSearchData] = useState('');
//     const [open, setOpen] = useState(false);
//     const [modalData, setModalData] = useState('');

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setDisplayText((prev) => prev + headings[currentHeading][charIndex]);
//             setCharIndex((prev) => prev + 1);
//         }, 100);

//         if (charIndex === headings[currentHeading].length) {
//             clearInterval(interval);
//             setTimeout(() => {
//                 setCurrentHeading((prev) => (prev + 1) % headings.length);
//                 setDisplayText("");
//                 setCharIndex(0);
//             }, 2000);
//         }

//         return () => clearInterval(interval);
//     }, [charIndex, currentHeading, headings]);


//     const handleSearch = async () => {
//         if (searchData) {
//             const data = await post_data(`services/search`, { value: searchData.trim() })
//             setOpen(true);
//             if (data.status) {
//                 if (data?.message === "subService") {
//                     setModalData(data?.data)
//                 }
//                 else {
//                     setModalData(data?.data[0]?.sub_services);
//                 }
//             }
//             else {
//                 setModalData("No Result Found");
//             }
//         }
//     };

//     const handleKeyDown = (event) => {
//         if (event.key === 'Enter') {
//             handleSearch();
//         }
//     };

//     return (
//         <div style={styles.container}
//         // className='hero-sec'
//         >
//             <div style={styles.leftSide}>
//                 <h2 style={styles.title}>We Provide Quality Service</h2>
//                 <h2 style={styles.heading}><span style={{ color: primaryColor }}>{displayText}</span> <br />Transform Your Home with Expert Care & Precision</h2>
//                 <p style={styles.description}>
//                     At Nivishka Services, we are committed to delivering top-notch home maintenance and furnishing solutions that make your home shine. Our team of skilled professionals ensures every detail is perfect, providing you with the best service available in the industry.
//                 </p>
//                 <div>
//                     <TextField
//                         fullWidth
//                         style={{ background: 'white' }}
//                         label="Search Services..."
//                         onChange={(e) => {
//                             setSearchData(e.target.value)
//                         }}
//                         InputProps={{
//                             endAdornment: (
//                                 <InputAdornment>
//                                     <IconButton>
//                                         <SearchIcon onClick={handleSearch} />
//                                     </IconButton>
//                                 </InputAdornment>
//                             )
//                         }}
//                         onKeyDown={handleKeyDown}
//                     />
//                     {open && <SearchModal open={open} setOpen={setOpen} searchData={searchData} modalData={modalData} />}
//                 </div>
//                 {/* <button style={styles.button}>Check all Services</button> */}
//             </div>
//             <div style={styles.rightSide}>
//                 <img
//                     // src="/images/nivishka-bg-image.jpg"
//                     src="/images/hero-image.svg"
//                     alt="Home Maintenance"
//                     style={styles.image}
//                 />
//             </div>
//         </div>
//     );
// }


// export default HeroSection;



export default function HeroSection() {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { primaryColor } = globalConstant()

    const [searchData, setSearchData] = useState('');
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState('');

    const handleSearch = async () => {
        if (searchData) {
            const data = await post_data(`services/search`, { value: searchData.trim() })
            setOpen(true);
            if (data.status) {
                if (data?.message === "subService") {
                    setModalData(data?.data)
                }
                else {
                    setModalData(data?.data[0]?.sub_services);
                }
            }
            else {
                setModalData("No Result Found");
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };


    const allBanners = [
        {
            image: 'banner-1.jpg',
            title: 'Home Maintenance and Services for you',
            description: "We aim to serve you with the best and quality service available in the industry. We make sure all your home shines with our work."
        },
        {
            image: 'banner-2.jpg',
            title: 'We are Professional and provide repair Services',
            description: "We provide any kind of repairs that are needed for your house. We have experienced and trained workers who can deliver quality results."
        },
        {
            image: 'banner-3.jpg',
            title: 'The Best company to provide home Services',
            description: "We are the best company and we also believe in working hard to make sure we remain the best by delivering quality services to clients like you."
        }
    ]


    return (
        <section className="w3l-covers-9-main">
            <div className="covers-9">
                <div className="csslider infinity" id="slider1">
                    {allBanners?.length > 0 && allBanners?.map((banner, index) => (
                        <input
                            key={index}
                            type="radio"
                            name="slides"
                            id={`slides_${index + 1}`}
                            defaultChecked={index === 0}
                        />
                    ))}
                    <ul style={{ height: '75vh' }}>
                        {allBanners?.map((banner, index) => {
                            return (
                                <li key={index} style={{ position: 'relative', background: `url(images/${banner?.image})` }} className="bannerSlides">

                                    <div style={{ position: 'absolute', bottom: '5%', left: isDesktop ? '9.5%' : '5%', width: isDesktop ? 550 : '90%', margin: isDesktop ? '' : 'auto' }}>
                                        <TextField
                                            fullWidth
                                            style={{ background: 'white' }}
                                            label="Search Services..."
                                            onChange={(e) => {
                                                setSearchData(e.target.value)
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton>
                                                            <SearchIcon onClick={handleSearch} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onKeyDown={handleKeyDown}
                                        />
                                        {open && <SearchModal open={open} setOpen={setOpen} searchData={searchData} modalData={modalData} />}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="arrows">
                        {allBanners?.map((_, index) => (
                            <label key={index} htmlFor={`slides_${index + 1}`}></label>
                        ))}
                    </div>
                    <div className="navigation">
                        <div>
                            {allBanners?.map((_, index) => (
                                <label key={index} htmlFor={`slides_${index + 1}`}></label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
