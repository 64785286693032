import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import ServicesTable from "../Components/ServicesTable";
import SearchComponent from "../Components/SearchComponent";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../globalConstants";

export default function Services({ type }) {

    const [loading, setLoading] = useState("true")
    const [loader, setLoader] = useState(true);
    const [servicesData, setServicesData] = useState([]);
    const [value, setValue] = useState('')
    const [addServices, setAddServices] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalServices, setTotalServices] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [searchState, setSearchState] = useState(false)

    const handleSearch = () => {
        setLoader(true)
        setTimeout(() => {
            if (value !== '') {
                searchServices()
                setSearchState(true)
            }
            else {
                getAllServices()
                setSearchState(false)
            }
        }, 500);
    }

    const searchServices = async () => {
        try {
            const data = await post_data(`services/search-services/${value}?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setServicesData(data?.data?.services);
                setTotalPages(data?.data?.totalPages);
                setTotalServices(data?.data?.totalServices);
            }
            else {
                setLoader(false);
                setServicesData([])
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error While Fetching All Customers", error);
        }
    }


    const vendor_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    const getAllServices = async () => {
        try {
            const data = await get_data(`services/get-all-services?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setServicesData(data?.data?.services);
                setTotalPages(data?.data?.totalPages);
                setTotalServices(data?.data?.totalServices);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Services", error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchServices()
            }
            else {
                getAllServices();
            }
        }
    }, [currentPage])


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 0.5 },
        { title: "Image", grid: 1 },
        { title: "Service", grid: 4.5 },
        { title: "Description", grid: 4 },
        { title: "Action", grid: 2 },
    ];

    const count = servicesData.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...vendor_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Services' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => setAddServices(true)}>
                        Create
                    </Button>
                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div>

                <div style={page_container}>
                    <ServicesTable
                        addServices={addServices}
                        setAddServices={setAddServices}
                        tableHeader={tableHeader}
                        data={servicesData}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        setLoader={setLoader}
                        totalPages={totalPages}
                        totalServices={totalServices}
                        loader={loader}
                        getAllService={getAllServices}
                    />
                </div>
            </Grid>
        </>
    )
}