import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import BackButton from "../Components/BackButton";
import VendorTable from "../Components/VendorTable";
import { get_data, post_data } from "../../../api";
import SearchComponent from "../Components/SearchComponent";
import ExportModal from "../Components/ExportModal";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../globalConstants";

export default function Vendors({ type }) {

    const [loading, setLoading] = useState("true");
    const [vendorData, setVendorData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [value, setValue] = useState('')
    const [open, setOpen] = useState(false)
    const [addVendor, setAddVendor] = useState(false)
    const [totalVendors, setTotalVendors] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [searchState, setSearchState] = useState(false)

    const handleSearch = () => {
        setLoader(true)
        setTimeout(() => {
            if (value !== '') {
                searchVendors()
                setSearchState(true)
            }
            else {
                getAllVendors()
                setSearchState(false)
            }
        }, 500);
    }

    const searchVendors = async () => {
        try {
            const data = await post_data(`vendor/search-vendors/${value}?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setVendorData(data?.data?.vendor);
                setTotalPages(data?.data?.totalPages);
                setTotalVendors(data?.data?.totalVendors);
            }
            else {
                setLoader(false);
                setVendorData([])
            }
        } catch (error) {
            setLoader(false);
            console.log("Error While Fetching All Customers", error);
        }
    }

    const getAllVendors = async () => {
        try {
            const data = await get_data(`vendor/get-all-vendors?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setVendorData(data?.data?.vendor);
                setTotalPages(data?.data?.totalPages);
                setTotalVendors(data?.data?.totalVendors);
            }
        } catch (error) {
            setLoader(false);
        }
    }


    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchVendors()
            }
            else {
                getAllVendors();
            }
        }
    }, [currentPage])


    const vendor_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Name", grid: 2 },
        { title: "Profession", grid: 2 },
        { title: "Phone", grid: 2 },
        { title: "City", grid: 1 },
        { title: "Commission", grid: 1 },
        { title: "Rating", grid: 1 },
        { title: "Earning", grid: 1 },
        { title: "Action", grid: 1 },
    ];

    const count = vendorData?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...vendor_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Vendors' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => setAddVendor(true)}>
                        Create
                    </Button>
                    <Button variant="contained" style={{ borderRadius: 7, background: 'black', boxShadow: 'none' }} onClick={() => setOpen(true)}>
                        Export
                    </Button>
                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div>

                <div style={page_container}>
                    <VendorTable
                        addVendor={addVendor}
                        setAddVendor={setAddVendor}
                        tableHeader={tableHeader}
                        data={vendorData}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalVendors={totalVendors}
                        totalPages={totalPages}
                        loader={loader}
                        setLoader={setLoader}
                        getAllVendors={getAllVendors}
                        searchState={searchState}
                    />
                </div>
                <ExportModal isOpen={open} setOpen={setOpen} type='vendors' />
            </Grid>
        </>
    )
}