import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useMediaQuery, Tabs, Tab } from '@mui/material';
import { globalConstant } from '../../../globalConstants';
import { get_data } from '../../../api';
import EditMemberShipModal from '../Components/EditPlusMemberShipModal';

const PlusMembership = () => {

    const isTablet = useMediaQuery('(max-width: 960px)');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { primaryColor } = globalConstant();
    const [memberShipData, setMemberShipData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);


    const getAllMemberShip = async () => {
        const data = await get_data("membership/get-all-membership")
        if (data.status) {
            setMemberShipData(data?.data[0]);
        }
    }

    useEffect(() => {
        getAllMemberShip();
    }, [])

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const styles = {
        container: {
            backgroundColor: '#fff',
            padding: isMobile || isTablet ? '20px' : '30px',
            borderRadius: '10px',
            border: '1px solid #E0E0E0',
            backgroundColor: 'white',
            width: isMobile || isTablet ? '90%' : '40%',
            margin: '2% 0 0 0',
            position: 'relative',
        },
        tabsContainer: {
            marginBottom: '20px',
            width: 400,
            marginTop: '1%',
        },
        tab: {
            flex: 1,
            '&.Mui-selected': {
                color: primaryColor,
            },
            '&.MuiTab-textColorPrimary': {
                color: primaryColor,
            },
        },
        totalPayable: {
            fontSize: '24px',
            fontWeight: '600',
        },
        amount: {
            fontSize: '32px',
            fontWeight: '700',
            color: primaryColor,
            marginTop: 0,
            marginBottom: '20px',
        },
        actual_amount: {
            fontSize: '20px',
            fontWeight: '500',
            color: "#6666",
            marginTop: 0,
            marginBottom: '20px',
            textDecoration: "line-through"
        },
        itemRow: {
            display: 'flex',
            alignItems: 'center',
            gap: '1%',
            margin: '15px 0',
        },
        itemName: {
            fontSize: '20px',
            color: '#666',
        },
        itemAmount: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
        divider: {
            width: '100%',
            height: '1px',
            backgroundColor: '#e0e0e0',
            margin: '10px 0',
        },
        button: {
            padding: '13px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: '#011102',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '100%',
            marginTop: '3%'
        }
    };

    const planItems = [
        "10% Discount on all Services at Nivishka"
    ];

    const discountedPrices = [memberShipData?.quaterly_discounted_price, memberShipData?.semi_yearly_discounted_price, memberShipData?.yearly_discounted_price]; // 0: Yearly, 1: Semi-Yearly
    const prices = [memberShipData?.quaterly_price, memberShipData?.semi_yearly_price, memberShipData?.yearly_price,]; // 0: Yearly, 1: Semi-Yearly

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={styles.tabsContainer}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    centered
                    textColor="primary"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: primaryColor
                        }
                    }}
                >
                    <Tab label="Quaterly" sx={styles.tab} />
                    <Tab label="Semi-Yearly" sx={styles.tab} />
                    <Tab label="Yearly" sx={styles.tab} />
                </Tabs>
            </Box>
            <Box sx={styles.container}>
                <Typography sx={styles.totalPayable}>{memberShipData?.name}</Typography>
                <Typography sx={styles.itemName}>{memberShipData?.description}</Typography>
                <Typography sx={styles.amount}>₹{(discountedPrices[selectedTab])?.toLocaleString('en-IN')} <span style={styles.actual_amount}>₹{(prices[selectedTab])?.toLocaleString('en-IN')}</span>
                </Typography>

                {
                    planItems.map((item, index) => (
                        <Box sx={styles.itemRow} key={index}>
                            <img src='/images/check-icon.svg' alt="Check Icon" style={{ width: 30, height: 30 }} />
                            <Typography sx={styles.itemName}>{item}</Typography>
                        </Box>
                    ))
                }
                <Button
                    onClick={handleOpen}
                    style={styles.button}
                >
                    Edit Plus Plan
                </Button>
                {open && <EditMemberShipModal getAllMemberShip={getAllMemberShip} open={open} setOpen={setOpen} memberShipData={memberShipData} selectedTab={selectedTab} />}
            </Box>
        </Box>
    );
};

export default PlusMembership;
