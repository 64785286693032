import React from 'react';
import { Button, useMediaQuery } from '@mui/material';
import Heading from './Heading';
import { globalConstant } from '../../globalConstants';
import { endPointURL, get_data, post_data } from '../../api';
import { useNavigate } from 'react-router-dom';

const FeaturedServices = () => {

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { primaryColor } = globalConstant()
    const [serviceData, setServicesData] = React.useState([])
    const [loader, setLoader] = React.useState(true)
    const navigate = useNavigate()

    const styles = {
        container: {
            padding: isDesktop ? '3% 10%' : '20px',
            textAlign: 'center',
        },
        heading: {
            color: '#41A317',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        subheading: {
            fontSize: 18,
            marginBottom: '20px',
            color: '#555',
        },
        grid: {
            display: 'grid',
            gap: '20px',
            marginTop: isDesktop ? '5%' : '',
            gridTemplateColumns: isDesktop ? 'repeat(3, 1fr)' : isTablet ? 'repeat(2, 1fr)' : '1fr',
        },
        card: {
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            background: '#fff',
            height: 450
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        cardContent: {
            padding: isDesktop ? '5%' : '15px',
            textAlign: 'left',
            background: 'linear-gradient(180deg, #163F1800 , #163F18 90%',
            color: '#fff',
            position: 'absolute',
            bottom: 0,
            width: '100%',
        },
        title: {
            fontSize: 22,
            fontWeight: '600',
        },
        description: {
            fontSize: 16,
            width: isDesktop ? '80%' : '',
            marginTop: '5px',
        }
    };

    const getAllServices = async () => {
        try {
            const data = await get_data("services/get-random-services");
            if (data.status) {
                setLoader(false);
                setServicesData(data?.data);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Services", error);
        }
    }

    React.useEffect(() => {
        getAllServices();
    }, [])

    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: isDesktop ? '15%' : '40%',
        margin: '3% auto 0',
        boxShadow: 'none'
    }

    const handleNavigate = () => {
        navigate('/services')
        window.scrollTo(0, 0)
    }

    return (
        <div style={styles.container}>
            <Heading title={'Our Featured Services'} color={primaryColor} />
            <p style={styles.subheading}>Book from our Featured services, at the best price</p>
            <div style={styles.grid}>
                {serviceData?.slice(0, 3)?.map((service, index) => (
                    <div key={index} style={styles.card}>
                        <img src={`${endPointURL}/uploads/service-picture/${service?.service_image}`} alt={service.title} style={styles.image} />
                        <div style={styles.cardContent}>
                            <h3 style={styles.title}>{service?.service_name}</h3>
                            <p style={styles.description}>{service?.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <Button variant="contained" style={button} onClick={handleNavigate}>
                View all
            </Button>
        </div>
    );
}

export default FeaturedServices;
